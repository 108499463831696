const validate = (fieldsKeyToValidate, values) => fieldsKeyToValidate
  .every(field => values[field] || values[field] === 0);

const fieldsKeyToValidateDestination = ['policyStartDate', 'policyEndDate', 'targetFrame', 'targetCountries'];
const fieldsKeyToValidateTravelers = [
  ...fieldsKeyToValidateDestination, 'phone1', 'firstName1', 'lastName1', 'dob1', 'ID1', 'gender1',
];
const fieldsKeyToValidateMedical = [
  ...fieldsKeyToValidateTravelers, 'isTreatmentAbroad', 'isMedicalEscalation', 'isMedicalTreatment', 'isAdditionalChronic', // , 'isChronic',
];

const getValues = () => JSON.parse(sessionStorage.getItem('travel.details-form'))?.values || {};

export default [
  {
    check: () => validate(fieldsKeyToValidateDestination, getValues()),
    redirectTo: '/details#destination',
    uri: '/details#insured',
  },
  {
    check: () => validate(fieldsKeyToValidateTravelers, getValues()),
    redirectTo: '/details#insured',
    uri: '/details#medical',
  },
  {
    check: () => validate(fieldsKeyToValidateTravelers, getValues()),
    redirectTo: '/details#medical',
    uri: '/details#medical_extended',
  },
  {
    check: () => validate(fieldsKeyToValidateMedical, getValues()),
    redirectTo: '/details#medical_extended',
    uri: '/details#coverage',
  },
  {
    check: () => validate([...fieldsKeyToValidateMedical, 'isTerms'], getValues()),
    redirectTo: '/details#coverage',
    uri: '/offers',
  },
];
