import React, {useState} from 'react';
import {Backdrop, Modal, Paper, Box} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

import useStyles from './styles';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

const steps = [FirstStep, SecondStep];

function UnsupportedMedicalCases({isModalShow, close, values, hideContactPassportCard}) {
  const classes = useStyles();
  const intl = useIntl();
  const [step, setStep] = useState(0);
  const CurrentStep = steps[step];
  const increaseStep = () => setStep(step + 1);

  const onClose = () => {
    setStep(0);
    close();
  };

  if (!CurrentStep) {
    onClose();
    return null;
  }

  return (
    <Modal
      open={isModalShow}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{timeout: 500}}
      onClose={onClose}
    >
      <Paper className={classes.paper}>
        <div data-testid='modal-popup' className={classes.modalHeader}>
          <Box onClick={onClose}><span className={classes.buttonDismiss}/></Box>
        </div>
        <div className={classes.modalBody}>
        {!hideContactPassportCard ?
            (<h3 className={classes.title} >
              { intl.formatMessage({id: 'unsupported_medical_cases_modal.title'}) }
            </h3>) : <h3 className={classes.title} ></h3> }
          <CurrentStep increaseStep={increaseStep} values={values} hideContactPassportCard={hideContactPassportCard} />
        </div>
      </Paper>
    </Modal>
  );
}

UnsupportedMedicalCases.propTypes = {
  isModalShow: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  hideContactPassportCard: PropTypes.bool,
};

UnsupportedMedicalCases.defaultProps = {
  hideContactPassportCard: false,
};

export default UnsupportedMedicalCases;

