/* eslint-disable max-statements-per-line */
/* eslint-disable max-len */
/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import {Breakpoints, agentDeveloperUtils} from 'wobi-web-common';
import EzFormControl from 'wobi-web-common/dist/components/EzFormControl';
import {makeStyles, Modal, Backdrop, Fade, Paper, Grid, Button, DialogTitle, TextField,
  Checkbox, FormHelperText, FormGroup, FormControlLabel, Link} from '@material-ui/core';
import YesNoRadioGroup from 'wobi-web-common/dist/components/YesNoRadioGroup';

import {FormattedMessage, useIntl} from 'react-intl';
import {fireGtmEvent} from '../../../utils/marketing.utils';
import {PregnancyAndExtremeSportsPopup} from '../../../components/modals/PregnancyAndExtremeSports';
import validationSchema from './helpers/Tab4_helpers/validationSchema';
import SuggestionAddRiderModal from '../../../components/modals/SuggestionAddRider/SuggestionAddRider';

import {getImageByAgent} from '../../../config/agent';

import moment from 'moment';

const carIcon = getImageByAgent('riders/Car.png');
const winterExtremeIcon = getImageByAgent('riders/WinterSports_Icon.png');
const cellphoneIcon = getImageByAgent('riders/Cellphone_Icon.png');
const laptopIcon = getImageByAgent('riders/Laptop_Icon.png');
const iturIcon = getImageByAgent('riders/Itur_Icon.png');
const extremeSportsIcon = getImageByAgent('riders/ExtremeSports_Icon.png');
const cancellationIcon = getImageByAgent('riders/Cancellation_Icon.png');
const luggageIcon = getImageByAgent('riders/Baggage_Icon.png');
const thirdPartyIcon = getImageByAgent('riders/ThirdParty_Icon.png');
const presentorImage = getImageByAgent('presentor.png');
const CompleteIcon = getImageByAgent('task-complete.svg');

const extremeSportsCountres = ['URUGUAY', 'INDONESIA', 'EL SALVADOR', 'ECUADOR', 'ARGENTINA', 'BOLIVIA', 'BELIZE', 'BRUNEI', 'BRAZIL', 'GUATEMALA', 'INDIA', 'HONDURAS', 'VIETNAM', 'VENEZUELA', 'TAIWAN', 'PORTUGUESE TIMOR', 'JAPAN', 'LAOS', 'MONGOLIA', 'MYANMAR (BURMA)', 'MALAYSIA', 'MEXICO', 'NEW ZEALAND', 'NICARAGUA', 'NEPAL', 'SURINAME', 'CHINA', 'SINGAPORE', 'SRI-LANKA', 'PANAMA', 'PARAGUAY', 'PERU', 'CHILE', 'COLOMBIA', 'COSTA RICA', 'S KOREA', 'N KOREA', 'CAMBODIA', 'THAILAND'];

const Tab4 = React.memo(
  ({
    className,
    idForAccessibility,
    submitCallback,
    validationCallback,
    formikSetField,
    formikErrors,
    formikValues,
    formikValidateForm,
    actionButton,
  }) => {
  // dynamic validation schema
    const validationObject = validationSchema;

    setTimeout(() => {
      validationCallback(validationObject); // dynamic callback to React.Component method
    }, 1);

    const intl = useIntl();
    const classes = useStyles();
    const [isDetection, setIsDetection] = React.useState(false);
    const [isLiable, setIsLiable] = React.useState(false);
    const [showSuggestionAddRiderModal, setShowSuggestionAddRiderModal] = React.useState(false);
    const [typeOfSuggestionAddRiderModal, setTypeOfSuggestionAddRiderModal] = React.useState('luggage');
    const [isCancellationOfSelfParticipationOnRentedCar, setIsCancellationOfSelfParticipationOnRentedCar] = React.useState(false);
    const [shouldShowPopUp, setShouldShowPopUp] = React.useState(true);
    const handleSuggestionAddRiderModalClose = () => setShowSuggestionAddRiderModal(!showSuggestionAddRiderModal);

    const handleLogicForShowingSuggestionAddRiderModal = () => {
      const todayDate = moment();
      const policyStartDate = moment(formikValues.policyStartDate);
      const isPassMonth = policyStartDate.diff(todayDate, 'days') + 1 >= 30;
      const hasExtremeSportsCountry = formikValues.targetCountries.some(country => extremeSportsCountres.includes(country));

      if (isPassMonth && !formikValues.isTravelsc) {
        fireGtmEvent('coversCancellationPu', {});
        setTypeOfSuggestionAddRiderModal('short_and_cancel');
        handleSuggestionAddRiderModalClose();
        setShouldShowPopUp(false);
      } else if (!formikValues.isCharger) {
        fireGtmEvent('coversLuggagePu', {});
        setShouldShowPopUp(false);
        setTypeOfSuggestionAddRiderModal('luggage');
        handleSuggestionAddRiderModalClose();
      } else if (hasExtremeSportsCountry && !formikValues.isEsports) {
        fireGtmEvent('coversExtremePu', {});
        setShouldShowPopUp(false);
        setTypeOfSuggestionAddRiderModal('extremeSport');
        handleSuggestionAddRiderModalClose();
      } else {
        submitCallback(5);
      }
    };

    const handleSuggestionAddRiderModalConfirm = async () => {
      if (typeOfSuggestionAddRiderModal === 'luggage') {
        fireGtmEvent('addCoversLuggagePu', {});
        setIsCharger(true);
        await formikSetField('isCharger', true);
      }
      if (typeOfSuggestionAddRiderModal === 'short_and_cancel') {
        fireGtmEvent('addCoversCancellationPu', {});
        setIsTravelsc(true);
        await formikSetField('isTravelsc', true);
      }
      if (typeOfSuggestionAddRiderModal === 'extremeSport') {
        fireGtmEvent('addCoversExtremePu', {});
        setIsEsports(true);
        await formikSetField('isEsports', true);
      }
      handleSuggestionAddRiderModalClose();
      submitCallback(5);
    };

    const handleSuggestionAddRiderModalDecline = () => {
      handleSuggestionAddRiderModalClose();
      submitCallback(5);
    };

    const renderDescriptionAfter2 = () => {
      if (isDetection === false) {
        return (
          <span>
            {intl.formatMessage({id: 'step1.tab4.detection_bottom1'})}
          </span>
        );
      }
      return null;
    };

    const renderDescription2After2 = () => {
      if (isLiable === false) {
        return (
          <span>
            {intl.formatMessage({id: 'step1.tab4.liability_bottom1'})}
          </span>
        );
      }
      return null;
    };

    const clickPcCoverage = (traveler, value) => {
      traveler.needPcCoverage = value;
      if (!traveler.pc) {
        traveler.pc = {};
      }
      formikSetField('travelers', formikValues.travelers);
    };

    const clickMobileCoverage = (traveler, value) => {
      traveler.needMobileCoverage = value;
      if (!traveler.mobile) {
        traveler.mobile = {};
      }
      formikSetField('travelers', formikValues.travelers);
    };
    const clickCancellationOfSelfParticipationOnRentedCar = (traveler, value) => {
      //  only one traveler can have this cover
      // eslint-disable-next-line array-callback-return, no-shadow
      formikValues.travelers.some((traveler) => {
        if (traveler.cancellationOfSelfParticipationOnRentedCar) {
          traveler.cancellationOfSelfParticipationOnRentedCar = false;
        }
      });
      traveler.cancellationOfSelfParticipationOnRentedCar = value;

      formikSetField('travelers', formikValues.travelers);
    };

    const handleSubmit = async () => {
      const errors = await formikValidateForm();
      if (Object.keys(errors).length === 0) {
        if (isPregnancyAndExtremeSportsStatus && !isApprovedPregnancyAndExtremeSports &&
          formikValues?.isPregnancy === 'yes') {
          setIsShowPregnancyAndExtremeSportsPopup(true);
          return;
        }

        fireGtmEvent('confirmAdditionalCovers', {
          locateAndExtract: formikValues.isDetection,
          travelThirdParty: formikValues.isLiable,
          luggage: formikValues.isCharger,
          extreme: formikValues.isEsports,
          winterExtreme: formikValues.isWsports,
          cancellation: formikValues.isTravelc,
          shortening: formikValues.isTravelsc,
          pcAndTablet: formikValues.isPccover,
          mobile: formikValues.isMobilecover,
          cancellationOfSelfParticipationOnRentedCar: formikValues.isCancellationOfSelfParticipationOnRentedCar,
        });
        if (shouldShowPopUp) {
          handleLogicForShowingSuggestionAddRiderModal();
        } else {
          submitCallback(5);
        }
      }
    };

    // stuff that was before on tab 5

    // First checkbox
    const [isCharger, setIsCharger] = React.useState(false);
    const renderDescription3After2 = () => {
      if (isCharger === false) {
        return (<span>{intl.formatMessage({id: 'step1.tab5.charger_bottom1'})}</span>);
      }
      return null;
    };

    // Second checkbox

    const [isApprovedPregnancyAndExtremeSports, setIsApprovedPregnancyAndExtremeSports] = React.useState(false);
    const [isShowPregnancyAndExtremeSportsPopup, setIsShowPregnancyAndExtremeSportsPopup] = React.useState(false);
    const [typeOfShowPregnancyAndExtremeSportsPopup, setTypeOfShowPregnancyAndExtremeSportsPopup] = React.useState('pregnancy_and_extreme_sports_both.title');

    const [isEsports, setIsEsports] = React.useState(false);
    const renderDescription4After2 = () => {
      if (isEsports === false) {
        return (<span>{intl.formatMessage({id: 'step1.tab5.esports_bottom1'})}</span>);
      } else {
        return (<span>{intl.formatMessage({id: 'step1.tab5.esports_bottom1'})}</span>);
      }

      // removing orange bottom texts
    };

    // Third checkbox
    const [isWsports, setIsWsports] = React.useState(false);
    const renderDescription5After2 = () => {
      if (isWsports === false) {
        return (<span>{intl.formatMessage({id: 'step1.tab5.wsports_bottom1'})}</span>);
      } else {
        return (<span>{intl.formatMessage({id: 'step1.tab5.wsports_bottom1'})}</span>);
      }

      // removing orange bottom texts
    };

    let isPregnancyAndExtremeSportsStatus = false;
    const isPregnancy = formikValues?.isPregnancy;
    if (isPregnancy === 'yes' && isEsports || isWsports) {
      isPregnancyAndExtremeSportsStatus = true;
    }

    // Fourth-Fith checkboxes
    const [isTravelc, setIsTravelc] = React.useState(false);
    const [isTravelsc, setIsTravelsc] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const renderDescription6After2 = (values, setFieldValue) => {
      if (isTravelc === false) {
        return (<span>{intl.formatMessage({id: 'step1.tab5.travelc_bottom1'})}</span>);
      } else {
        return (
          <div>
            <span className={classes.description5After2}>{intl.formatMessage({id: 'step1.tab5.travelc_bottom2_ask'})}</span>
            <br/>
            <EzFormControl name='isOwnerHospital' isInlineLabel={false} showError={false}>
              <div data-testid='cancel-travel-from-special-reason'>
                <YesNoRadioGroup value={values.isOwnerHospital}
                  onChange={(event) => {
                    setFieldValue('isOwnerHospital', event.target.value); if (event.target.value === 'yes') {
                      setOpenModal(true);
                    }
                  }} />
              </div>
            </EzFormControl>
            <ModalContainer setFieldValue={setFieldValue} isforBlock4/>
          </div>
        );
      }
    };
    const renderDescription7After2 = (values, setFieldValue) => {
      if (isTravelsc === false) {
        return (<span>{intl.formatMessage({id: 'step1.tab5.travelsc_bottom1'})}</span>);
      } else {
        return (
          <div>
            <span className={classes.description5After2}>{intl.formatMessage({id: 'step1.tab5.travelc_bottom2_ask'})}</span>
            <br/>
            <EzFormControl name='isOwnerHospital2' isInlineLabel={false} showError={false}>
              <div data-testid='short-travel-from-special-reason'>
                <YesNoRadioGroup value={values.isOwnerHospital2}
                  onChange={(event) => {
                    setFieldValue('isOwnerHospital2', event.target.value); if (event.target.value === 'yes') {
                      setOpenModal(true);
                    }
                  }} />
              </div>
              <FormHelperText component='span' error>{formikErrors.isOwnerHospital2}</FormHelperText>
            </EzFormControl>
            <ModalContainer setFieldValue={setFieldValue} isforBlock4={false}/>
          </div>
        );
      }
    };

    // Sixth checkbox
    const [isPccover, setIsPccover] = React.useState(false);
    const renderDescription8After2 = (traveler, index, setFieldValue) => {
      if (isPccover === false) {
        return (<span>{intl.formatMessage({id: 'step1.tab5.pccover_bottom1'})}</span>);
      } else {
        return traveler.needPcCoverage && traveler.pc ? <div data-testid={`traveler-${index + 1}-pcCover`}>
          <Grid container spacing={0} className={classes.inputsContainer} >
            <Grid item xs={4}>
              <label htmlFor={`pccoverManufacturer${index}`}>{intl.formatMessage({id: 'step1.tab5.pccover_bottom2_manufacturer'})}</label>
              <EzFormControl name={`pccoverManufacturer${index}`} label='' isInputLabel showError={false}>
                <TextField id={`pccoverManufacturer${index}`} data-testid={`pccoverManufacturer${index + 1}`} onChange={(event) => {
                  updateTravelCoverItems(event, index, 'manufacturer', 'pc');
                }} value={traveler.pc.manufacturer} margin='normal' variant='outlined'/>
              </EzFormControl>
              <FormHelperText component='span' error>
                {
                  formikErrors?.travelers?.[index]?.pc?.manufacturer ? intl.formatMessage({id: formikErrors.travelers[index].pc.manufacturer}) : ''
                }
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <label htmlFor={`pccoverModel${index}`}>{intl.formatMessage({id: 'step1.tab5.pccover_bottom2_model'})}</label>
              <EzFormControl name={`pccoverModel${index}`} label='' isInputLabel showError={false}>
                <TextField id={`pccoverModel${index}`} data-testid={`pccoverModel${index + 1}`} onChange={(event) => {
                  updateTravelCoverItems(event, index, 'model', 'pc');
                }} value={traveler.pc.model} margin='normal' variant='outlined'/>
              </EzFormControl>
              <FormHelperText component='span' error>
                {
                  formikErrors?.travelers?.[index]?.pc?.model ? intl.formatMessage({id: formikErrors.travelers[index].pc.model}) : ''
                }
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <label htmlFor={`pccoverYear${index}`}>{intl.formatMessage({id: 'step1.tab5.pccover_bottom2_year'})}</label>
              <EzFormControl name={`pccoverYear${index}`} label='YYYY' isLabelInChild showError={false}>
                <TextField id={`pccoverYear${index}`} data-testid={`pccoverYear${index + 1}`} onChange={(event) => {
                  updateTravelCoverItems(event, index, 'year', 'pc');
                }} value={traveler.pc.year} margin='normal' variant='outlined'/>
              </EzFormControl>
              <FormHelperText component='span' error>
                {
                  formikErrors?.travelers?.[index]?.pc?.year ? intl.formatMessage({id: formikErrors.travelers[index].pc.year}) : ''
                }
              </FormHelperText>
            </Grid>
          </Grid>
        </div> : null;
      }
    };

    // Seventh checkbox
    const [isMobilecover, setIsMobilecover] = React.useState(false);
    const renderDescription9After2 = (traveler, index, setFieldValue) => {
      if (isMobilecover === false) {
        return (<span>{intl.formatMessage({id: 'step1.tab5.mobilecover_bottom1'})}</span>);
      } else {
        return traveler.needMobileCoverage && traveler.mobile ? <div>
          <Grid container spacing={0} className={classes.inputsContainer}>
            <Grid item xs={4}>
              <label htmlFor={`mobilecoverManufacturer${index}`}>{intl.formatMessage({id: 'step1.tab5.pccover_bottom2_manufacturer'})}</label>
              <EzFormControl showError={false} name={`mobilecoverManufacturer${index}`} label='' isInputLabel>
                <TextField data-testid={`mobilecoverManufacturer${index + 1}`} id={`mobilecoverManufacturer${index}`} onChange={(event) => {
                  updateTravelCoverItems(event, index, 'manufacturer', 'mobile');
                }} value={traveler.mobile.manufacturer} margin='normal' variant='outlined'/>
              </EzFormControl>
              <FormHelperText component='span' error>
                {
                  formikErrors?.travelers?.[index]?.mobile?.manufacturer ? intl.formatMessage({id: formikErrors.travelers[index].mobile.manufacturer}) : ''
                }
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <label htmlFor={`mobilecoverModel${index}`}>{intl.formatMessage({id: 'step1.tab5.pccover_bottom2_model'})}</label>
              <EzFormControl showError={false} name={`mobilecoverModel${index}`} label='' isInputLabel>
                <TextField data-testid={`mobilecoverModel${index + 1}`} id={`mobilecoverModel${index}`} onChange={(event) => {
                  updateTravelCoverItems(event, index, 'model', 'mobile');
                }} value={traveler.mobile.model} margin='normal' variant='outlined'/>
              </EzFormControl>
              <FormHelperText component='span' error>
                {
                formikErrors?.travelers?.[index]?.mobile?.model ? intl.formatMessage({id: formikErrors.travelers[index].mobile.model}) : ''
                }
              </FormHelperText>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </div> : null;
      }
    };

    const ModalContainer = (props) => {
      const useStyles = makeStyles(theme => ({
        paper: {
          maxWidth: '600px',
          display: 'flex',
          maxHeight: 'calc(100% - 64px)',
          flexDirection: 'column',
          margin: '32px',
          position: 'relative',
          overflowY: 'auto',
        },
        modal: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        modalBtn: {
          fontSize: theme.typography.pxToRem(15.75),
          height: 52,
          width: '150px',
          margin: '0 20px 40px',
          lineHeight: 1,
        },
        dialogueTitle: {
          textAlign: 'center',
          color: theme.palette.secondary.main,
        },

      }));
      const classes = useStyles();
      const handleClose = (doCancel) => {
        if (doCancel && props.isforBlock4) {
          setIsTravelc(false);
          props.setFieldValue('isTravelc', false);
        } else if (doCancel && !props.isforBlock4) {
          setIsTravelsc(false);
          props.setFieldValue('isTravelsc', false);
        }
        setOpenModal(false);
      };

      return (
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={openModal}
          className={`MuiModal ${classes.modal}`}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <Paper className={classes.paper}>
              <DialogTitle className={classes.dialogueTitle}>{intl.formatMessage({id: 'step1.tab5.travelc_bottom2_notation'})}</DialogTitle>
              <div style={{padding: '0 40px 40px'}}>{intl.formatMessage({id: 'step1.tab5.travelc_bottom2_notation2'})}</div>
              <div className={classes.modal}>
                <Button className={classes.modalBtn} data-testid='add-cover-btn-modal' onClick={() => handleClose(false)}><FormattedMessage id='add_cover'/></Button>
                <Button className={classes.modalBtn} data-testid='cancel-cover-btn-modal' onClick={() => handleClose(true)}><FormattedMessage id='cancel'/></Button>
              </div>
            </Paper>
          </Fade>
        </Modal>
      );
    };

    const updateTravelCoverItems = (event, index, key, itemToCover) => {
      formikValues.travelers[index][itemToCover][key] = event.target.value;
      formikSetField('travelers', formikValues.travelers);
    };

    return (
      <div className={className} id={idForAccessibility} role='tabpanel'>
        <Grid container spacing={0} className={classes.existingCoverages}>
          <Grid item xs={12} className={classes.title}>
            <div>
              {intl.formatMessage({id: 'step1.tab4.title'})}
              <div
                className={classes.completeIcon}
                style={{display: 'inline-block'}}
              />
            </div>
          </Grid>

          {/* First checkbox block */}
          <Grid container item xs={12} className={classes.title2}>
            <div className={classes.checkboxIconContainer}>

              <Checkbox
                name='isDetection'
                id='isDetection'
                data-testid='is-detection'
                onChange={(event) => {
                  setIsDetection(event.target.checked);
                  formikSetField('isDetection', event.target.checked);
                }}
                checked={
                  formikValues.isDetection && !isDetection ?
                    setIsDetection(true) ?
                      formikValues.isDetection :
                      formikValues.isDetection :
                    formikValues.isDetection
                }
              />
              <div className={classes.icon}>

                <img src={iturIcon} alt='icon' />
              </div>

            </div>
            <Grid item className={`${classes.descriptionAfter}`}>
              <label htmlFor='isDetection' className={classes.description}>
                {intl.formatMessage({id: 'step1.tab4.detection'})}
              </label>
              <span>
                {intl.formatMessage({id: 'step1.tab4.detection_fulltext'})}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.descriptionAfter2}>
            {renderDescriptionAfter2()}
          </Grid>

          {/* Second checkbox block */}
          <Grid container item xs={12} className={classes.title2}>
            <div className={classes.checkboxIconContainer}>

              <Checkbox
                name='isLiable'
                id='isLiable'
                data-testid='is-liable'
                onChange={(event) => {
                  setIsLiable(event.target.checked);
                  formikSetField('isLiable', event.target.checked);
                }}
                checked={
                  formikValues.isLiable && !isLiable ?
                    setIsLiable(true) ?
                      formikValues.isLiable :
                      formikValues.isLiable :
                    formikValues.isLiable
                }
              />
              <div className={classes.icon}>

                <img src={thirdPartyIcon} alt='icon' />
              </div>

            </div>
            <Grid item className={`${classes.descriptionAfter}`}>
              <label htmlFor='isLiable' className={classes.description}>
                {intl.formatMessage({id: 'step1.tab4.liability'})}
              </label>
              <span>
                {intl.formatMessage({id: 'step1.tab4.liability_fulltext'})}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.descriptionAfter2}>
            {renderDescription2After2()}
          </Grid>

          {/* First checkbox block */}
          <Grid container item xs={12} className={classes.title2}>
            <div className={classes.checkboxIconContainer}>

              <Checkbox
                name='isCharger'
                id='isCharger'
                data-testid='is-charger'
                onChange={(event) => {
                  setIsCharger(event.target.checked); formikSetField('isCharger', event.target.checked);
                }}
                checked={formikValues.isCharger && !isCharger ? setIsCharger(true) ? formikValues.isCharger : formikValues.isCharger : formikValues.isCharger}
              />
              <div className={classes.icon}>

                <img src={luggageIcon} alt='icon' />
              </div>
            </div>
            <Grid item className={`${classes.descriptionAfter}`}>
              <label htmlFor='isCharger' className={classes.description}>
                {intl.formatMessage({id: 'step1.tab5.charger'})}
              </label>
              <span>
                {intl.formatMessage({id: 'step1.tab5.charger_fulltext'})}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.descriptionAfter2}>
            {renderDescription3After2()}
          </Grid>
          {/* Second checkbox block */}
          <Grid container item xs={12} className={classes.title2}>
            <div className={classes.checkboxIconContainer}>

              <Checkbox
                name='isEsports'
                id='isEsports'
                data-testid='isEsports'
                onChange={(event) => {
                  const isPregnancy = formikValues?.isPregnancy;
                  if (isPregnancy === 'yes') {
                    if (event.target.checked) {
                      setIsShowPregnancyAndExtremeSportsPopup(true);
                      setTypeOfShowPregnancyAndExtremeSportsPopup('pregnancy_and_extreme_sports_esports.title');
                    }
                  }
                  setIsEsports(event.target.checked); formikSetField('isEsports', event.target.checked);
                  if (!event.target.checked && !isWsports) {
                    setIsApprovedPregnancyAndExtremeSports(false);
                  }
                }}
                checked={formikValues.isEsports && !isEsports ? setIsEsports(true) ? formikValues.isEsports : formikValues.isEsports : formikValues.isEsports}
              />
              <div className={classes.icon}>

                <img src={extremeSportsIcon} alt='icon' />
              </div>

            </div>
            <Grid item xs={12} className={`${classes.descriptionAfter}`}>
              <label htmlFor='isEsports' className={classes.description}>{intl.formatMessage({id: 'step1.tab5.esports'})}</label>
              <PregnancyAndExtremeSportsPopup
                typeOfShowPregnancyAndExtremeSportsPopup={typeOfShowPregnancyAndExtremeSportsPopup}
                isOpen={isShowPregnancyAndExtremeSportsPopup}
                onClose={setIsShowPregnancyAndExtremeSportsPopup}
                onCheckBoxEvent={setIsApprovedPregnancyAndExtremeSports}
                sportsStatus = {{isEsports,
                  isWsports}}
              />

              <label htmlFor='isEsports'>{intl.formatMessage({id: 'step1.tab5.esports_fulltext'})}</label>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.descriptionAfter2}>
            {renderDescription4After2()}
          </Grid>
          {/* Third checkbox block */}
          <Grid container item xs={12} className={classes.title2}>
            <div className={classes.checkboxIconContainer}>

              <Checkbox
                name='isWsports'
                id='isWsports'
                data-testid='isWsports'
                onChange={(event) => {
                  const isPregnancy = formikValues?.isPregnancy;
                  if (isPregnancy === 'yes') {
                    if (event.target.checked) {
                      setTypeOfShowPregnancyAndExtremeSportsPopup('pregnancy_and_extreme_sports_wsports.title');
                      setIsShowPregnancyAndExtremeSportsPopup(true);
                    }
                  }
                  setIsWsports(event.target.checked);
                  if (!isEsports && !event.target.checked) {
                    setIsApprovedPregnancyAndExtremeSports(false);
                  }
                  formikSetField('isWsports', event.target.checked);
                }}
                checked={formikValues.isWsports && !isWsports ? setIsWsports(true) ? formikValues.isWsports : formikValues.isWsports : formikValues.isWsports}
              />
              <div className={classes.icon}>

                <img src={winterExtremeIcon} alt='icon' />
              </div>
            </div>
            <Grid item xs={12} className={`${classes.descriptionAfter}`}>
              <label htmlFor='isWsports' className={classes.description}>{intl.formatMessage({id: 'step1.tab5.wsports'})}</label>
              <label htmlFor='isWsports'>{intl.formatMessage({id: 'step1.tab5.wsports_fulltext'})}</label>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.descriptionAfter2}>
            {renderDescription5After2()}
          </Grid>
          {/* Fourth checkbox block */}
          {/* Commented out for COVID-19 period, to put back later */}
          {/*      <Grid item xs={12} className={classes.title2}>*/}
          {/*          <Checkbox*/}
          {/*              name='isTravelc'*/}
          {/*              id='isTravelc'*/}
          {/*              data-testid='isTravelc'*/}
          {/*              onChange={(event) => { setIsTravelc(event.target.checked); formikSetField('isTravelc', event.target.checked);}}*/}
          {/*              checked={formikValues.isTravelc && !isTravelc ? setIsTravelc(true) ? formikValues.isTravelc : formikValues.isTravelc : formikValues.isTravelc}*/}
          {/*          />*/}
          {/*          <span className={classes.description}>{intl.formatMessage({id: 'step1.tab5.travelc'})}</span>*/}
          {/*      </Grid>*/}
          {/*      <Grid item xs={12} className={`${classes.descriptionAfter}`}>*/}
          {/*          <span>{intl.formatMessage({id: 'step1.tab5.travelc_fulltext'})}</span>*/}
          {/*      </Grid>*/}
          {/*      <Grid item xs={12} className={classes.descriptionAfter2}>*/}
          {/*          {renderDescription4After2(formikValues, formikSetField)}*/}
          {/*      </Grid>*/}
          {/* Fith checkbox block */}
          <Grid container item xs={12} className={classes.title2}>
            <div className={classes.checkboxIconContainer}>

              <Checkbox
                name='isTravelsc'
                id='isTravelsc'
                data-testid='isTravelsc'
                onChange={(event) => {
                  setIsTravelsc(event.target.checked); formikSetField('isTravelsc', event.target.checked);
                }}
                checked={formikValues.isTravelsc && !isTravelsc ? setIsTravelsc(true) ? formikValues.isTravelsc : formikValues.isTravelsc : formikValues.isTravelsc}
              />
              <div className={classes.icon}>

                <img src={cancellationIcon} alt='icon' />
              </div>
            </div>
            <Grid item xs={12} className={`${classes.descriptionAfter}`}>
              <label htmlFor='isTravelsc' className={classes.description}>{intl.formatMessage({id: 'step1.tab5.travelsc'})}</label>

              <label htmlFor='isTravelsc'>{intl.formatMessage({id: 'step1.tab5.travelsc_fulltext'})}</label>
              <Grid item xs={12} style={{marginTop: '10px'}}>
                {renderDescription7After2(formikValues, formikSetField)}
              </Grid>
            </Grid>
          </Grid>
          {/* Sixth checkbox block */}
          <Grid container item xs={12} className={classes.title2}>
            <div className={classes.checkboxIconContainer}>

              <Checkbox
                name='isPccover'
                id='isPccover'
                data-testid='isPccover'
                onChange={(event) => {
                  setIsPccover(event.target.checked);
                  if (formikValues.travelers.length === 1) {
                    clickPcCoverage(formikValues.travelers[0], event.target.checked);
                  } else if (formikValues.travelers.length > 1 && !event.target.checked) {
                    const travelers = formikValues.travelers.map((traveler) => {
                      traveler.needPcCoverage = false;
                      if (!traveler.pc) {
                        traveler.pc = {};
                      }
                      return traveler;
                    });
                    formikSetField('travelers', travelers);
                  }

                  formikSetField('isPccover', event.target.checked);
                }}
                checked={formikValues.isPccover && !isPccover ? setIsPccover(true) ? formikValues.isPccover : formikValues.isPccover : formikValues.isPccover}
              />
              <div className={classes.icon}>

                <img src={laptopIcon} alt='icon' />
              </div>
            </div>
            <Grid item xs={12} className={`${classes.descriptionAfter}`}>
              <label htmlFor='isPccover' className={classes.description}>{intl.formatMessage({id: 'step1.tab5.pccover'})}</label>
              <label htmlFor='isPccover'>{intl.formatMessage({id: 'step1.tab5.pccover_fulltext'})}</label>
              {isPccover ? <Grid item xs={12}>
                <FormGroup>
                  {formikValues.travelers.map((traveler, i) => (traveler.gender === 1 ? false :
                    (<div>
                      {formikValues.travelers.length > 1 ? <FormControlLabel
                        key={`traveler-${i}-pcCover`}
                        label={traveler.fullName}
                        aria-label={`${traveler.fullName} ${intl.formatMessage({id: 'step1.tab5.pccover_ariaLabel'})}`}
                        control={<Checkbox
                          name='traveler-pcCover'
                          data-testid={`traveler-${i + 1}-pcCover`}
                          checked={Boolean(traveler.needPcCoverage)}
                          onChange={(event, value) => {
                            formikSetField(`traveler-${i}-pcCover`, value);
                            clickPcCoverage(traveler, value);
                          }}
                        />}
                      /> : null }

                      <Grid item xs={12} className={classes.descriptionAfter2Inputs}>
                        {renderDescription8After2(traveler, i, formikSetField)}
                      </Grid>
                    </div>)))}
                  {formikValues.travelers.length > 1 ? <FormHelperText component='span' error>{formikErrors.pcCoverError}</FormHelperText> : null }
                </FormGroup>
              </Grid> : null }
            </Grid>
          </Grid>
          {/* Seventh checkbox block */}
          <Grid container item xs={12} className={classes.title2}>
            <div className={classes.checkboxIconContainer}>

              <Checkbox
                name='isMobilecover'
                id='isMobilecover'
                data-testid='isMobilecover'
                onChange={(event) => {
                  setIsMobilecover(event.target.checked);
                  if (formikValues.travelers.length === 1) {
                    clickMobileCoverage(formikValues.travelers[0], event.target.checked);
                  }
                  formikSetField('isMobilecover', event.target.checked);
                }
                }
                checked={formikValues.isMobilecover && !isMobilecover ? setIsMobilecover(true) ? formikValues.isMobilecover : formikValues.isMobilecover : formikValues.isMobilecover}
              />
              <div className={classes.icon}>
                <img src={cellphoneIcon} alt='icon' />
              </div>
            </div>
            <Grid item xs={12} className={`${classes.descriptionAfter}`}>
              <label htmlFor='isMobilecover' className={classes.description}>{intl.formatMessage({id: 'step1.tab5.mobilecover'})}</label>
              <label htmlFor='isMobilecover'>{intl.formatMessage({id: 'step1.tab5.mobilecover_fulltext'})}</label>
              {isMobilecover ? <Grid iten xs={12} >
                <FormGroup>
                  {formikValues.travelers.map((traveler, i) => (traveler.gender === 1 ? false :
                    (<div>
                      {formikValues.travelers.length > 1 ? <FormControlLabel
                        key={`traveler-${i}-mobileCover`}
                        label={traveler.fullName}
                        aria-label={`${traveler.fullName} ${intl.formatMessage({id: 'step1.tab5.mobilecover_ariaLabel'})}`}
                        control={<Checkbox
                          name={`traveler-${i}-mobileCover`}
                          data-testid={`traveler-${i + 1}-mobileCover`}
                          checked={Boolean(traveler.needMobileCoverage)}
                          onChange={(event, value) => {
                            clickMobileCoverage(traveler, value);
                          }}
                        />}
                      /> : null }
                      <Grid item xs={12} className={classes.descriptionAfter2Inputs}>
                        {renderDescription9After2(traveler, i, formikSetField)}
                      </Grid>

                    </div>)))}
                  {formikValues.travelers.length > 1 ? <FormHelperText component='span' error>{formikErrors.mobileCoverError}</FormHelperText> : null}
                </FormGroup>
              </Grid> : null}
            </Grid>
          </Grid>
          {/* Eighth checkbox block  */}
          {
            formikValues.travelers.some((traveler) => traveler.age >= 24) ?
              <Grid container item xs={12} className={classes.title2}>
                <div className={classes.checkboxIconContainer}>

                  <Checkbox
                    name='isCancellationOfSelfParticipationOnRentedCar'
                    id='isCancellationOfSelfParticipationOnRentedCar'
                    data-testid='isCancellationOfSelfParticipationOnRentedCar'
                    onChange={(event) => {
                      setIsCancellationOfSelfParticipationOnRentedCar(event.target.checked);

                      // if we have only one traveler, we need to change the value of the traveler
                      // and in case we have only one travler of age higher than 24 so we need to change the value this traveler
                      // so we need to find this traveler and change his value
                      let indexOfTraveler;

                      // check if we have only one traveler of age higher than 24
                      for (let i = 0; i < formikValues.travelers.length; i++) {
                        if (formikValues.travelers[i].age > 24) {
                          if (indexOfTraveler !== undefined) {
                            indexOfTraveler = undefined;
                            break;
                          } else {
                            indexOfTraveler = i;
                          }
                        }
                      }
                      if (formikValues.travelers.length === 1) {
                        clickCancellationOfSelfParticipationOnRentedCar(formikValues.travelers[0], event.target.checked);
                      } else if (indexOfTraveler !== undefined) {
                        clickCancellationOfSelfParticipationOnRentedCar(formikValues.travelers[indexOfTraveler], event.target.checked);
                      }
                      formikSetField('isCancellationOfSelfParticipationOnRentedCar', event.target.checked);
                    }
                    }
                    checked={
                      formikValues.isCancellationOfSelfParticipationOnRentedCar && !isCancellationOfSelfParticipationOnRentedCar ?
                        setIsCancellationOfSelfParticipationOnRentedCar(true) ?
                          formikValues.isCancellationOfSelfParticipationOnRentedCar : formikValues.isCancellationOfSelfParticipationOnRentedCar : formikValues.isCancellationOfSelfParticipationOnRentedCar
                    }
                  />
                  <div className={classes.icon}>

                    <img src={carIcon} alt='icon' />
                  </div>

                </div>
                <Grid item xs={12} className={`${classes.descriptionAfter}`}>
                  <label htmlFor='isCancellationOfSelfParticipationOnRentedCar' className={classes.description}>{intl.formatMessage({id: 'step1.tab5.cancellationOfSelfParticipationOnRentedCar'})}</label>
                  <label htmlFor='isCancellationOfSelfParticipationOnRentedCar'>{intl.formatMessage({id: 'step1.tab5.cancellationOfSelfParticipationOnRentedCar_fulltext'})}</label>
                  {isCancellationOfSelfParticipationOnRentedCar ? (
                    <Grid item xs={12} style={{margin: 0}} className={`${classes.descriptionAfter}`}>
                      <FormGroup>
                        {formikValues.travelers.length > 1 ?
                          <p className={classes.explainWhatTodoText} htmlFor='isCancellationOfSelfParticipationOnRentedCar'>{intl.formatMessage({id: 'step1.tab5.cancellationOfSelfParticipationOnRentedCar_explain_what_todo'})}</p> :
                          null
                        }
                        {formikValues.travelers.map((traveler, i) => (traveler.age < 24 ? false :
                          (<div>
                            {formikValues.travelers.length > 1 ? <FormControlLabel
                              key={`traveler-${i}-cancellationOfSelfParticipationOnRentedCar`}
                              label={traveler.fullName}
                              aria-label={`${traveler.fullName} ${intl.formatMessage({id: 'step1.tab5.cancellationOfSelfParticipationOnRentedCar_ariaLabel'})}`}
                              control={<Checkbox
                                name={`traveler-${i}-cancellationOfSelfParticipationOnRentedCar`}
                                data-testid={`traveler-${i + 1}-cancellationOfSelfParticipationOnRentedCar`}
                                checked={Boolean(traveler.cancellationOfSelfParticipationOnRentedCar)}
                                onChange={(event, value) => {
                                  clickCancellationOfSelfParticipationOnRentedCar(traveler, value);
                                }}
                              />}
                            /> : null }
                          </div>)))}
                        {formikValues.travelers.length > 1 ? <FormHelperText style={{margin: '10px'}} component='span' error>{formikErrors.isCancellationOfSelfParticipationOnRentedCarError}</FormHelperText> : null}
                      </FormGroup>
                    </Grid>
                  ) : null }
                </Grid>
              </Grid> : null
          }

          {/* Last checkbox */}
          <Grid item xs={12} className={classes.title2}>
            <EzFormControl name='isTerms' isInlineLabel showError={false}>
              <Checkbox
                aria-label={intl.formatMessage({id: 'step1.tab5.terms-ariaLabel'})}
                id='isTerms'
                data-testid='isTerms'
                onChange={(event) => formikSetField('isTerms', event.target.checked)}
                checked={formikValues.isTerms}
              />
              <span className={classes.terms}>
                {intl.formatMessage({id: 'step1.tab5.terms-1'})}
                <Link
                  href={agentDeveloperUtils.getAgentLinks.termsAndConditions}
                  target='_blank'
                >
                  {intl.formatMessage({id: 'step1.tab5.terms-link'})}
                </Link>
                {intl.formatMessage({id: 'step1.tab5.terms-2'})}
              </span>
            </EzFormControl>
          </Grid>
          <Grid item xs={12} className={`${classes.descriptionAfter}`}>
            <FormHelperText component='span' className={classes.helper} error>{formikErrors.isTerms}</FormHelperText>
          </Grid>

          <Grid item xs={12} className={classes.gridSubmitBtn}>
            {actionButton &&
              actionButton({
                children: <FormattedMessage id='continue' />,
                className: classes.submitBtn,
                size: 'large',
                onClick: handleSubmit,
                variant: 'contained',
                'data-testid': 'continue-button-4',
                'aria-label': formikErrors && Object.keys(formikErrors).length ?
                  `${intl.formatMessage({id: 'continue'})}, ${intl.formatMessage({id: 'validation.needToFillAllFields'})}` :
                  null,
              })}
          </Grid>
        </Grid>
        <SuggestionAddRiderModal
          isModalShow={showSuggestionAddRiderModal}
          title={intl.formatMessage({id: `step1.tab4.suggestion_add_rider_title_${typeOfSuggestionAddRiderModal}`})}
          description={intl.formatMessage({id: `step1.tab4.suggestion_add_rider_description_${typeOfSuggestionAddRiderModal}`})}
          subDescription={intl.formatMessage({id: `step1.tab4.suggestion_add_rider_subdescription_${typeOfSuggestionAddRiderModal}`})}
          onClose={handleSuggestionAddRiderModalClose}
          buttons={{
            button1: {
              image: presentorImage,
              text: intl.formatMessage({id: 'step1.tab4.suggestionAddRiderModal.button1'}),
              onClick: handleSuggestionAddRiderModalConfirm,
            },
            button2: {
              text: intl.formatMessage({id: 'step1.tab4.suggestionAddRiderModal.button2'}),
              onClick: handleSuggestionAddRiderModalDecline,
            },
          }}
        />

      </div>
    );
  },
);

const useStyles = makeStyles(theme => ({
  existingCoverages: {
    maxWidth: 795,
  },
  checkboxIconContainer: {
    alignItems: 'baseline',
    display: 'flex',
    flex: 0.1,
    justifyContent: 'space-between',
  },
  completeIcon: {
    content: '" "',
    backgroundImage: `url(${CompleteIcon})`,
    backgroundRepeat: 'no-repeat',
    height: '16px',
    width: '17px',
    marginLeft: 5,
    display: 'inline-block',
  },
  icon: {
    width: '25px',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  title: {
    marginTop: 20,
  },
  title2: {
    marginTop: 10,
  },
  description: {
    fontWeight: 'bold',
    verticalAlign: 'middle',
    display: 'inline-block',
    width: 'calc(100% - 45px)',
    [theme.breakpoints.down(Breakpoints.mobile)]: {
      fontSize: theme.typography.pxToRem(13),
    },
  },
  descriptionAfter: {
    margin: '0 0 0 20px',
    display: 'flex',
    flex: 0.9,
    flexWrap: 'wrap',
    [theme.breakpoints.down(Breakpoints.mobile)]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  descriptionAfter2: {
    margin: '0 0 0 45px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(Breakpoints.mobile)]: {
      fontSize: theme.typography.pxToRem(11),
    },
  },
  descriptionAfter2Inputs: {
    margin: '0 0 0 45px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(Breakpoints.mobile)]: {
      margin: 0,
    },
  },
  description5After2: {
    color: theme.palette.text.primary,
  },
  inputsContainer: {
    color: theme.palette.text.primary,
    margin: '20px 45px 0 0',
    [theme.breakpoints.down(Breakpoints.mobile)]: {
      margin: 0,
    },
  },
  terms: {
    '&>a': {
      fontWeight: 'normal',
      lineHeight: 1.43,
      fontSize: '1rem',
    },
    margin: '0 0 0 10px',
    fontWeight: 'normal',
    verticalAlign: 'middle',
    [theme.breakpoints.down(Breakpoints.mobile)]: {
      fontSize: theme.typography.pxToRem(13),
    },
  },
  circle: {
    verticalAlign: 'middle',
  },
  submitBtn: {
    fontSize: theme.typography.fontSize,
    width: 166,
  },
  gridSubmitBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '45px 0 0 0',
  },
  hide: {
    display: 'none',
  },
  explainWhatTodoText: {
    lineHeight: 1.5,
    marginBottom: '0.2rem',
    paddingLeft: '10px',
  },
}));
/* eslint-enable complexity */

Tab4.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object,
  submitCallback: PropTypes.func,
  formikIsSubmitting: PropTypes.bool,
  validationCallback: PropTypes.func,
  formikSetField: PropTypes.func,
  formikErrors: PropTypes.object,
  formikValues: PropTypes.object,
  formikSetTouched: PropTypes.func,
  formikValidateForm: PropTypes.func,
  setFieldValue: PropTypes.func,
  isforBlock4: PropTypes.bool,
  actionButton: PropTypes.object,
  idForAccessibility: PropTypes.string,
};

export default Tab4;
