import {makeStyles} from '@material-ui/core';
import {agentDeveloperUtils} from 'wobi-web-common';

export default makeStyles((theme) => ({
  button1: {
    // border: 2px solid #fff;
    // margin-right: 10px;
    // margin-left: 10px;
    backgroundColor: agentDeveloperUtils.getPrimaryColor(440),
    border: `2px solid ${agentDeveloperUtils.getSecondaryColor(150)}`,
    marginRight: '10px',
    marginLeft: '10px',
    color: '#ffffff',

  },
  button2: {
    backgroundColor: '#ffffff',
    border: `2px solid ${agentDeveloperUtils.getSecondaryColor(150)}`,
    borderStyle: 'solid',
    color: agentDeveloperUtils.getSecondaryColor(440),
    marginRight: '10px',
    marginLeft: '10px',
  },
  buttons: {
    width: '202px',
    height: '40px',
    borderRadius: '20px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: 'max(1.2em, 35px)',
    padding: '20px',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  description: {
    color: '#fff',
    fontSize: 'max(2em, 35px)',
    fontFamily: 'Ploni, Arial, sans-serif',
    marginBottom: 10,
  },
  popupTextHighlited: {
    color: agentDeveloperUtils.getConvertedColor('#25c4ee'),
  },
  popupText: {
    color: agentDeveloperUtils.getSecondaryColor(150),
  },
  modalPaper: {
    backgroundColor: agentDeveloperUtils.getConvertedColor('#2c729b'),
  },

  buttonsWrapper: {
    display: 'flex',
    width: '45%',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  actionButton: {

  },
  popupClose: {
    backgroundColor: agentDeveloperUtils.getConvertedColor('#236A92'),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
