import React from 'react';
import {Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

import useStyles from './styles';
import {apiRequest} from '../../../../utils/apiHelpers';
import {fireGtmEvent} from '../../../../utils/marketing.utils';

function FirstStep({ increaseStep, values, hideContactPassportCard }) {
  const classes = useStyles();
  const intl = useIntl();

  const handleButtonClick = (company) => {
    const { firstName1, lastName1, phone1 } = values;
    const data = {
      fullName: firstName1 + ' ' + lastName1,
      phoneNumber: phone1,
      provider: company,
    };

    apiRequest({ endpoint: 'api/callback', data, method: 'POST' })
      .then(increaseStep)
  };

  return (
    <>
      <span className={classes.text}>
        {intl.formatMessage({
          id: hideContactPassportCard ?
            'unsupported_medical_cases_modal.first_step.text_unsupported' :
            'unsupported_medical_cases_modal.first_step.text',
        })}
      </span>
      <div className={classes.buttonsWrapper}>
        {hideContactPassportCard ? null : (
          <Button
            className={classes.actionButton}
            onClick={() => {
              fireGtmEvent('ContactPassportcard');
              handleButtonClick('passportcard');
            }}
          >
            {intl.formatMessage({id: 'unsupported_medical_cases_modal.first_step.button1'})}
          </Button>
        )}
        {/* <Button
          className={classes.actionButton}
          onClick={() => {
            fireGtmEvent('ContactShirbit');
            handleButtonClick('shirbit');
          }}
        >
          { intl.formatMessage({ id: 'unsupported_medical_cases_modal.first_step.button2' }) }
        </Button> */}
      </div>
    </>
  )
}

FirstStep.propTypes = {
  increaseStep: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  hideContactPassportCard: PropTypes.bool,
};

FirstStep.defaultProps = {
  hideContactPassportCard: false,
};

export default FirstStep;
