import stepsTabs from '../config/tabs';
import steps from '../config/steps';
import countries from 'wobi-web-common/dist/assets/js/countries';

const getStepName = (location) => {
  const {pathname} = location;
  return pathname === '/' ? 'details' : pathname.replace('/', '');
};

const areBreadCrumbsNeeded = (step) => Boolean(steps.find(item => item.key === step));

const getTabsPercentage = (location, step) => {
  const tabs = stepsTabs()[step];
  const tabIndex = getCurrentTabIndex(location, step);
  return tabIndex !== false ? tabIndex / tabs.length * 100 : 0;
};

const tabIndexToName = (tabIndex, tabs) => {
  let tabObject = tabs.find(tab => tab.index === tabIndex);
  if (!tabObject) {
    // try to find next tab
    tabObject = tabs.find(tab => tab.index === (tabIndex + 1));
  }
  return tabObject ? tabObject.key : false;
};

const getCurrentTabIndex = (location, step) => {
  const tabs = stepsTabs()[step];
  if (!tabs) {
    return false;
  }

  const {hash} = location;
  const tabKey = hash.replace('#', '');
  const tabObject = tabs.find(tab => tabKey === tab.key);
  return tabObject ? tabObject.index : false;
};

const stepNameToIndex = (name) => {
  const stepObject = steps.find(item => item.key === name);
  return stepObject ? stepObject.index : null;
};

const destinationsToNames = (Ids) => {
  let result = '';
  Ids.forEach(val => {
    const destination = countries.find(item => item.value === val);
    result += destination ? result ? ', ' + destination.label : destination.label : '';
  });

  return result;
};

export {
  areBreadCrumbsNeeded,
  getCurrentTabIndex,
  getTabsPercentage,
  stepNameToIndex,
  tabIndexToName,
  getStepName,
  destinationsToNames
};
