import PhoenixLogo from './150x84/FenixLogo.png';
import MenoraLogo from './150x84/MenoraLogo.png';
import ClalLogo from './150x84/ClalLogo.png';
import ShirbitLogo from './150x84/ShirbitLogo.png';
import HarelLogo from './150x84/HarelLogo.png';
import HachsharaLogo from './150x84/HachsharaLogo.png';
import PassportCardLogo from './150x84/PassportCardLogo.png';
import MigdalLogo from './150x84/MigdalLogo.png';

export default {
  PhoenixLogo,
  MenoraLogo,
  ClalLogo,
  ShirbitLogo,
  HarelLogo,
  HachsharaLogo,
  PassportCardLogo,
  MigdalLogo,
};
