import React from 'react';
import Moment from 'moment';
import {Grid, Box, Table, TableCell, TableRow, TableBody, Typography,
  Button, SnackbarContent, Icon, makeStyles} from '@material-ui/core';
  import Alert from '@material-ui/lab/Alert';

import {Blue} from 'wobi-web-common/dist/config/colors.config';
import {FormattedMessage} from 'react-intl';
import {destinationsToNames} from '../../utils/routingHelpers';

const ThankYou = React.memo(({className, offer, initialTabs, ccNumber, orderId}) => {
  const classes = useStyles();

  return (
    <div className={className} data-testid='confirmation-container'>
    <Grid container>
    <Grid item xs={12} lg={6}>
      <Box mb={4} pb={4} borderBottom={1} className={classes.borderColorLight}>
        <Grid container spacing={0}>
          <Grid item xs={12}><Typography className={classes.messageHead} component={'h1'}><FormattedMessage id='thank_you.tank_you'/></Typography></Grid>
          <Grid item xs={12}><Box className={classes.messageBigLetters}><FormattedMessage id='thank_you.success'/></Box></Grid>
          <Grid item xs={12}><Box className={classes.messageBigLetters}><FormattedMessage id='thank_you.order_number_text'/> <span className={classes.colorAccent}>{orderId}</span></Box></Grid>
        </Grid>
      </Box>
    </Grid>
    <Grid item xs={12} lg={7}>
      <Box mb={4}>
        <Grid container spacing={2}>
          <Grid item>
              <Box mb={2}>
                <Typography className={classes.messageSubhead}>
                  <strong><FormattedMessage id='thank_you.table_title'/></strong>
                </Typography>
              </Box>
            <Box>
              <Table className={classes.tableSmall} size="small">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <FormattedMessage id='thank_you.table_label_period'/>
                    </TableCell>
                    <TableCell>
                      {initialTabs?.policyStartDate && initialTabs?.policyEndDate ? (
                        <FormattedMessage id='thank_you.table_value_period' values={{
                          start_date: Moment(initialTabs.policyStartDate).format('DD.MM.YYYY'),
                          end_date: Moment(initialTabs.policyEndDate).format('DD.MM.YYYY'),
                        }}/>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <FormattedMessage id='thank_you.destination'/>
                    </TableCell>
                    <TableCell>
                      {destinationsToNames(initialTabs.targetCountries)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <FormattedMessage id='thank_you.insurance_period'/>
                    </TableCell>
                    <TableCell>
                      {Moment(initialTabs.policyEndDate).diff(Moment(initialTabs.policyStartDate), 'days') + 1} <FormattedMessage id='step3.stayOf2'/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <FormattedMessage id='thank_you.table_label_product_line'/>
                    </TableCell>
                    <TableCell>
                      {offer.providerName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <FormattedMessage id='thank_you.table_label_owner'/>
                    </TableCell>
                    <TableCell>{initialTabs.firstName1} {initialTabs.lastName1}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <FormattedMessage id='step1.tab2.gender'/>
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id={initialTabs.gender1 === '1' ? 'male' : 'female'}/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <FormattedMessage id='step1.tab2.phone'/>
                    </TableCell>
                    <TableCell>{initialTabs.phone1}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <FormattedMessage id='thank_you.table_label_price'/><br />
                      <span className={classes.messageCellTitle}>
                        <FormattedMessage id='thank_you.bank_of_israel_payment_info'/>
                      </span>
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id='thank_you.table_value_price' values={{price: offer.convertedPrice}}/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <FormattedMessage id='thank_you.table_label_cc'/>
                    </TableCell>
                    <TableCell>
                      {ccNumber}*
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Grid>
          <Grid item xs={12} lg={12} container spacing={3} alignItems={'center'}>
            <Grid item xs={6} md={5}>
              <Button
                variant='outlined'
                color='secondary'
                size='medium'
                href={`/policies/${offer.productLineId}/policy.pdf`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icon className={classes.downloadIcon}>get_app</Icon> <FormattedMessage id='thank_you.button_download'/>
              </Button>
            </Grid>
            <Grid item xs={6} md={7}>
              <span className={classes.messagePrimary}></span>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Box borderTop={1} className={classes.borderColorGray} pt={2}>
        <SnackbarContent className={classes.alertTransparent} message={<FormattedMessage id='thank_you.bottom_notification'/>}/>
      </Box>
    </Grid>
    </Grid>
    </div>
  );
});

const useStyles = makeStyles(theme => ({
  messageHead: {
    fontWeight: 500,
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.pxToRem(21),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(15.75),
      lineHeight: '28px',
    },
  },
  messageBigLetters: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(36.75),
    lineHeight: '52px',
    color: theme.palette.secondary.dark,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(28),
    },
  },
  messageSubhead: {
    fontSize: theme.typography.pxToRem(15.75),
    fontWeight: 500,
    color: theme.palette.secondary.dark,
  },
  messageCellTitle: {
    fontSize: theme.typography.pxToRem(10.5),
    fontWeight: 500,
    color: theme.palette.secondary.dark,
  },
  colorAccent: {
    color: theme.palette.primary.main,
  },
  messagePrimary: {
    fontSize: theme.typography.pxToRem(12.25),
    fontWeight: 500,
    color: theme.palette.secondary.dark,
  },
  messageRegular: {
    fontSize: theme.typography.pxToRem(15.75),
    color: theme.palette.text.secondary,
  },
  boxAlert: {
    fontSize: theme.typography.pxToRem(15.75),
    backgroundColor: 'rgba(226, 226, 226, 0.16)',
  },
  boxAlertTitle: {
    fontWeight: 600,
    color: '#8e8e8e',
    marginBottom: 8,
  },
  boxAlertMessage: {
    color: '#9D9D9D',
  },
  vAlignMiddle: {
    verticalAlign: 'middle',
  },
  cellTitle: {
    fontWeight: 500,
    borderRight: `1px solid ${theme.palette.divider}`,
    color: theme.palette.secondary.main,
  },
  tableSmall: {
    fontSize: theme.typography.pxToRem(12.25),
  },
  alertTransparent: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    border: 'none',
    color: theme.palette.text.secondary,
    fontWeight: '500',
  },
  borderColorLight: {
    borderColor: Blue[100],
  },
  borderColorGray: {
    borderColor: '#707070',
  },
  downloadIcon: {
    fontSize: theme.typography.pxToRem(17.5),
    color: theme.palette.primary.main,
    marginRight: 5,
  },
  target: {
    paddingRight: 5,
  },
}));

export default ThankYou;
