import React from 'react';
import BaseHeader from 'wobi-web-common/dist/components/Header';
import Breadcrumbs from 'wobi-web-common/dist/components/Breadcrumbs';
import MainTitle from './MainTitle';
import {withStyles} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {fireGtmEvent} from '../utils/marketing.utils';
import {handleContactUsSubmit} from '../utils/apiHandlers';
import {getTabsPercentage, areBreadCrumbsNeeded, getStepName,
  stepNameToIndex} from '../utils/routingHelpers';
import fullDataSteps from '../config/steps';
import {Breakpoints, agentDeveloperUtils} from 'wobi-web-common';
import routes from '../config/routes';
import {getDetailForm, setDetailForm} from '../utils/persistOfferDetails';

const styles = theme => ({
  breadcrumbs: {
    maxWidth: 500,
    padding: '10px 0',
    '& .MuiPaper-root': {
      padding: 0,
    },
    [theme.breakpoints.down(Breakpoints.mobile)]: {
      padding: '0 50px',
      margin: 0,
    },
  },
});

const steps = fullDataSteps.reduce((curry, step) => {
  curry.push(step.label);
  return curry;
}, []);

window.addEventListener('popstate', (event) => {
  if ('/offers' === window.location.pathname ||
      ('/details' === window.location.pathname && '#extra' === window.location.hash ||
      '/complete-details' === window.location.pathname)) {
    window.location.assign('/');
    sessionStorage.clear();
  }
}, false);

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
  }

  state = {
    percentage: 0,
    stepIndex: 0,
    showSteps: true,
    tabs: [],
    isHeaderFixed: this.props.location.pathname !== '/offers',
    isMainTitleVisible: routes ? routes.includes(this.props.location.pathname) : true,
  };

  handleBack() {
    fireGtmEvent('TravelTimePick');
    this.props.history.goBack();
  }

  componentDidMount() {
    this.setStepAndPercentage(this.props.location);
    this.unlisten = this.props.history.listen((location) => {
      this.setStepAndPercentage(location);
    });
  }

  componentDidUpdate(previousProps, previousState, snapshot) {
    if (previousProps.location !== this.props.location) {
      this.setState({isHeaderFixed: this.props.location.pathname !== '/offers'});
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  setStepAndPercentage = (location) => {
    const step = getStepName(location);

    // Hide breadcrumbs on specific pages
    const hideBreadCrumbs = !areBreadCrumbsNeeded(step);
    if (hideBreadCrumbs) {
      this.setState({showSteps: false});
      return;
    }

    const stepIndex = stepNameToIndex(step);
    const percentage = getTabsPercentage(location, 'details');
    this.setState({percentage,
      stepIndex});
  };

  handleClick = (index) => {
    if (fullDataSteps[index].meta) {
      fireGtmEvent(fullDataSteps[index].meta);
    }
    if (index === 0) {
      this.props.history.push('/#destination');
    }
  }

  render() {
    const classes = this.props.classes;
    const headerEvents = {
      callHandler: () => fireGtmEvent('TravelCall'),
      chatHandler: () => fireGtmEvent(this.props.isMobile ? 'Travel2Chat' : 'Travel2ChatDSK'),
      headerContactHandler: () => fireGtmEvent('TravelHeaderContact'),
      logoHandler: () => fireGtmEvent('Travel2HomePage'),
    };

    return (
      <React.Fragment>
        <BaseHeader
          backFunction={this.handleBack}
          handler={(values) => {
            fireGtmEvent('TravelContactUs');
            return handleContactUsSubmit(values);
          }}
          isHeaderFixed={this.state.isHeaderFixed}
          hasContactus={false}
          hasPhoneNumber={false}
          hasEmail={agentDeveloperUtils.getAgentEmail}
          {...headerEvents}
        />
        {
          this.state.isMainTitleVisible && (
            <MainTitle
              mainText={<React.Fragment><FormattedMessage id='travelInsurance'/></React.Fragment>}
              subText=''
            />
          )
        }
        {
          this.state.showSteps && (
            <Breadcrumbs
              className={classes.breadcrumbs}
              currentStep={this.state.stepIndex}
              subStepPercentage={this.state.percentage}
              steps={steps}
              handleClick={this.handleClick}
            />
          )
        }
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(Header));
