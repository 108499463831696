import React from 'react';
import {Backdrop, Modal, Paper, Box} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import useStyles from './styles';

function ErrorMessagePopup({isOpen, onClose}) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Modal
      open={isOpen}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{timeout: 500}}
      onClose={onClose}
    >
      <Paper className={classes.paper}>
        <div data-testid='error-popup' className={classes.modalHeader}>
          <Box onClick={onClose}><span className={classes.buttonDismiss}/></Box>
        </div>
        <div className={classes.modalBody}>
          <h3 className={classes.title}>
            {intl.formatMessage({id: 'an_error_occurred_1'}) }
          </h3>
          <h6 className={classes.subtitle}>
            {intl.formatMessage({id: 'an_error_occurred_2'})}
          </h6>
        </div>
      </Paper>
    </Modal>
  );
}

ErrorMessagePopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ErrorMessagePopup;

