import React, {useRef} from 'react';
import DatePicker from 'wobi-web-common/dist/components/Datepicker';
import EzFormControl from 'wobi-web-common/dist/components/EzFormControl';
import * as yup from 'yup';
import moment from 'moment';
import {
  makeStyles,
  Button,
  MenuItem,
  FormControl,
  FormControlLabel,
  Grid,
  Select,
  Checkbox,
  TextField,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import {Breakpoints} from 'wobi-web-common';
import {FormattedMessage, useIntl} from 'react-intl';
import {Blue} from 'wobi-web-common/dist/config/colors.config';
import {
  validateDate,
  validateForHebrew,
  validatePhoneNumber,
  validateForIsraeliID,
} from '../../../utils/validationHelpers';
import {fireGtmEvent} from '../../../utils/marketing.utils';
import {utils} from './helpers/Tab3_helpers/utils';

const Tab2 = React.memo(
  ({
    idForAccessibility,
    className,
    submitCallback,
    validationCallback,
    formikSetField,
    formikHandleChange,
    formikErrors,
    formikValues,
    formikSetTouched,
    formikValidateForm,
    actionButton,
  }) => {
    const intl = useIntl();
    const [selectValue, setSelectValue] = React.useState(['']);
    const classes = useStyles();
    const selectItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const policyHolderMinDOB = moment().subtract(85, 'year');
    const policyHolderMaxDOB = moment().subtract(0, 'year');

    const validationObject = {
      isLeftIsrael: yup.boolean().oneOf([true], 'שדה חובה'),
    };
    const firstNameValidationText = 'נא להזין שם פרטי בעברית';
    const lastNameValidationText = 'נא להזין שם משפחה בעברית';

    // Dynamic block for validation IDx and OtherX fields on unique
    if (Number(selectValue) === 1) {
      selectItems.forEach((index) => {
        delete validationObject['ID' + index];
      });
      validationObject.ID1 = validateForIsraeliID();
      validationObject.dob1 = validateDate(
        policyHolderMinDOB,
        policyHolderMaxDOB,
      );
      validationObject.firstName1 = validateForHebrew(
        firstNameValidationText,
      );
      validationObject.lastName1 = validateForHebrew(lastNameValidationText);
      validationObject.gender1 = yup
        .string()
        .typeError()
        .required('שדה חובה');
      validationObject.phone1 = validatePhoneNumber();

      // dynamic callback to React.Component method
      setTimeout(() => {
        validationCallback(validationObject);
      }, 1);
    } else {
      selectItems.forEach((index) => {
        if (selectValue >= index) {
          const temporaryArray = [];
          selectItems.forEach((index2) => {
            if (index !== index2 && selectValue >= index2) {
              temporaryArray.push(yup.ref('ID' + index2));
            }
          });
          validationObject.phone1 = validatePhoneNumber();
          validationObject['ID' + index] = validateForIsraeliID().notOneOf(
            temporaryArray,
            'שדה זה חייב להיות ייחודי',
          );
          validationObject['dob' + index] = validateDate(
            policyHolderMinDOB,
            policyHolderMaxDOB,
          );
          validationObject['firstName' + index] = validateForHebrew(
            firstNameValidationText,
          );
          validationObject['lastName' + index] = validateForHebrew(
            lastNameValidationText,
          );
          validationObject[
            'gender' + index
          ] = yup.string().typeError().required('שדה חובה');
        }
      });
      setTimeout(() => {
        validationCallback(validationObject);
      }, 1); // dynamic callback to React.Component method
    }

    const handleChangeGender = (key, value, setter, index) => {
      if (
        value === '1' &&
        utils.filterWomenTravelers(formikValues.travelers).length <= 1
      ) {
        setter('isPregEndangered', '');
        setter('isPregnancy', '');
      }
      if (value === '1') {
        index = Number(index);
        setter(`week${index}`, '');
        const arrayIndex = formikValues.pregnancyIdentifica.findIndex(
          (id) => id === formikValues[`ID${index + 1}`],
        );
        if (arrayIndex != -1) {
          setter('pregnancyIdentifica', [
            ...formikValues.pregnancyIdentifica.slice(0, arrayIndex),
            ...formikValues.pregnancyIdentifica.slice(
              arrayIndex + 1,
              formikValues.pregnancyIdentifica.length,
            ),
          ]);
        }
      }
      setter(key, value);
    };

    const handleSubmit = async () => {
      const errors = await formikValidateForm();
      if (Object.keys(errors).length === 0) {
        fireGtmEvent('confirmPolicyOwners', {
          insuredNumber: formikValues.phone1,
          birthDate: formikValues.dob1,
          gender: formikValues.gender1,
        });
        const {values} = JSON.parse(sessionStorage.getItem('travel.details-form')) || {};
        const {phone1} = values || {};

        if (phone1 && phone1.length < 10) {
          formikSetField('phone1', phone1);
          formikSetTouched('phone1', true);
          window.rollbar.error(JSON.stringify({
            browser: window.navigator.appCodeName,
            browserVersion: window.navigator.appVersion,
            field: 'phone1',
            fieldValue: formikValues.phone1,
          }));
        } else {
          submitCallback(2);
        }
      } else {
        Object.keys(validationObject).forEach((field) => formikSetTouched(field, true));
      }
    };

    if (
      formikValues.policyholdNum &&
      formikValues.policyholdNum !== selectValue
    ) {
      setSelectValue(formikValues.policyholdNum);
    }
    const handleChange = (event) => {
      if (formikValues.policyholdNum > event.target.value) {
        emptyPersonalFieldsData(formikValues.policyholdNum, event.target.value);
      }
      formikSetField('policyholdNum', event.target.value);
      setSelectValue(event.target.value);
    };

    const handleDoBChange = (value, setFieldValue, dobName) => {
      value = moment(value).format('YYYY-MM-DD');
      setFieldValue(dobName, value);
    };
    const handleIdChange = (event, setFieldValue, idName) => {
      const newValue = event.target.value.trim().replace(/\D/g, '');
      setFieldValue(idName, newValue);
    };

    const emptyPersonalFieldsData = (previousPolicyholdNumber, newPolicyholdNumber) => {
    // case of choosing less travelers: delete relevant fields-data (e.g firstName2...)
      for (let i = 1; i <= previousPolicyholdNumber; i++) {
        if (i > newPolicyholdNumber) {
          formikSetField(`ID${i}`, '');
          formikSetField(`dob${i}`, null);
          formikSetField(`firstName${i}`, '');
          formikSetField(`lastName${i}`, '');
          formikSetField(`gender${i}`, '');
          formikSetField(`week${i - 1}`, '');
        }
      }
      if (formikValues.travelers) {
        const newTravelers = formikValues.travelers.slice(0, newPolicyholdNumber);
        const oldTravelersIds = formikValues.travelers.slice(newPolicyholdNumber).map(t => t.ID);
        formikSetField('travelers', newTravelers);
        if (formikValues.isPregnancy === 'yes' && !newTravelers.find(tr => tr.pregnant)) {
          formikSetField('isPregnancy', 'no');
        }
        if (formikValues.isChronic === 'yes') {
          if (!newTravelers.find(tr => tr.chronic)) {
            formikSetField('isChronic', 'no');
          }
          const newMinorDiseases = formikValues.minorDiseases.map(md => {
            const newMd = {...md};
            if (newMd.chosenTravelers.find(chtr => oldTravelersIds.includes(chtr))) {
              newMd.chosenTravelers = newMd.chosenTravelers.filter(id => !oldTravelersIds.includes(id));
              if (newMd.chosenTravelers.length === 0) {
                newMd.checked = false;
              }
            }
            return newMd;
          });
          formikSetField('minorDiseases', newMinorDiseases);
        }
        if (formikValues.isAdditionalChronic) {
          if (!newTravelers.find(tr => tr.additionalChronic)) {
            formikSetField('isAdditionalChronic', 'no');
          }
          const newAddChrIds = formikValues.additChronIdentific.filter(id => !oldTravelersIds.includes(id));
          formikSetField('additChronIdentific', newAddChrIds);
        }
      }
    };

    const FormBlocks = []; // For rendering travellers form blocks
    Object.keys(selectItems).map((i) => {
      const dobName = 'dob' + selectItems[i];
      if (formikValues.policyholdNum >= selectItems[i]) {
        FormBlocks.push(
          <Grid
            container
            spacing={0}
            name={'policyholdDetails-' + selectItems[i] + '-div'}
            id={'policyholdDetails-' + selectItems[i] + '-div'}
            className={
              formikValues.policyholdNum < selectItems[i] ?
                classes.hide :
                classes.insured
            }
            key={`blockNo-${i}`}
            xs={12}
            sm={6}
            lg={4}
            xl={3}
          >
            <div className={classes.title}>
              {selectItems[i].toString() === '1' ?
                intl.formatMessage({id: 'step1.tab2.DoBTitle_primary'}) :
                intl.formatMessage({id: 'step1.tab2.DoBTitle'}) +
                  ' ' +
                  selectItems[i]}
            </div>
            <Grid item className={classes.birthdayInput}>
              <EzFormControl
                name={dobName}
                labelFor={dobName}
                label={intl.formatMessage({id: 'step1.tab2.dob'})}
                isLabelInChild
              >
                <DatePicker
                  value={formikValues[dobName]}
                  onChange={(date) => handleDoBChange(date, formikSetField, dobName)
                  }
                  onAccept={() => {}}
                  invalidDateMessage=''
                  maxDateMessage=''
                  minDateMessage=''
                  autoOk
                  minDate={policyHolderMinDOB}
                  maxDate={policyHolderMaxDOB}
                  id={dobName}
                  variant='inline'
                  data-testid={'dob-' + selectItems[i]}
                />
              </EzFormControl>
            </Grid>
            <Grid item>
              <EzFormControl
                name={'firstName' + selectItems[i]}
                labelFor={'firstName' + selectItems[i]}
                label={intl.formatMessage({id: 'step1.tab2.firstName'})}
                isLabelInChild
              >
                <TextField
                  data-testid={`first-name-${selectItems[i]}`}
                  onChange={formikHandleChange}
                  value={formikValues['firstName' + selectItems[i]]}
                  id={'firstName' + selectItems[i]}
                  margin='normal'
                  variant='outlined'
                />
              </EzFormControl>
            </Grid>
            <Grid item>
              <EzFormControl
                name={'lastName' + selectItems[i]}
                labelFor={'lastName' + selectItems[i]}
                label={intl.formatMessage({id: 'step1.tab2.lastName'})}
                isLabelInChild
              >
                <TextField
                  data-testid={`last-name-${selectItems[i]}`}
                  onChange={formikHandleChange}
                  value={formikValues['lastName' + selectItems[i]]}
                  id={'lastName' + selectItems[i]}
                  margin='normal'
                  variant='outlined'
                />
              </EzFormControl>
            </Grid>
            <Grid item>
              <EzFormControl
                name={'ID' + selectItems[i]}
                labelFor={'ID' + selectItems[i]}
                label={intl.formatMessage({id: 'step1.tab2.ID'})}
                isLabelInChild
              >
                <TextField
                  data-testid={`id-${selectItems[i]}`}
                  onChange={(text) => handleIdChange(text, formikSetField, `ID${selectItems[i]}`)}
                  value={formikValues['ID' + selectItems[i]]}
                  id={'ID' + selectItems[i]}
                  margin='normal'
                  variant='outlined'
                />
              </EzFormControl>
            </Grid>
            <Grid item className={classes.gender}>
              <div className={classes.title2}>
                {intl.formatMessage({id: 'step1.tab2.gender'})}
              </div>
              <EzFormControl
                name={'gender' + selectItems[i]}
                isInlineLabel={false}
              >
                <RadioGroup
                  row
                  value={formikValues['gender' + selectItems[i]]}
                  aria-label={intl.formatMessage({id: 'step1.tab2.gender'})}
                  onChange={(event) => handleChangeGender(
                    'gender' + selectItems[i],
                    event.target.value,
                    formikSetField,
                    i,
                  )
                  }
                >
                  <FormControlLabel
                    data-testid={`gender-male-${selectItems[i]}`}
                    value='1'
                    control={<Radio inputProps={{'aria-setsize': '2'}}/>}
                    label={intl.formatMessage({id: 'male'})}
                  />
                  <FormControlLabel
                    data-testid={`gender-female-${selectItems[i]}`}
                    value='2'
                    control={<Radio inputProps={{'aria-setsize': '2'}}/>}
                    label={intl.formatMessage({id: 'female'})}
                  />
                </RadioGroup>
              </EzFormControl>
            </Grid>
          </Grid>,
        );
      }
    });

    return (
      <div className={className ? className : classes.insuredForm} id={idForAccessibility} role='tabpanel'>
        <div className={classes.general}>
          <div>
            <EzFormControl
              name='policyholdNum'
              label={intl.formatMessage({id: 'step1.tab2.selectNumTitle'})}
              isInlineLabel={false}
            >
              <Select
                color='primary'
                className={classes.select}
                value={selectValue}
                onChange={handleChange}
                data-testid='select-policy-holders-number'
                SelectDisplayProps={{
                  'aria-label': intl.formatMessage({id: 'step1.tab2.selectNumTitle'}),
                  'aria-labelledby': null}}
              >
                {selectItems.map((item) => (
                  <MenuItem
                    data-testid={`policy-holders-number-${item}`}
                    key={item}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </EzFormControl>
          </div>
          <div>
            <EzFormControl
              name='phone1'
              labelFor='phone1'
              label='טלפון מבוטח ראשי'
              isInlineLabel={false}
            >
              <TextField
                id='phone1'
                data-testid='main-phone-number'
                autoFocus
                onChange={formikHandleChange}
                value={formikValues.phone1}
                margin='normal'
                variant='outlined'
              />
            </EzFormControl>
          </div>
        </div>

        <Grid container spacing={0} className={classes.main}>
          {FormBlocks}
        </Grid>

        <Grid container spacing={1} className={classes.summary}>
          <Grid item xs={12}>
            <EzFormControl name='isLeftIsrael' label='' isInputLabel>
              <Checkbox
                id='isLeftIsrael'
                label={intl.formatMessage({id: 'step1.tab2.isLeftIsrael'})}
                onChange={(event) => formikSetField('isLeftIsrael', event.target.checked)
                }
                checked={formikValues.isLeftIsrael}
                data-testid='is-left-israel'
              />
              <label htmlFor='isLeftIsrael'>
                {intl.formatMessage({id: 'step1.tab2.isLeftIsrael'})}
              </label>
              <br />
            </EzFormControl>
          </Grid>
          <Grid item xs={12}>
            {actionButton &&
              actionButton({
                children: <FormattedMessage id='continue' />,
                className: classes.submitBtn,
                size: 'large',
                onClick: handleSubmit,
                variant: 'contained',
                'data-testid': 'continue-button-2',
                'aria-label': formikErrors && Object.keys(formikErrors).length ?
                  `${intl.formatMessage({id: 'continue'})}, ${intl.formatMessage({id: 'validation.needToFillAllFields'})}` :
                  null,
              })}
          </Grid>
        </Grid>
      </div>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(10.5),
    paddingLeft: 30,
    paddingRight: 10,
    marginBottom: 10,
  },
  datepickerError: {
    marginTop: 0,
    marginBottom: 5,
  },
  general: {
    display: 'flex',
    flexFlow: 'row wrap',
    '&>div:first-child': {margin: '0 28px 16px 0'},
    '&>div:last-child': {minWidth: 163},
    '& .MuiFormControl-root.MuiFormControl-marginNormal': {marginTop: 0},
    '& label.MuiFormLabel-root': {
      width: 'auto',
      color: theme.palette.primary.main,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(17.5),
      transform: 'none',
      margin: '0 0 10px 12px',
    },
    [theme.breakpoints.down(650)]: {flexFlow: 'column nowrap'},
    [theme.breakpoints.up(768)]: {flexFlow: 'column nowrap'},
    [theme.breakpoints.up(1109)]: {flexFlow: 'row nowrap'},
  },
  select: {
    width: 170,
    [theme.breakpoints.down(Breakpoints.mobile)]: {
      width: '235px',
      display: 'block',
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    padding: '20px 12px 12px',
    alignSelf: 'flex-start',
  },
  insuredForm: {
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    '& .MuiOutlinedInput-input': {padding: '12px 25px'},
    '& .MuiFormControl-root.MuiFormControl-marginNormal': {marginBottom: 0},
    '& .MuiFormHelperText-contained': {margin: '3px 12px 0'},
    '& .MuiFormHelperText-root.Mui-error': {marginBottom: 0},
    '& .MuiOutlinedInput-root': {maxWidth: 237},
    '& .MuiFormLabel-root': {transform: 'translate(25px, 13px)'},
  },
  birthdayInput: {
    '& .MuiOutlinedInput-input': {paddingRight: 0},
  },
  main: {
    minWidth: 260,
  },
  insured: {
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 22px 24px',
    position: 'relative',
    '&::before': {
      content: '\' \'',
      display: 'block',
      width: 2,
      height: '90%',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      borderLeft: `2px dotted ${Blue[100]}`,
      right: -1,
      pointerEvents: 'none',
    },
    '&::after': {
      content: '\' \'',
      display: 'block',
      width: '90%',
      height: 2,
      position: 'absolute',
      right: '50%',
      transform: 'translateX(50%)',
      borderBottom: `2px dotted ${Blue[100]}`,
      bottom: -1,
      pointerEvents: 'none',
    },
    '&:last-child': {'&::before': {display: 'none'}},
    [theme.breakpoints.down(1528)]: {
      maxWidth: '50%',
      flexBasis: '50%',
    },
    [theme.breakpoints.down(1268)]: {
      maxWidth: '100%',
      flexBasis: '100%',
      '&::before': {display: 'none'},
    },
    [theme.breakpoints.down(Breakpoints.mobile)]: {
      paddingRight: 0,
      alignItems: 'flex-start',
    },
  },
  title2: {
    color: theme.palette.secondary.main,
    margin: '9px 12px 0 25px',
    fontSize: theme.typography.pxToRem(15.75),
  },
  gender: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 12,
    width: '100%',
    maxWidth: 237,
    '& .MuiFormGroup-row': {justifyContent: 'flex-end'},
    '& .MuiFormHelperText-root.Mui-error': {position: 'relative',
      right: 56},
  },
  hide: {
    display: 'none',
  },
  summary: {
    position: 'relative',
    '&::after': {
      content: '\' \'',
      display: 'block',
      width: '100%',
      height: 4,
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      top: 2,
      pointerEvents: 'none',
    },
    '& .Mui-error': {
      margin: '-8px 34px 0',
      [theme.breakpoints.down(1111)]: {marginTop: 0},
    },
    '& [class^=makeStyles-inlineLabel]': {
      height: 40,
      [theme.breakpoints.down(Breakpoints.mobile)]: {
        marginBottom: 20,
        maxWidth: 245,
      },
    },
  },
  submitBtn: {
    fontSize: theme.typography.fontSize,
    width: 132,
    marginTop: 8,
  },
}));

export default Tab2;
