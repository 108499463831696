import * as React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'formik';
import debounce from 'lodash.debounce';
import isEqual from 'react-fast-compare';

export const FORMIK_DEBOUNCE = 300;

class PersistImpl extends React.Component {
  static defaultProps = {
    debounce: FORMIK_DEBOUNCE,
  };

  saveForm = debounce((data) => {
    if (!this.props.preSaveHandler || this.props.preSaveHandler()) {
      window.sessionStorage.setItem(this.props.name, JSON.stringify(data));
    }
  }, this.props.debounce);

  componentDidUpdate(previousProps) {
    if (!isEqual(previousProps.formik, this.props.formik)) {
      this.saveForm(this.props.formik);
    }
  }

  componentDidMount() {
    const maybeState = window.sessionStorage.getItem(this.props.name);
    if (maybeState) {
      this.props.formik.setFormikState(JSON.parse(maybeState));
    }
  }

  render() {
    return null;
  }
}

PersistImpl.propTypes = {
  preSaveHandler: PropTypes.func,
  name: PropTypes.string,
  debounce: PropTypes.number,
  formik: PropTypes.object,
};

export const Persist = connect(PersistImpl);
