import React from 'react';
import PropTypes from 'prop-types';
import {Fab, Grow, makeStyles, FormControl} from '@material-ui/core';
import {ChipsSelect, AutocompleteMultiSelect, countries} from 'wobi-web-common';
import {Plus} from '../assets/js/icons';
import {Blue, Black} from 'wobi-web-common/dist/Colors';
import popularCountries from '../assets/js/popularCountries';

const useStyles = makeStyles((theme) => ({
  addBtn: {
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? Blue[550] : Blue[50],
    },
    backgroundColor: theme.palette.type === 'dark' ? Black[900] : Blue[10],
    border: theme.palette.type === 'dark' ? `1px solid ${Black[10]}` : `1px solid ${Blue[100]}`,
    borderRadius: '50%',
    boxShadow: 'none',
    color: theme.palette.secondary.main,
    height: 72,
    minWidth: 'auto',
    padding: 0,
    position: 'relative',
    width: 72,
    margin: '6px 15px 50px',
  },
  addBtnIcon: {
    height: 21.355,
    width: 21.355,
  },
  addBtnLabel: {
    fontFamily: 'Open-sans-hebrew',
    fontSize: theme.typography.pxToRem(10.5),
    fontWeight: 'bold',
    lineHeight: '17.8px',
    position: 'absolute',
  },
  autocompleteWrap: {
    margin: '8px 8px 0',
  },
  container: {
    maxWidth: 620,
  },
  containerLabel: {
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
  },
  formControl: {
    margin: '0 6px',
    maxWidth: 508,
    position: 'relative',
  },
  legend: {
    color: theme.palette.secondary.main,
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '14px',
    paddingLeft: '12px',
  },
}));

const getChips = values => {
  if (values) {
    return values.filter(value => popularCountries.includes(value));
  }
  return [];
};
const getAutocomplete = values => {
  if (values) {
    return values.filter(value => !popularCountries.includes(value));
  }
  return [];
};

const getSelectedContinents = countriesSelected => {
  const newContinents = new Set();
  countriesSelected.forEach(value_ => {
    const fullValue = countries.find(cou => cou.value === value_);
    newContinents.add(fullValue.continentInEnglish);
  });
  return Array.from(newContinents);
};

const popular = countries.filter(country => popularCountries.includes(country.value));
const nonPopular = countries.filter(country => !popularCountries.includes(country.value));

const CountriesSelect = ({value, onSelect}) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(Boolean(getAutocomplete(value).length));
  const [chipsSelected, setChipsSelected] = React.useState(getChips(value));
  const [autocompleteSelected, setAutocompleteSelected] = React.useState(getAutocomplete(value));
  const classes = useStyles();

  const handleClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleAutocompleteSelect = (values_) => {
    const clearValue = values_ ? values_.map(value_ => value_.value) : [];

    setAutocompleteSelected(clearValue);
    const newSelected = Array.from(new Set([...chipsSelected, ...clearValue]));
    const newContinents = getSelectedContinents(newSelected);
    onSelect(newSelected, newContinents);
  };

  const handleChipSelect = (values_) => {
    setChipsSelected(values_);
    const newSelected = Array.from(new Set([...autocompleteSelected, ...values_]));
    const newContinents = getSelectedContinents(newSelected);
    onSelect(newSelected, newContinents);
  };

  return (
    <FormControl className={classes.container}>
      <ChipsSelect
        isMulti
        items={popular}
        parentValue={chipsSelected}
        onSelect={handleChipSelect}
      >
        <Fab variant='extended' aria-label='עוד' onClick={handleClick} className={classes.addBtn}>
          <Plus />
          <span className={classes.addBtnLabel}>עוד</span>
        </Fab>
      </ChipsSelect>
      <Grow
        in={dropdownOpen}
        mountOnEnter
        unmountOnExit
        timeout={{
          appear: 300,
          enter: 200,
          exit: 300,
        }}
      >
        <div className={classes.autocompleteWrap}>
          <AutocompleteMultiSelect
            items={nonPopular}
            parentValue={autocompleteSelected}
            defaultMenuIsOpen={false}
            onSelect={handleAutocompleteSelect}
          />
        </div>
      </Grow>
    </FormControl>
  );
};

CountriesSelect.propTypes = {
  onSelect: PropTypes.func,
  value: PropTypes.array,
};

export default CountriesSelect;
