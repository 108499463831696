import React from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {Blue} from 'wobi-web-common/dist/config/colors.config';
import {getImageByAgent} from '../config/agent';
import {agentDeveloperUtils} from 'wobi-web-common';

const CarLinkImg = getImageByAgent('carLink.svg');
const PensionLinkImg = getImageByAgent('pensionLink.svg');
const ApartmentLinkImg = getImageByAgent('apartmentLink.svg');
const useStyles = makeStyles((theme) => ({
  crossSellBanner: {
    width: 'calc(100% + 100px)',
    position: 'relative',
    left: '-50px',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    '& h3': {
      fontSize: theme.typography.pxToRem(24.5),
      fontWeight: 300,
      margin: '12px 0 0',
      color: theme.palette.secondary.main,
    },
    '& ul': {
      display: 'flex',
      justifyContent: 'center',
      padding: 0,
      margin: 0,
      width: '100%',
      '&>li': {
        listStyle: 'none',
        width: '33%',
        height: 140,
        textAlign: 'center',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'row nowrap',
        '& a': {
          width: 122,
          color: theme.palette.secondary.main,
          fontSize: theme.typography.pxToRem(15.75),
          fontWeight: 700,
          textDecoration: 'none',
          display: 'block',
          '&:hover': {textDecoration: 'underline'},
        },
        '& img': {
          width: 112,
          height: 112,
        },
        '& .partition': {
          width: 1,
          height: 98,
          backgroundColor: Blue[100],
          position: 'absolute',
          right: 0,
          bottom: 10,
        },
      },
    },
  },
}));

const CrossSellBanner = () => {
  const classes = useStyles();

  const renderPartition = (service) => {
    switch (service) {
    case 'auto':
      if (agentDeveloperUtils.getSupportedServices.includes('home') ||
          agentDeveloperUtils.getSupportedServices.includes('mortgage')) {
        return (
          <div className='partition' />
        );
      }
      return null;
    case 'mortgage':
      if (agentDeveloperUtils.getSupportedServices.includes('home')) {
        return (
          <div className='partition' />
        );
      }
      return null;
    default:
      return null;
    }
  };

  return (
    <section className={classes.crossSellBanner}>
      <h3 style={{textAlign: 'center'}}><FormattedMessage id='cross_sell_banner.title'/></h3>
      <ul>
        { agentDeveloperUtils.getSupportedServices.includes('auto') ?
          <li>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={agentDeveloperUtils.getSerivceWebsiteUrl('auto')}
            >
              <img src={CarLinkImg} alt='' aria-hidden='true'/>
              <FormattedMessage id='cross_sell_banner.car_insurance'/>
            </a>
            {renderPartition('auto')}
          </li> : null}
        {agentDeveloperUtils.getSupportedServices.includes('mortgage') ?
          (<li>
            <a target='_blank' rel='noopener noreferrer' href={agentDeveloperUtils.getSerivceWebsiteUrl('mortgage')}>
              <img src={PensionLinkImg} alt='' aria-hidden='true'/>
              <FormattedMessage id='cross_sell_banner.pension'/>
            </a>
            {renderPartition('mortgage')}
          </li>) : null}
        {agentDeveloperUtils.getSupportedServices.includes('home') ? (<li>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={agentDeveloperUtils.getSerivceWebsiteUrl('home')}
          >
            <img src={ApartmentLinkImg} alt='' aria-hidden='true'/>
            <FormattedMessage id='cross_sell_banner.apartment_insurance'/>
          </a>
        </li>) : null}
      </ul>
    </section>
  );
};

export default CrossSellBanner;
