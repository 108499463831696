// continents icons
import africa from '../assets/images/continentsIcons/Africa.png';
import asia from '../assets/images/continentsIcons/Asia.png';
import australia from '../assets/images/continentsIcons/Australia.png';
import europe from '../assets/images/continentsIcons/Europe.png';
import latinAmerica from '../assets/images/continentsIcons/LatinAmerica.png';
import usaCanada from '../assets/images/continentsIcons/USA-Canada.png';

export default [
  {value: 'ASIA',
    imgSrc: asia,
    label: 'אסיה'},
  {value: 'AUSTRALIA',
    imgSrc: australia,
    label: 'אוסטרליה / ניו-זילנד'},
  {value: 'AFRICA',
    imgSrc: africa,
    label: 'אפריקה'},
  {value: 'EUROPE',
    imgSrc: europe,
    label: 'אירופה'},
  {value: 'SOUTHAM',
    imgSrc: latinAmerica,
    label: 'אמריקה הלטינית'},
  {value: 'USA',
    imgSrc: usaCanada,
    label: 'ארה"ב / קנדה',
    disabled: true,
    disabledTooltip: 'באופן זמני לא ניתן',
    disabledTooltip_line_2: 'לרכישה'}
];