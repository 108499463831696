// export const twoWomen = [
//   {firstName: 'מישהי', lastName: 'בדיקה', ID: '999894223', dob: '1987-04-10T21:00:00.000Z', gender: '2', phone: '0501234567'},
//   {firstName: 'להלה', lastName: 'בדיקה', ID: '999805815', dob: '1987-04-16T21:00:00.000Z', gender: '2'},
// ];

//  export const manAndWoman = [
//    {firstName: 'מישהו', lastName: 'בדיקה', ID: '999805815', dob: '1987-04-16T21:00:00.000Z', gender: '1', phone: '0501234567'},
//    {firstName: 'מישהי', lastName: 'בדיקה', ID: '999894223', dob: '1987-04-10T21:00:00.000Z', gender: '2'},
// ];

// export const oneMan = [
//   {firstName: 'מישהו', lastName: 'בדיקה', ID: '999805815', dob: '1987-04-16T21:00:00.000Z', gender: '1', phone: '0501234567'}
// ];

// export const oneWoman = [
//   {firstName: 'מישהי', lastName: 'בדיקה', ID: '999894223', dob: '1987-04-10T21:00:00.000Z', gender: '2', phone: '0501234567'}
// ];


export const twoWomen = [{
  firstName:'להלה',
  lastName:'בדיקה',
  dob: '1987-04-10T21:00:00.000Z',
  ID: '999358369',
  gender: 2,
  age: 32,
  fullName: 'להלה בדיקה',
  pregnant: false,
  week: '',
  chronic: false,
  additionalChronic: false,
  minorDiseases: []
},{
  firstName:'לילי',
  lastName:'בדיקי',
  dob: '1987-04-16T21:00:00.000Z',
  ID: '999830243',
  gender: 2,
  age: 32,
  fullName: 'לילי בדיקי',
  pregnant: false,
  week: '',
  chronic: false,
  additionalChronic: false,
  minorDiseases: []
}];

export const manAndWoman = [{
  firstName:'לול',
  lastName:'בדיקה',
  dob: '1987-04-10T21:00:00.000Z',
  ID: '999358369',
  gender: 1,
  fullName: 'לול בדיקה',
  chronic: false,
  additionalChronic: false,
  minorDiseases: []
},{
  firstName:'לילי',
  lastName:'בדיקי',
  dob: '1987-04-16T21:00:00.000Z',
  ID: '999830243',
  gender: 2,
  age: 32,
  fullName: 'לילי בדיקי',
  pregnant: false,
  week: '',
  chronic: false,
  additionalChronic: false,
  minorDiseases: []
}];

export const manAndTwoWomen = [{
  firstName:'לול',
  lastName:'בדיקה',
  dob: '1987-04-10T21:00:00.000Z',
  ID: '999358369',
  gender: 1,
  fullName: 'לול בדיקה',
  chronic: false,
  additionalChronic: false,
  minorDiseases: []
},{
  firstName:'לילי',
  lastName:'בדיקי',
  dob: '1987-04-16T21:00:00.000Z',
  ID: '999830243',
  gender: 2,
  age: 32,
  fullName: 'לילי בדיקי',
  pregnant: false,
  week: '',
  chronic: false,
  additionalChronic: false,
  minorDiseases: []
},{
  firstName:'להלה',
  lastName:'בדיקה',
  dob: '1987-04-08T21:00:00.000Z',
  ID: '999519192',
  gender: 2,
  age: 32,
  fullName: 'להלה בדיקה',
  pregnant: false,
  week: '',
  chronic: false,
  additionalChronic: false,
  minorDiseases: []
}];

export const oneMan = [{
  firstName:'לול',
  lastName:'בדיקה',
  dob: '1987-04-10T21:00:00.000Z',
  ID: '999358369',
  gender: 1,
  fullName: 'לול בדיקה',
  chronic: false,
  additionalChronic: false,
  minorDiseases: []
}];

export const oneWoman = [{
  firstName:'לילי',
  lastName:'בדיקי',
  dob: '1987-04-10T21:00:00.000Z',
  ID: '999830243',
  gender: 2,
  age: 32,
  fullName: 'לילי בדיקי',
  pregnant: false,
  week: '',
  chronic: false,
  additionalChronic: false,
  minorDiseases: []
}];
