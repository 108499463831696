import React from 'react';
import PropTypes from 'prop-types';
import {Tab, Tabs, makeStyles} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {Breakpoints} from 'wobi-web-common';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  tabs: {
    width: 213,
    flex: 'none',
  },
  hide: {
    display: 'none',
  },
  contents: {
    width: '100%',
    padding: '0 50px',
    marginTop: -14,
    [theme.breakpoints.down(Breakpoints.mobile)]: {
      padding: '0 20px',
    },
  },
  textsInTabs: {
    // make the font fit the tab
    fontSize: '1rem',
    '&.MuiTab-wrapper': {
      paddingRight: '0px',
      fontSize: '1rem',
    },
    paddingRight: '0px',

    '&.Mui-selected': {
      paddingRight: '0px',
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    '&.complete': {
      paddingRight: '0px',

      fontSize: '1rem',
    },
  },
  contentsNoPadding: {
    width: '100%',
    padding: '0',
  },
}));

const a11yProps = (index, itemLabel, currentTabIndex) => ({
  'aria-controls': `vertical-tabpanel-${index}`,
  'aria-label': `${itemLabel} ${index < currentTabIndex ? 'ניתן לחזור ל-tab זה במקש enter' : ''}`,
  id: `vertical-tab-${index}`,
  key: `tab-${index}`,
  role: 'tab',
});

const TabsLayout = ({
  currentTabIndex,
  handleChange,
  tabs,
  isTabsContainer = true,
  children,
  formikSetField,
  formikHandleChange,
  formikErrors,
  formikValues,
  formikSetTouched,
  formikValidateForm,
  updateFormikValidateForm,
}) => {
  const classes = useStyles();
  const isDesktop = !useMediaQuery(`(max-width:${Breakpoints.mobile}px)`);

  if (Boolean(updateFormikValidateForm) && Boolean(formikValidateForm)) {
    updateFormikValidateForm(formikValidateForm, formikSetTouched);
  }

  const isComplete = (index) => {
    if (index < currentTabIndex) {
      return `complete ${classes.textsInTabs}`;
    } else {
      return classes.textsInTabs;
    }
  };

  const TabsContainer = (
    <Tabs
      className={classes.tabs}
      orientation='vertical'
      variant='scrollable'
      value={currentTabIndex}
      onChange={handleChange}
      aria-label='wobi-tabs'
    >
      {tabs.map((item, index) => (
        <Tab
          key={index}
          disabled={index > currentTabIndex}
          className={isComplete(index)}
          label={item.label}
          {...a11yProps(index, item.label, currentTabIndex)}
        />
      ))}
    </Tabs>
  );

  return (
    <div className={classes.root}>
      {isDesktop && isTabsContainer ? TabsContainer : ''}
      <div
        className={
          isTabsContainer ? classes.contents : classes.contentsNoPadding
        }
      >
        {Array.isArray(children) ? children
          .map((item, index) => React.cloneElement(item, {
            key: `tab-content-${index}`,
            idForAccessibility: `vertical-tabpanel-${index}`,
            formikSetField,
            formikHandleChange,
            formikErrors,
            formikValidateForm,
            formikSetTouched,
            formikValues,
            className: index === currentTabIndex ? null : classes.hide,
          })) : [React.cloneElement(children, {key: 'tab-content-0'})]}
      </div>
    </div>
  );
};

TabsLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node]),
  currentTabIndex: PropTypes.number,
  formikErrors: PropTypes.object,
  formikHandleChange: PropTypes.func,
  formikSetField: PropTypes.func,
  formikValidateForm: PropTypes.func,
  formikSetTouched: PropTypes.func,
  formikValues: PropTypes.object,
  updateFormikValidateForm: PropTypes.func,
  handleChange: PropTypes.func,
  maxIndex: PropTypes.number,
  isTabsContainer: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number,
    key: PropTypes.string,
    label: PropTypes.string,
  })),
};

export default TabsLayout;
