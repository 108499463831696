const tabs = {
  details: [
    {index: 0,
      key: 'destination',
      label: 'תאריכים ויעד'},
    {index: 1,
      key: 'insured',
      label: 'מבוטחים'},
    {index: 2,
      key: 'medical',
      label: 'שאלון רפואי'},
    {
      index: 3,
      key: 'medical_extended',
      label: 'שאלון רפואי - מורחב',
    },
    {index: 4,
      key: 'coverage',
      label: 'כיסויים קיימים'},
  ],
  offers: [
    {index: 0,
      key: 'list',
      label: 'list'},
  ],
};

export default () => {
  const isShowMedicalExtended = JSON.parse(sessionStorage.getItem('travel.details-form.isShowMedicalExtended'))
  const clonedTabs = JSON.parse(JSON.stringify(tabs));

  if (!isShowMedicalExtended) {
    clonedTabs.details = clonedTabs.details.filter((tab) => tab.key !== 'medical_extended');
  }
  return clonedTabs;
};

