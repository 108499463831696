import React from 'react';
import {withRouter} from 'react-router-dom';
import TabsLayout from '../components/TabsLayout';
import ThankYou from './tabs-contents/ThankYou';
import stepsTabs from '../config/tabs';
import {getCurrentTabIndex, tabIndexToName} from '../utils/routingHelpers';

const tabs = stepsTabs().offers;

class ThankYouPage extends React.Component {
  constructor(props) {
    super(props);
    this.setTab = this.setTab.bind(this);
  }

  componentDidMount() {
    this.setTab(this.props.location);
    this.unlisten = this.props.history.listen((location) => {
      this.setTab(location);
    });
  }

  componentWillUnmount() {
    this.unlisten();
    sessionStorage.clear();
    this.props.history.push('/');
  }

  setTab(location) {
    const tabIndex = getCurrentTabIndex(location, 'offers');
    if (tabIndex === false) {
      return;
    }
    sessionStorage.setItem('travel.thank-you-tab', tabIndex);
  }

  render() {
    this.props.changeSideBar(false);
    const offer = JSON.parse(sessionStorage.getItem('travel.offerSelected'));
    const initialTabs = JSON.parse(sessionStorage.getItem('travel.details-form'));
    const payment = JSON.parse(sessionStorage.getItem('travel.payment'));
    return (
      <TabsLayout
        isTabsContainer={false}
        tabs={tabs}
        currentTabIndex={Number(sessionStorage.getItem('travel.thank-you-tab'))}
      >

        <ThankYou
          offer={offer}
          orderId={sessionStorage.getItem('orderId')}
          initialTabs={initialTabs?.values}
          ccNumber={payment?.ccNumber}
        />
      </TabsLayout>
    );
  }
}

export default withRouter(ThankYouPage);
