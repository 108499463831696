import {makeStyles} from '@material-ui/core';

export default makeStyles((theme) => ({
  secondStepWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  text: {
    color: theme.palette.text.secondary,
  },
  buttonClose: {
    minWidth: 130,
    marginTop: 20,
  },
}));
