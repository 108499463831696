import FenixLogo from '../assets/images/company-logos/88x47/FenixLogo.png';
import ClalLogo from '../assets/images/company-logos/88x47/ClalLogo.png';

export default [
  {
    provider: 'FNX',
    productLineId: '5d3eb7625b45482b00aec264',
    providerName: 'פניקס',
    productLineName: 'הפניקס רכב בעיר',
    productType: 'FULLCOVER',
    disclaimer: '* הפוליסה מוגבלת ל 10,000 ק״מ בשנה',
    alternativeTitle: 'הפניקס רכב בעיר',
    covers: {
      1: '2,020 ש"ח (לרכב בשווי עד 400000 ש"ח) במידה ובחרת לבטח נהג מתחת לגיל 24 תחול תוספת של 50% !',
      2: '995 ש"ח בתנאי שהנזק מעל 2020 ש"ח' +
				' (לרכב בשווי עד 400000 ש"ח)במידה ובחרת לבטח נהג מתחת לגיל 24 תחול תוספת של 50% ',
      3: '2,000,000 ש"ח ',
      4: 'כלול (כולל החלפת גלגל ואספקת דלק) באמצעות "דרכים" או "שגריר"',
      5: 'כלול רכב אוטומטי (מגיל 24 )',
      6: '1.5% (0.75% השתתפות עצמית במידה ובמוסך הסדר)',
      7: '30,000 ש"ח ',
      8: '500 ש"ח ',
      9: '1,500 ש"ח (במידה ובחרת לבטח נהג מתחת לגיל 24 תחול תוספת של 50%)',
      10: 'כלול',
      11: 'כלול',
      12: 'רכב עד שנה יד ראשונה מבעלות פרטית',
      13: ' מ 50% ע"פ החלטת שמאי חברה',
      14: 'כלול',
    },
    extraCovers: {
      1: '2,020 ש"ח (לרכב בשווי עד 400000 ש"ח) במידה ובחרת לבטח נהג מתחת לגיל 24 תחול תוספת של 50% !',
      2: '995 ש"ח בתנאי שהנזק מעל 2020 ש"ח' +
				' (לרכב בשווי עד 400000 ש"ח)במידה ובחרת לבטח נהג מתחת לגיל 24 תחול תוספת של 50% ',
    },
    protectionCode: '71',
    protectionTitle: 'רמת מיגון 71 אימובילייזר מקורי וקודן + עלוקה ממוגנת במתכת/מעטה קשיח',
    cascoPrice: 2521,
    mandatoryPrice: 1210,
    price: 3731,
    logo: FenixLogo,
    additionalPrices: [
      {
        title: 'גובה כיסוי',
        value: 750000,
      },
      {
        title: 'הריון',
        value: 250000,
      },
      {
        title: 'השתתפות עצמית',
        value: 'ללא ה.ע בפוליסת הבסיס',
      },
    ],
  },

  {
    provider: 'CLAL',
    productLineId: '5d3eb7625b45482b00aec264',
    providerName: 'פניקס',
    productLineName: 'הפניקס רכב בעיר',
    productType: 'FULLCOVER',
    disclaimer: '* הפוליסה מוגבלת ל 10,000 ק״מ בשנה',
    alternativeTitle: 'הפניקס רכב בעיר',
    covers: {
      1: '2,020 ש"ח (לרכב בשווי עד 400000 ש"ח) במידה ובחרת לבטח נהג מתחת לגיל 24 תחול תוספת של 50% !',
      2: '995 ש"ח בתנאי שהנזק מעל 2020 ש"ח' +
				' (לרכב בשווי עד 400000 ש"ח)במידה ובחרת לבטח נהג מתחת לגיל 24 תחול תוספת של 50% ',
      3: '2,000,000 ש"ח ',
      4: 'כלול (כולל החלפת גלגל ואספקת דלק) באמצעות "דרכים" או "שגריר"',
      5: 'כלול רכב אוטומטי (מגיל 24 )',
      6: '1.5% (0.75% השתתפות עצמית במידה ובמוסך הסדר)',
      7: '30,000 ש"ח ',
      8: '500 ש"ח ',
      9: '1,500 ש"ח (במידה ובחרת לבטח נהג מתחת לגיל 24 תחול תוספת של 50%)',
      10: 'כלול',
      11: 'כלול',
      12: 'רכב עד שנה יד ראשונה מבעלות פרטית',
      13: ' מ 50% ע"פ החלטת שמאי חברה',
      14: 'כלול',
    },
    extraCovers: {
      1: '2,020 ש"ח (לרכב בשווי עד 400000 ש"ח) במידה ובחרת לבטח נהג מתחת לגיל 24 תחול תוספת של 50% !',
      2: '995 ש"ח בתנאי שהנזק מעל 2020 ש"ח ' +
				'(לרכב בשווי עד 400000 ש"ח)במידה ובחרת לבטח נהג מתחת לגיל 24 תחול תוספת של 50% ',
    },
    protectionCode: '71',
    protectionTitle: 'רמת מיגון 71 אימובילייזר מקורי וקודן + עלוקה ממוגנת במתכת/מעטה קשיח',
    cascoPrice: 1520,
    mandatoryPrice: 1200,
    price: 2720,
    logo: ClalLogo,
    additionalPrices: [
      {
        title: 'גובה כיסוי',
        value: 750000,
      },
      {
        title: 'הריון',
        value: 250000,
      },
      {
        title: 'השתתפות עצמית',
        value: 'ללא ה.ע בפוליסת הבסיס',
      },
    ],
    coverExistingMedical: false,
  },
];