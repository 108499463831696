import {rollbarInitialize} from 'wobi-web-common';
import pjson from '../../package.json';

const accessToken = process.env.REACT_APP_ROLLBAR_TOKEN;
const codeVersion = pjson.version;
const environment = process.env.NODE_ENV;
const isIgnoreError = process.env.IS_IGNORE_ERROR;

export default rollbarInitialize({
  accessToken,
  codeVersion,
  environment,
  isIgnoreError
});
