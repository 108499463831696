import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {FormattedMessage, useIntl} from 'react-intl';
import OffersTeaser from 'wobi-web-common/dist/components/OffersTeaser';
import SingleOffer from 'wobi-web-common/dist/components/Offer';
import {makeStyles, Grid, Button, CircularProgress, Typography, Hidden} from '@material-ui/core';
import {Blue} from 'wobi-web-common/dist/config/colors.config';
import {fireGtmEvent} from '../../utils/marketing.utils';
import logos from '../../assets/images/company-logos';
import {destinationsToNames} from '../../utils/routingHelpers';
import {ridersTypes} from '../../config/ridersTypes';
import {agentDeveloperUtils} from 'wobi-web-common';

const useStyles = makeStyles(theme => ({
  subheaderContainer: {
    padding: '10px 19px 0 19px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  importantInfo: {
    fontSize: theme.typography.pxToRem(12.25),
    color: theme.palette.text.secondary,
    paddingLeft: '10px',
  },
  importantInfoTitle: {
    fontSize: theme.typography.pxToRem(15.25),
    color: agentDeveloperUtils.getPrimaryColor(440),
    fontWeight: 'bold',
  },
  importantInfoDot: {
    fontSize: theme.typography.pxToRem(30.25),
    paddingRight: theme.typography.pxToRem(3),
    color: agentDeveloperUtils.getPrimaryColor(440),
    flex: 0.2,
  },
  logo: {
    maxWidth: '100%',
  },
  importantInfoBulletContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  importantInfoContainer: {
    paddingLeft: theme.typography.pxToRem(30),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 0,
    },
  },
  importantInfoBulletsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    listStyle: 'none',
    paddingLeft: 0,
    margin: 0,
    fontWeight: 'bold',
    '& li::before': {
      content: '"\\2022"',
      color: agentDeveloperUtils.getPrimaryColor(440),
      fontWeight: 'bold',
      display: 'inline-block',
      width: '1em',
      marginLeft: '-1em',
    },
  },
  subheaderItem: {
    padding: '0 11px',
    borderRight: `1px solid ${theme.palette.secondary.dark}`,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    color: theme.palette.secondary.dark,
  },
  riderSubheaderItem: {
    padding: '0 11px',
    borderRight: `1px solid ${agentDeveloperUtils.getPrimaryColor(440)}`,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    color: agentDeveloperUtils.getPrimaryColor(440),
    '&:last-child': {
      borderRight: 'none',
    },
  },
  logoGrid: {
    padding: '0 0 0 20px',
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.pxToRem(12.25),
    letterSpacing: 0,
    fontWeight: 'bold',
    width: 250,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      textAlign: 'left',
      alignItems: 'none',
    },
  },
  parentForMiddled: {
    position: 'relative',
    height: 100,
  },
  cardHeader: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  prices: {
    margin: 'auto 0',
    paddingRight: '20px',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'right',
    },
  },
  forButton: {
    maxWidth: 'fit-content',
  },
  middled: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0%, -50%)',
    fontSize: theme.typography.pxToRem(12.25),
  },
  shekelSymbol: {
    margin: '0 0 0 8px',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15.75),
    letterSpacing: 0,
    fontWeight: 'bold',
  },
  shekelSymbolMobile: {
    margin: '0 0 0 8px',
    color: theme.palette.text.secondary,
    letterSpacing: 0,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  fullPrice: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(38.5),
    lineHeight: 1,
    letterSpacing: 0,
    // on landscape mode the price should be smaller
    '@media (orientation: landscape)': {
      fontSize: '2rem',
    },
    fontWeight: 450,
  },
  button: {
    marginLeft: 20,
    marginRight: 20,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  mandatory: {
    padding: '0 0 0 5px',
    fontSize: theme.typography.pxToRem(12.25),
    borderLeft: '1px solid ' + Blue[100],
  },
  casco: {
    padding: '0 5px 0 0',
    fontSize: theme.typography.pxToRem(12.25),
  },
  selectedOfferLabel: {
    alignItems: 'center',
    display: 'flex',
    width: 'inherit',
    '& > span': {
      color: theme.palette.secondary.main,
      marginLeft: 10,
    },
  },
  submitButton: {
    fontSize: '1rem',
    marginBottom: 5,
    padding: '7px 10px',
    width: '100%',
  },
  coversButtonGrid: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  logoPriceGrid: {
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        height: 1,
        background: theme.palette.grey.A100,
        opacity: 0.8,
        top: '100%',
        width: '90%',
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
  },
  offersTeaser: {
    fontSize: theme.typography.pxToRem(16),
    padding: '19px 19px 0px',
  },
  productLineName: {
    // if screen is small, this element will be hidden
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const OffersContainer = ({
  offers,
  submitAct,
  isSubmitting,
}) => {
  const intl = useIntl();

  const classes = useStyles();
  const isPregnancy = JSON.parse(sessionStorage.getItem('travel.details-form')).values.isPregnancy;
  const result = [];
  const [chosenOffersIndex, setChosenOferIndex] = useState(null);
  const [ridersList, setRidersList] = useState([]);
  useEffect(() => {
    const riders = [];
    const formikData = JSON.parse(sessionStorage.getItem('travel.details-form')).values;
    for (const {name, formikKey} of ridersTypes) {
      if (formikData[formikKey]) {
        riders.push(name);
      }
    }
    setRidersList(riders);
  }, []);

  Object.keys(offers)
    .map(i => {
      // props.offers[x]
      // filtering the additionalPrices array for not to show the columns if no such conditions
      if (!isPregnancy || isPregnancy === 'no') {
        offers[i].additionalPrices = offers[i].additionalPrices
          .filter(element => element.title !== 'הריון');
      }
      if (!offers[i].coverExistingMedical) {
        offers[i].additionalPrices = offers[i].additionalPrices
          .filter(element => element.title !== 'מצב רפואי קיים');
      }

      let isPregnancyAndExtremeSportsStatus = null;
      const isEsports = JSON.parse(sessionStorage.getItem('travel.details-form')).values.isEsports;
      const isWsports = JSON.parse(sessionStorage.getItem('travel.details-form')).values.isWsports;
      if (isPregnancy === 'yes') {
        if (isEsports && isWsports) {
          isPregnancyAndExtremeSportsStatus = 'pregnancy_and_extreme_sports_both_covers.title';
        } else if (isEsports) {
          isPregnancyAndExtremeSportsStatus = 'pregnancy_and_extreme_sports_esports_cover.title';
        } else if (isWsports) {
          isPregnancyAndExtremeSportsStatus = 'pregnancy_and_extreme_sports_wsports_cover.title';
        }
      }

      if (offers[i].insuranceCovers) {
        offers[i].insuranceCovers.push({
          warning: true,
          extra: true,
          text: isPregnancyAndExtremeSportsStatus ? intl.formatMessage({id: isPregnancyAndExtremeSportsStatus}) : null,
          title: ' ',
        });
      }
      const renderImportantInfo = () => {
        if (offers[i].importantInfo) {
          return (
            <Grid item xs={12} sm={7} className={classes.importantInfoContainer}>
              <div>
                <span className={classes.importantInfoTitle}>
                  חשוב לדעת:
                </span>
              </div>
              <ul className={classes.importantInfoBulletsContainer}>
                {offers[i].importantInfo.map((item, index) => (
                  <li key={`importantInfo-${index}`} className={classes.importantInfo}>
                    {item.text}
                  </li>
                ))}
              </ul>
            </Grid>
          );
        }
        return null;
      };
      result.push(<Grid item xs={12} style={{padding: '19px 19px 0'}} key={`offerNo-${i}`} data-testid='offer-row'>
        <SingleOffer
          data={{
            ...offers[i],
            pdfLink: `/policies/${offers[i].productLineId}/policy.pdf`,
          }}
          currency='empty'
          isOpen
          showProtectionTitleAndKmLim={false}
          extraPrimaryCovers
          shouldFilterEmptyCovers
          expandCoversHandler={() => fireGtmEvent('showMore', {provider: offers[i].provider})}
        >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid container className={classes.cardHeader}>
                <Grid item container xs={12} sm={8} className={classes.logoPriceGrid}>
                  <Grid xs={4} className={classes.logoGrid}>
                    <div>
                      <img className={classes.logo} src={logos[offers[i].logo]} alt=''/>
                      <br/>
                      <span className={classes.productLineName} >{offers[i].productLineName}</span>
                    </div>
                  </Grid>
                  <Hidden xsDown>
                    {renderImportantInfo()}
                  </Hidden>
                  <Hidden smUp>
                    <Grid xs={6} className={classes.prices}>
                      <span className={classes.fullPrice}>{Math.ceil(offers[i].price)}</span>
                      <span className={classes.shekelSymbolMobile}>$</span>
                    </Grid>
                  </Hidden>
                </Grid>
                <Grid item container xs={12} sm={4} className={classes.coversButtonGrid}>
                  {/* render  renderImportantInfo() this only on sm + size screens  */}
                  <Hidden smUp>
                    {renderImportantInfo()}
                  </Hidden>
                  <Hidden xsDown>
                    <Grid xs={6} sm={5} className={classes.prices}>
                      <span className={classes.shekelSymbol}>$</span>
                      <span className={classes.fullPrice}>{Math.ceil(offers[i].price)}</span>
                    </Grid>
                  </Hidden>
                  <Grid item xs={6} sm={7} className={classes.forButton}>
                    <div className={classes.button}>
                      <Button
                        onClick={() => {
                          submitAct(i);
                          setChosenOferIndex(i);
                        }}
                        data-testid={`buy-${offers[i].productLineName}`}
                        className={classes.submitButton}
                        size='large'
                        aria-label='add'
                        disabled={isSubmitting}
                      >
                        {isSubmitting && chosenOffersIndex === i ? (
                          <div className={classes.selectedOfferLabel}>
                            <CircularProgress size={26}/>
                            <span><FormattedMessage id='offers.list.buy-selecting'/></span>
                          </div>
                        ) : intl.formatMessage({id: 'offers.list.buy'})}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid >
          </Grid>
        </SingleOffer>
      </Grid>);
    });

  const values = JSON.parse(sessionStorage.getItem('travel.details-form')).values;
  const days = moment(values.policyEndDate).diff(values.policyStartDate, 'days') + 1;

  return (
    <Grid container spacing={0} style={{flexDirection: 'column'}}>
      <div className={classes.subheaderContainer}>
        <span className={classes.subheaderItem}><FormattedMessage id='vacationInsurance'/></span>
        <span className={classes.subheaderItem}>
          <span>{destinationsToNames(values.targetCountries)}</span>
        </span>
        <span className={classes.subheaderItem}>
          <FormattedMessage id='offers.insuredNum' values={{num: values.policyholdNum}}/>
        </span>
        <span className={classes.subheaderItem}>
          <FormattedMessage id='offers.duration' values={{days}}/>
        </span>
        {ridersList.map((rider, index) => (
          <span key={index} className={classes.riderSubheaderItem}>
            {index === 0 ? 'כולל: ' : ''}
            {index === 0 ? <span style={{whiteSpace:'pre-wrap'}}>{' '}</span> : ''}
            {rider}
          </span>
        ))}
      </div>
      <div className={classes.offersTeaser}>
        <OffersTeaser/>
      </div>
      {result}
    </Grid>
  );
};

OffersContainer.propTypes = {
  isSubmitting: PropTypes.bool,
  offers: PropTypes.object,
  submitAct: PropTypes.func,
  coversTitles: PropTypes.object,
};

export default OffersContainer;
