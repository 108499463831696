export const getDataFromSession = () => Object.keys(window.sessionStorage).reduce((obj, key) => {
  let val = window.sessionStorage.getItem(key);
  if (val) {
    try {
      val = JSON.parse(val);
    } catch (e) {

    }
    obj[key] = val;
  }

  return obj;
}, {});

export const setDataInSession = (key, data) => {
  if (data && typeof value === 'object') {
    window.sessionStorage.setItem(key, JSON.stringify(data));
  } else {
    window.sessionStorage.setItem(key, data);
  }
};

export const postSession = (url) => {
  function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }

  function parseJSON(response) {
    return response.json();
  }

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(getDataFromSession())
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch(error => console.log(error));
};
