/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import {agentDeveloperUtils} from 'wobi-web-common';

class Agent {
  /**
     * Agent constructor
     *
     * @class
     * @param {string} name
     * @param {string} developerName
     * @param {string} displayName
     * @param {string} isFollowUpEnabled
     */
  constructor(name, developerName, displayName, isFollowUpEnabled) {
    this.name = name;
    this.developerName = developerName;
    this.displayName = displayName;
    this.isFollowUpEnabled = isFollowUpEnabled;
  }

  get getName() {
    return this.name;
  }

  get getDisplayName() {
    return this.displayName;
  }

  get getDeveloperName() {
    return this.developerName;
  }

  get getLogoName() {
    return 'companyLogo';
  }

  get getFormsEmailAddress() {
    return this.formsEmailAddress;
  }

  get getAssetsPath() {
    return `assets/images/agents/${this.developerName}`;
  }

  get getIsFollowUpEnabled() {
    return this.isFollowUpEnabled;
  }

  get getAgentLogoPath() {
    if (this.developerName === 'Wobi') {
      return `${this.getAssetsPath}/${this.getLogoName}.svg`;
    } else {
      return `${this.getAssetsPath}/${this.getLogoName}.png`;
    }
  }

  getAgentImagePath(imageName) {
    return `${this.getAssetsPath}/${imageName}`;
  }
}

export const getImageByAgent = (imageName, config) => {
  const agent = new Agent(agentDeveloperUtils.agentData.Agent_Developer_Name_c,
    agentDeveloperUtils.agentData.Agent_Developer_Name_c,
    agentDeveloperUtils.getAgentDisplayName);
  // eslint-disable-next-line global-require, import/no-dynamic-require
  let image;
  if (config && config[agent.getDeveloperName]) {
    image = require('../' + agent.getAgentImagePath(config[agent.getDeveloperName]));
  } else {
    image = require('../' + agent.getAgentImagePath(imageName));
  }

  return image;
};

export default Agent;
