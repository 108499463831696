/* eslint-disable complexity */
/* eslint-disable no-confusing-arrow */
/* eslint-disable id-length */
/* eslint-disable max-len */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {ErrorMessage} from 'formik';
import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  FormGroup,
  FormHelperText,
} from '@material-ui/core';
import {injectIntl, FormattedHTMLMessage} from 'react-intl';
import {utils} from './helpers/Tab3_helpers/utils';
import ValidationSchema from './helpers/Tab3_Extended_helpers/validationSchema';
import useTab3Styles from './helpers/Tab3_helpers/tab3Styles';
import BlockedBanner from '../../../components/BlockedBanner';
import CrossSellBanner from '../../../components/CrossSellBanner';
import Tab3YesNo from '../../../components/Tab3YesNo';
import UnsupportedMedicalCases from '../../../components/modals/UnsupportedMedicalCases';
import {fireGtmEvent} from '../../../utils/marketing.utils';
import {render} from 'react-dom/cjs/react-dom.production.min';
import { agentDeveloperUtils } from 'wobi-web-common';

const Tab3 = React.memo(
  ({
    className: hideTabClassName,
    intl,
    idForAccessibility,
    submitCallback,
    formikIsSubmitting,
    validationCallback,
    formikSetField,
    formikErrors,
    formikValues,
    formikSetTouched,
    formikValidateForm,
    actionButton,
  }) => {
    const values = formikValues;
    const [isModalShow, setIsModalShow] = useState(false);
    const classes = useTab3Styles();
    const messages = (id) => intl.formatMessage({id});
    const validationObject = ValidationSchema;
    const isMedicalEscalation = values.isMedicalEscalation === 'yes';
    const isAdditionalChronic = values.isAdditionalChronic === 'yes';
    const isSeverDiseaseToSomeone = values.isSeverDisease1 === 'yes' || values.isSeverDisease2 === 'yes' || values.isSeverDisease3 === 'yes' || values.isSeverDisease4 === 'yes';
    const travelersForAdditionalChronic = values.travelers.filter((traveler) => traveler.additionalChronic && !traveler.surgeryMoreThan3Months);
    const travelersForSurgeryMoreThan3Months = values.travelers.filter((traveler) => traveler.surgeryMoreThan3Months);
    setTimeout(() => {
      validationCallback(validationObject);
    }, 1);

    useEffect(() => {
      if (formikValues.travelers.length >= 1) {
        if (!isMedicalEscalation) {
          formikSetField('isSurgeryMoreThan3Months', '');
          formikSetField('surgeryMoreThan3MonthsIdentifica', []);
        }
        if (!isAdditionalChronic || !isSeverDiseaseToSomeone) {
          formikSetField('isRecommendationForMedicines', '');
          formikSetField('isAdditionalExistingMedicalCondition', '');
          formikSetField('recommendationForMedicinesIdentifica', []);
          formikSetField('additionalExistingMedicalConditionIdentifica', []);
        }
        values.travelers.forEach((traveler) => {
          if (!isMedicalEscalation) {
            traveler.surgeryMoreThan3Months = false;
          }
          if (!isAdditionalChronic || !isSeverDiseaseToSomeone) {
            traveler.recommendationForMedicines = false;
            traveler.additionalExistingMedicalCondition = false;
          }
        });
      }
    }, [formikValues.isMedicalEscalation, formikValues.isAdditionalChronic, isSeverDiseaseToSomeone]);

    useEffect(() => {
      setIsModalShow(getBlockCondition() && agentDeveloperUtils.getAgentDeveloperName === 'Wobi');
    }, [values]);

    const manyCanBeAdditionalChronic = values.travelers.length > 1;
    const manyCanBeMedicalEscalation = values.travelers.length > 1;
    const lengthOfTravelerAdditionalChronic = values.travelers.filter((traveler) => traveler.additionalChronic);
    const lengthOfTravelerMedicalEscalation = values.travelers.filter((traveler) => traveler.medicalEscalation);

    const handleSubmitStep = async () => {
      const errors = await formikValidateForm();
      const hasErrors = Object.keys(errors).some((field) => Object.keys(validationObject).includes(field));
      if (!hasErrors) {
        // fireGtmEvent('confirmMedical', {
        //   medicalTravel: formikValues.isTreatmentAbroad,
        //   sixMonthsMedical: formikValues.isMedicalEscalation,
        //   medicalTests: formikValues.isMedicalTreatment,
        //   chronicPatient: formikValues.isChronic,
        //   additionalDisease: formikValues.isAdditionalChronic,
        //   isPregnant: formikValues.isPregnancy,
        //   pregnantTravelers: formikValues.travelers
        //     .filter((tr) => tr.pregnant)
        //     .map((tr) => ({
        //       week: tr.week,
        //       ID: tr.ID,
        //     })),
        // });
        submitCallback(4);
      } else {
        Object.keys(validationObject).forEach((field) => formikSetTouched(field, true, false));
      }
    };

    const someoneChronicAndPregnant = () => values.travelers.some(traveler => traveler.additionalChronic && traveler.pregnant);

    const getBlockCondition = () => false;

    const blockedBannerCondition = getBlockCondition();

    const submitButtonAriaLabel = () => {
      if (blockedBannerCondition) {
        return `${messages('continue')}, ${messages('blocked_banner.title')}`;
      } else if (formikErrors && Object.keys(formikErrors).length) {
        return `${messages('continue')}, ${messages('validation.needToFillAllFields')}`;
      } else {
        return null;
      }
    };

    const handleIsSurgeryMoreThan3Months = (value) => {
      fireGtmEvent('ThreeMonthHospital', {value});
      formikSetField('isSurgeryMoreThan3Months', value);
      for (let i = 0; i < values.travelers.length; i++) {
        if (values.travelers[i].surgeryMoreThan3Months) {
          setSurgeryMoreThan3MonthsIdentification(values.travelers[i], false);
        }
      }
      if (lengthOfTravelerMedicalEscalation.length === 1) {
        const traveler = lengthOfTravelerMedicalEscalation[0];
        if (value === 'yes') {
          setAdditionalExistingMedicalConditionIdentification(traveler, false);
          setSurgeryMoreThan3MonthsIdentification(traveler, true);
        } else if (value === 'no') {
          formikSetField('isAdditionalExistingMedicalCondition', '');
          setSurgeryMoreThan3MonthsIdentification(traveler, false);
        }
      } else {
        formikSetField('isAdditionalExistingMedicalCondition', '');
        for (let i = 0; i < values.travelers.length; i++) {
          if (values.travelers[i].additionalExistingMedicalCondition) {
            setAdditionalExistingMedicalConditionIdentification(values.travelers[i], false);
          }
        }
      }
    };

    const setSurgeryMoreThan3MonthsIdentification = (traveler, value) => {
      traveler.surgeryMoreThan3Months = value;
      traveler.additionalExistingMedicalCondition = false;
      formikSetField('travelers', values.travelers);
      if (value) {
        values.surgeryMoreThan3MonthsIdentifica.push(traveler.ID);
      } else {
        const i = utils.getIndexFromIDArrayByID(values.surgeryMoreThan3MonthsIdentifica, traveler.ID);
        values.surgeryMoreThan3MonthsIdentifica.splice(i, 1);
      }
      formikSetField('surgeryMoreThan3MonthsIdentifica', values.surgeryMoreThan3MonthsIdentifica);
    };

    const handleIsRecommendationForMedicines = (value) => {
      fireGtmEvent('UnderTreatment', {value});
      formikSetField('isRecommendationForMedicines', value);
      for (let i = 0; i < values.travelers.length; i++) {
        if (values.travelers[i].recommendationForMedicines) {
          setRecommendationForMedicinesIdentification(values.travelers[i], false);
        }
      }

      if (lengthOfTravelerAdditionalChronic.length === 1) {
        const traveler = lengthOfTravelerAdditionalChronic[0];
        if (value === 'yes') {
          setRecommendationForMedicinesIdentification(traveler, true);
        } else if (value === 'no') {
          setRecommendationForMedicinesIdentification(traveler, false);
        }
      }
    };

    const setRecommendationForMedicinesIdentification = (traveler, value) => {
      traveler.recommendationForMedicines = value;
      formikSetField('travelers', values.travelers);

      if (value) {
        values.recommendationForMedicinesIdentifica.push(traveler.ID);
      } else {
        const i = utils.getIndexFromIDArrayByID(values.recommendationForMedicinesIdentifica, traveler.ID);
        values.recommendationForMedicinesIdentifica.splice(i, 1);
      }
      formikSetField('recommendationForMedicinesIdentifica', values.recommendationForMedicinesIdentifica);
    };

    const handleIsAdditionalExistingMedicalCondition = (value) => {
      fireGtmEvent('ChronicHarel', {value});
      formikSetField('isAdditionalExistingMedicalCondition', value);

      for (let i = 0; i < values.travelers.length; i++) {
        if (values.travelers[i].additionalExistingMedicalCondition) {
          setAdditionalExistingMedicalConditionIdentification(values.travelers[i], false);
        }
      }
      if (lengthOfTravelerAdditionalChronic.length === 1) {
        const traveler = lengthOfTravelerAdditionalChronic[0];
        if (value === 'yes') {
          setAdditionalExistingMedicalConditionIdentification(traveler, true);
        } else if (value === 'no') {
          setAdditionalExistingMedicalConditionIdentification(traveler, false);
        }
      }
    };

    const setAdditionalExistingMedicalConditionIdentification = (traveler, value) => {
      traveler.additionalExistingMedicalCondition = value;
      formikSetField('travelers', values.travelers);

      if (value) {
        values.additionalExistingMedicalConditionIdentifica.push(traveler.ID);
      } else {
        const i = utils.getIndexFromIDArrayByID(values.additionalExistingMedicalConditionIdentifica, traveler.ID);
        values.additionalExistingMedicalConditionIdentifica.splice(i, 1);
      }
      formikSetField('additionalExistingMedicalConditionIdentifica', values.additionalExistingMedicalConditionIdentifica);
    };

    const renderAdditionalChronic = () => {
      if (
        (formikValues.isSurgeryMoreThan3Months === 'yes' || formikValues.isSurgeryMoreThan3Months === '')
         && travelersForSurgeryMoreThan3Months.length === 0) {
        return null;
      } else if (!isSeverDiseaseToSomeone) {
        return null;
      } else if (travelersForAdditionalChronic.length === 0) {
        return null;
      }

      if (travelersForAdditionalChronic.length === 1 &&
        formikValues.isAdditionalExistingMedicalCondition === 'yes' &&
        travelersForAdditionalChronic[0].additionalExistingMedicalCondition === false) {
        travelersForAdditionalChronic[0].additionalExistingMedicalCondition = true;
        formikSetField('travelers', values.travelers);
        values.additionalExistingMedicalConditionIdentifica.push(travelersForAdditionalChronic[0].ID);
        formikSetField('additionalExistingMedicalConditionIdentifica', values.additionalExistingMedicalConditionIdentifica);
      }

      return (
        <section>
          <Tab3YesNo
            name='isAdditionalExistingMedicalCondition'
            dataTestId='is-additional-existing-medical-condition'
            value={values.isAdditionalExistingMedicalCondition}
            values={values}
            onChange={(event) => {
              handleIsAdditionalExistingMedicalCondition(event.target.value);
            }}
            label={
              <div>
                <FormattedHTMLMessage id='step1.tab3_extended.isAdditionalExistingMedicalCondition' />
                {travelersForAdditionalChronic.length === 1 && manyCanBeAdditionalChronic ? (
                  <p component='legend' className='checkboxGroup-label'>
                    {messages('step1.tab3.select_traveler')}
                    <span className={classes.travelersName}>{`${travelersForAdditionalChronic[0].firstName} ${travelersForAdditionalChronic[0].lastName}`}</span>
                  </p>
                ) : null}
              </div>
            }
          />
          {manyCanBeAdditionalChronic && values.isAdditionalExistingMedicalCondition === 'yes' && travelersForAdditionalChronic.length > 1 ?
            (
              <article>
                <FormLabel component='legend' className='checkboxGroup-label'>
                  {messages('step1.tab3.select_travelers')}
                </FormLabel>
                <FormGroup>
                  {travelersForAdditionalChronic.map((traveler, i) => (
                    <FormControlLabel
                      key={`traveler-${i}-additional-existing-medical-condition`}
                      label={traveler.fullName}
                      control={
                        <Checkbox
                          data-testId={`traveler-${i + 1}-additional-existing-medical-condition`}
                          checked={Boolean(traveler.additionalExistingMedicalCondition)}
                          onChange={(event, value) => {
                            setAdditionalExistingMedicalConditionIdentification(traveler, value);
                          }}
                        />
                      }
                    />
                  ))}
                </FormGroup>
                <FormHelperText error>
                  <ErrorMessage name='additionalExistingMedicalConditionIdentifica' />
                </FormHelperText>
              </article>
            ) : false}
        </section>
      );
    };

    return (
      <div className={`${hideTabClassName} travel-medical-conditions`} id={idForAccessibility} role='tabpanel'>
        <div className={`${classes.medicalConditions}`}>
          <div>
            {isAdditionalChronic ? intl.formatMessage({id: 'step1.tab3_extended.title_multi_questions'}) : intl.formatMessage({id: 'step1.tab3_extended.title_one_question'})}
            <div
              className={classes.completeIcon}
              style={{display: 'inline-block'}}
            />
          </div>

          {isMedicalEscalation ? (
            <section className>
              <Tab3YesNo
                name='isSurgeryMoreThan3Months'
                dataTestId='is-surgery-more-than-3-months'
                value={values.isSurgeryMoreThan3Months}
                values={values}
                onChange={(event) => {
                  handleIsSurgeryMoreThan3Months(event.target.value);
                }}
                label={
                  <div>
                    <FormattedHTMLMessage id='step1.tab3_extended.isSurgeryMoreThan3Months' />
                    {lengthOfTravelerMedicalEscalation.length === 1 && manyCanBeAdditionalChronic ? (
                      <p component='legend' className='checkboxGroup-label'>
                        {messages('step1.tab3.select_traveler')}
                        <span className={classes.travelersName}>{`${lengthOfTravelerMedicalEscalation[0].firstName} ${lengthOfTravelerMedicalEscalation[0].lastName}`}</span>
                      </p>
                    ) : null}
                  </div>
                }
              />
              {manyCanBeMedicalEscalation && values.isSurgeryMoreThan3Months === 'yes' && lengthOfTravelerMedicalEscalation.length > 1 ?
                (
                  <article>
                    <FormLabel component='legend' className='checkboxGroup-label'>
                      {messages('step1.tab3.select_travelers')}
                    </FormLabel>
                    <FormGroup>
                      {values.travelers.map((traveler, i) => !traveler.medicalEscalation ?
                        false :
                        (
                          <FormControlLabel
                            key={`traveler-${i}-surgery-more-than-3-months`}
                            label={traveler.fullName}
                            control={
                              <Checkbox
                                disabled={lengthOfTravelerMedicalEscalation.length === 1}
                                data-testId={`traveler-${i + 1}-surgery-more-than-3-months`}
                                checked={Boolean(traveler.surgeryMoreThan3Months)}
                                onChange={(event, value) => {
                                  setSurgeryMoreThan3MonthsIdentification(traveler, value);
                                }}
                                c
                                classes={{checked: classes.checked}}
                              />
                            }
                          />
                        ))}
                    </FormGroup>
                    <FormHelperText error>
                      <ErrorMessage name='surgeryMoreThan3MonthsIdentifica' />
                    </FormHelperText>
                  </article>
                ) : false}
            </section>) : false}

          {renderAdditionalChronic()}
          {isAdditionalChronic && isSeverDiseaseToSomeone ? (
            <section >
              <Tab3YesNo
                name='isRecommendationForMedicines'
                dataTestId='is-recommendation-for-medicines'
                value={values.isRecommendationForMedicines}
                values={values}
                onChange={(event) => {
                  handleIsRecommendationForMedicines(event.target.value);
                }}
                label={
                  <div>
                    <FormattedHTMLMessage id='step1.tab3_extended.isRecommendationForMedicines' />
                    {lengthOfTravelerAdditionalChronic.length === 1 && manyCanBeAdditionalChronic ? (
                      <p component='legend' className='checkboxGroup-label'>
                        {messages('step1.tab3.select_traveler')}
                        <span className={classes.travelersName}>{`${lengthOfTravelerAdditionalChronic[0].firstName} ${lengthOfTravelerAdditionalChronic[0].lastName}`}</span>
                      </p>
                    ) : null}
                  </div>
                }
              />
              {manyCanBeAdditionalChronic && values.isRecommendationForMedicines === 'yes' && lengthOfTravelerAdditionalChronic.length > 1 ?
                (
                  <article>
                    <FormLabel component='legend' className='checkboxGroup-label'>
                      {messages('step1.tab3.select_travelers')}
                    </FormLabel>
                    <FormGroup>
                      {values.travelers.map((traveler, i) => !traveler.additionalChronic ?
                        false :
                        (
                          <FormControlLabel
                            key={`traveler-${i}-recommendation-for-medicines`}
                            label={traveler.fullName}
                            control={
                              <Checkbox
                                disableFocusRipple={lengthOfTravelerAdditionalChronic.length === 1}
                                data-testId={`traveler-${i + 1}-recommendation-for-medicines`}
                                checked={Boolean(traveler.recommendationForMedicines)}
                                onChange={(event, value) => {
                                  setRecommendationForMedicinesIdentification(traveler, value);
                                }}
                              />
                            }
                          />
                        ))}
                    </FormGroup>
                    <FormHelperText error>
                      <ErrorMessage name='recommendationForMedicinesIdentifica' />
                    </FormHelperText>
                  </article>
                ) : false}
            </section>) : false}
          {blockedBannerCondition && <BlockedBanner />}
          {blockedBannerCondition && <CrossSellBanner />}
          <UnsupportedMedicalCases
            values={values}
            isModalShow={!hideTabClassName && isModalShow}
            hideContactPassportCard={someoneChronicAndPregnant()}
            close={() => {
              setIsModalShow(false);
            }}
          />
          <div className={classes.buttons}>
            {actionButton &&
              actionButton({
                children: messages('continue'),
                className: classes.submitBtn,
                size: 'large',
                onClick: handleSubmitStep,
                variant: 'contained',
                'aria-label': messages('continue'),
                'data-testid': 'continue-button-3',
                color: 'primary',
                disabled: formikIsSubmitting || blockedBannerCondition,
                'aria-label': submitButtonAriaLabel(),
              })}
          </div>
        </div>
      </div>
    );
  },
);

Tab3.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object,
  submitCallback: PropTypes.func,
  formikIsSubmitting: PropTypes.bool,
  validationCallback: PropTypes.func,
  formikSetField: PropTypes.func,
  formikErrors: PropTypes.object,
  formikValues: PropTypes.object,
  formikSetTouched: PropTypes.func,
  formikValidateForm: PropTypes.func,
  actionButton: PropTypes.object,
  idForAccessibility: PropTypes.string,
};

export default injectIntl(Tab3);
