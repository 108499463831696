import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

import {Button} from '@material-ui/core';
import useStyles from './styles';
import {getImageByAgent} from '../../../../config/agent';

const coffeeImage = getImageByAgent('coffee.svg');
function SecondStep({increaseStep}) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.secondStepWrapper}>
      <img src={coffeeImage} alt='Coffee' />
      <span className={classes.text}>
        { intl.formatMessage({id: 'unsupported_medical_cases_modal.second_step.text'}) }
      </span>
      <Button onClick={increaseStep} className={classes.buttonClose}>
        { intl.formatMessage({id: 'unsupported_medical_cases_modal.second_step.button'}) }
      </Button>
    </div>
  );
}

SecondStep.propTypes = {
  increaseStep: PropTypes.func.isRequired,
};

export default SecondStep;
