import React from 'react';
import {makeStyles, Tooltip} from '@material-ui/core';
import {FormattedHTMLMessage} from 'react-intl';
import {getImageByAgent} from '../config/agent';

const LightbulbImg = getImageByAgent('lightbulb.gif');
const LightbulbTooltip = ({
  name,
  placement,
}) => {
  const classes = useStyles();
  return (
    <Tooltip
      className={classes.tooltip}
      placement={placement ? placement : 'left'}
      title={<FormattedHTMLMessage id={`step1.tab3.${name}`}/>}
    >
      <span>
        <img src={LightbulbImg} alt='' aria-hidden='true' />
      </span>
    </Tooltip>
  );
};
const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.secondary.main,
    display: 'inline-block',
    transition: '0.3s all',
    borderBottom: '2px solid transparent',
    padding: '5px 0 0',
    marginLeft: 10,
    '&:hover': {
      borderColor: theme.palette.secondary.main,
    },
    '& img': {
      height: '35px',
    },
  },
}));

export default LightbulbTooltip;