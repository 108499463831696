import {makeStyles} from '@material-ui/core';
import breakpoints from 'wobi-web-common/dist/config/breakpoints';
import { Blue } from 'wobi-web-common/dist/config/colors.config';

const questionsSection = {
  display: 'flex',
  justifyContent: 'flex-start',
  padding: 0,
  alignItems: 'center',
};
questionsSection[`@media screen and (max-width: ${breakpoints.mobile}px)`] = {
  padding: '10px 0 0',
  '& fieldset > div': {
    flexDirection: 'column',
    alignItems: 'unset',
    '& legend': {
      marginBottom: 10,
    },
  },
  '& > span': {
    display: 'none',
  },
};
const mobileColumnWidth = '100%';
const useTab3Styles = makeStyles((theme) => ({
  travelersName: {
    fontWeight: 500,
    color: theme.palette.secondary.main,
  },
  medicalConditions: {
    [theme.breakpoints.up(breakpoints.mobile)]: {maxWidth: 'calc(28.5vw + 180px)'},
    [theme.breakpoints.down(breakpoints.mobile)]: {padding: '0 10px'},
    '& .MuiFormLabel-root': {
      padding: '5px 0',
      width: '28.5vw',
      marginRight: 57,
      color: theme.palette.text.secondary,
      fontSize: theme.typography.fontSize,
      fontWeight: 500,
      lineHeight: 1.2,
      [theme.breakpoints.down(breakpoints.mobile)]: {
        marginRight: 'initial',
        width: mobileColumnWidth,
      },
      '& .notation': {
        fontSize: theme.typography.pxToRem(12.25),
        fontWeight: 400,
        display: 'block',
        color: theme.palette.text.secondary,
        marginTop: 3,
      },
      '& a': {
        color: theme.palette.secondary.light,
        fontSize: theme.typography.pxToRem(12.25),
        fontWeight: 400,
        textDecoration: 'none',
        display: 'block',
        marginTop: 3,
        '&:hover': {textDecoration: 'underline'},
      },
    },
    '& .questions': {
      textAlign: 'justify',
      textJustify: 'interWord',
      '& .Mui-error': {
        display: 'block',
        [theme.breakpoints.down(breakpoints.mobile)]: {
          width: mobileColumnWidth,
          margin: '0 auto 8px'
        }
      },
      '& > article, & > section > article': {
        borderBottom: `2px dotted ${Blue[100]}`,
        padding: '4px 0'
      },
      '& .util-unbordered > article': {borderBottom: 0},
      '& > article > div': questionsSection,
      '& > section > article > div': {
        [theme.breakpoints.down(breakpoints.mobile)]: {
          width: mobileColumnWidth,
          margin: '0 auto 8px',
        }
      },
      '& button': {
        width: '100%',
        maxWidth: 268,
        marginTop: '20px',
        '& .MuiIcon-root': {
          marginLeft: 10,
        },
      },
      '& .textFieldsGroup-error': {
        display: 'inline-block',
        marginBottom: 0,
        position: 'relative',
        top: 1,
      },
    },
    '& .checkbox-label-row': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .checkbox-item-row': {
      display: 'flex',
      alignItems: 'center',
      '& button.MuiToggleButton-sizeSmall': {
        margin: '2px 0',
        lineHeight: '14px',
        height: 31,
      },
      '& .MuiFormControlLabel-label': {minWidth: 173},
    },
    '& .textFieldsGroup-label': {marginBottom: 6},
    '& .checkboxGroup-label': {marginRight: 45},
    '& .MuiFormControlLabel-label': {
      color: theme.palette.secondary.main,
      fontWeight: 400,
      fontSize: theme.typography.fontSize,
    },
    '& .pregnancyWeek-row': {
      marginBottom: 5,
      '&:last-child': {marginBottom: 16},
      '& div.Mui-error': {marginBottom: 0},
    },
    '& .pregnancyWeek-input': {
      width: 40,
      marginRight: 8,
      '& input': {
        width: 40,
        padding: 0,
        textAlign: 'center',
        fontSize: theme.typography.fontSize,
        lineHeight: '16px',
        height: 30,
      },
    },
    '& .severDiseases_title': {
      width: 'calc(20vw + 155px)',
      fontWeight: 500,
      color: theme.palette.text.secondary,
      lineHeight: 1.2,
      margin: '8px 0 12px',
      [theme.breakpoints.down(breakpoints.mobile)]: {
        width: mobileColumnWidth,
        margin: '8px auto 0'
      }
    },
    '& .bordered-section': {
      border: `2px solid ${Blue[170]}`,
      borderWidth: '2px 0',
      paddingTop: 18,
      marginBottom: 19,
      '& > article:last-child': {borderBottom: 0},
    }
  },
  buttons: {
    marginTop: 20,
    [theme.breakpoints.down(breakpoints.mobile)]: {
      margin: '20px auto 0',
      width: mobileColumnWidth
    }
  },
  submitBtn: {
    fontSize: theme.typography.fontSize,
    width: 132,
  }
}));

export default useTab3Styles;