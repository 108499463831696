// eslint-disable-next-line import/no-cycle
import {getOffersFields} from './persistOfferDetails';

// import {logGtmEvent} from './apiHandlers';

// static for a reason, changing the policyType in mid flow should not effect marketing segment
let originalPolicyType;

export const constractCampaignArgs = (querystringParameters) => {
  if (querystringParameters.utm_campaign) {
    return {
      gclid: querystringParameters.gclid,
      marketingId: querystringParameters.gclid,
      referrer: querystringParameters.referrer,
      source: querystringParameters.utm_source,
      utm_campaign: querystringParameters.utm_campaign,
      utm_content: querystringParameters.utm_content,
      utm_keywords: querystringParameters.utm_keywords || querystringParameters.utm_term,
      utm_medium: querystringParameters.utm_medium,
      utm_source: querystringParameters.utm_source
    };
  } else if (querystringParameters.gclid) {
    return {
      gclid: querystringParameters.gclid,
      marketingId: querystringParameters.gclid,
      referrer: querystringParameters.referrer,
      source: 'googleAds'
    };
  } else if (querystringParameters.fbclid) {
    return {
      fbclid: querystringParameters.fbclid,
      marketingId: querystringParameters.fbclid,
      referrer: querystringParameters.referrer,
      source: 'facebook'
    };
  } else if (querystringParameters.referrer && !querystringParameters.referrer.includes('wobi.co.il')) {
    return {
      referrer: querystringParameters.referrer,
      source: 'organic'
    };
  } else if (querystringParameters.referrer && querystringParameters.referrer.includes('lm.facebook.com')) {
    return {
      referrer: 'https://lm.facebook.com/',
      source: 'facebook'

    };
  } else if (querystringParameters.referrer && querystringParameters.referrer.includes('LP-')) {
    const segments = querystringParameters.referrer.split('/');
    const parameters = segments[segments.length - 1].split('-');
    if (parameters.length >= 5) {
      return {
        referrer: querystringParameters.referrer,
        utm_campaign: parameters[3],
        utm_content: parameters[4],
        utm_medium: parameters[2],
        utm_source: parameters[1]
      };
    }
  }
  return {
    source: 'direct'
  };
};

export const fireGtmEvent = (gtmEventName, metaData) => {
  const policyType = 'Travel';
  if (!originalPolicyType) {
    originalPolicyType = policyType;
  }
  const data = {
    event: gtmEventName,
    ...metaData,
    originalPolicyType,
    policyType
  };
  window.dataLayer.push(data);

//  logGtmEvent(data);
};

export const gtmEventWrapper = (invokedAction, gtmEventName, metaData) => ({
  invoke: (event) => {
    const value = event ? event.target ? event.target.value ? event.target.value : undefined : undefined : undefined;
    if (invokedAction) {
      invokedAction(event);
    }
    fireGtmEvent(gtmEventName, {...metaData,
      value});
  }
});

export const initializeGoogleOptimizeExperiment = async (experimentID) => {
  if (experimentID !== undefined) {
    fireGtmEvent('optimize.activate');
    const experimentVariant = await checkGoogleOptimizeExperiment(experimentID);
    return experimentVariant;
  }
};

const checkGoogleOptimizeExperiment = (experimentID) => new Promise((resolve) => {
  if (window.google_optimize !== undefined) {
    resolve(window.google_optimize.get(experimentID));
  } else {
    setTimeout(async () => await resolve(checkGoogleOptimizeExperiment(experimentID)), 100);
  }
});
