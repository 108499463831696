/* eslint-disable max-len */
import * as yup from 'yup';
import {testsConditions as conditions} from './testsConditions';

const ValidationSchema = {
  isSurgeryMoreThan3Months: yup.string().when('isMedicalEscalation', {
    is: 'yes',
    then: yup.string().required('שדה חובה'),
  }),

  isRecommendationForMedicines: yup.string().when(['isAdditionalChronic', 'isSeverDisease1', 'isSeverDisease2', 'isSeverDisease3', 'isSeverDisease4'], {
    is: (isAdditionalChronic, isSeverDisease1, isSeverDisease2, isSeverDisease3, isSeverDisease4) => {
      return isAdditionalChronic === 'yes' && (isSeverDisease1 === 'yes' || isSeverDisease2 === 'yes' || isSeverDisease3 === 'yes' || isSeverDisease4 === 'yes');
    },
    then: yup.string().required('שדה חובה'),
  }),

  isAdditionalExistingMedicalCondition: yup.string().when(['isSurgeryMoreThan3Months', 'isSeverDisease1', 'isSeverDisease2', 'isSeverDisease3', 'isSeverDisease4'], {
    is: (isSurgeryMoreThan3Months, isSeverDisease1, isSeverDisease2, isSeverDisease3, isSeverDisease4) => {
      return isSurgeryMoreThan3Months === 'no' && (isSeverDisease1 === 'yes' || isSeverDisease2 === 'yes' || isSeverDisease3 === 'yes' || isSeverDisease4 === 'yes');
    },
    then: yup.string().required('שדה חובה'),
  }),

  surgeryMoreThan3MonthsIdentifica: yup.array().when('isMedicalEscalation', {
    is: 'yes',
    then: yup.array().test('surgeryMoreThan3MonthsIdentifica', 'שדה חובה', function () {
      return conditions.surgeryMoreThan3MonthsIdentifica(this.parent.travelers, this.parent.isSurgeryMoreThan3Months);
    }),
  }),

  recommendationForMedicinesIdentifica: yup.array().when(['isAdditionalChronic', 'isSeverDisease1', 'isSeverDisease2', 'isSeverDisease3', 'isSeverDisease4'], {
    is: (isAdditionalChronic, isSeverDisease1, isSeverDisease2, isSeverDisease3, isSeverDisease4) => {
      return isAdditionalChronic === 'yes' && (isSeverDisease1 === 'yes' || isSeverDisease2 === 'yes' || isSeverDisease3 === 'yes' || isSeverDisease4 === 'yes');
    },
    then: yup.array().test('recommendationForMedicinesIdentifica', 'שדה חובה', function () {
      return conditions.recommendationForMedicinesIdentifica(this.parent.travelers, this.parent.isRecommendationForMedicines);
    }),
  }),

  additionalExistingMedicalConditionIdentifica: yup.array().when(['isSurgeryMoreThan3Months', 'isSeverDisease1', 'isSeverDisease2', 'isSeverDisease3', 'isSeverDisease4'], {
    is: (isSurgeryMoreThan3Months, isSeverDisease1, isSeverDisease2, isSeverDisease3, isSeverDisease4) => {
      return isSurgeryMoreThan3Months === 'no' && (isSeverDisease1 === 'yes' || isSeverDisease2 === 'yes' || isSeverDisease3 === 'yes' || isSeverDisease4 === 'yes');
    },
    then: yup.array().test('additionalExistingMedicalConditionIdentifica', 'שדה חובה', function () {
      return conditions.additionalExistingMedicalConditionIdentifica(this.parent.travelers, this.parent.isAdditionalExistingMedicalCondition);
    }),
  }),

};

export default ValidationSchema;
