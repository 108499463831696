/* eslint-disable max-len */
import * as yup from 'yup';
import Moment from 'moment';
const maxNumberCharactersForModel = 50;

const ValidationSchema = {
  travelers: yup.array().of(
    yup.object().shape({
      needPcCoverage: yup.boolean().nullable(),
      pc: yup.object().nullable().when('needPcCoverage', {
        is: true,
        then: () => yup.object().shape({
          manufacturer: yup
            .string()
            .max(maxNumberCharactersForModel, 'validation.fieldLengthExceedsLimit')
            .required('validation.required'),
          model: yup
            .string()
            .max(maxNumberCharactersForModel, 'validation.fieldLengthExceedsLimit')
            .required('validation.required'),
          year: yup
            .number()
            .typeError('validation.invalidYear')
            .positive('validation.invalidYear')
            .integer('validation.invalidYear')
            .min(1989, 'validation.invalidYear')
            .required('validation.required'),
        }),
        otherwise: () => yup.object().nullable(),
      }),
      needMobileCoverage: yup.boolean().nullable(),
      mobile: yup.object().nullable().when('needMobileCoverage', {
        is: true,
        then: () => yup.object().shape({
          manufacturer: yup
            .string()
            .max(maxNumberCharactersForModel, 'validation.fieldLengthExceedsLimit')
            .required('validation.required'),
          model: yup
            .string()
            .max(maxNumberCharactersForModel, 'validation.fieldLengthExceedsLimit')
            .required('validation.required'),
        }),
        otherwise: () => yup.object().nullable(),
      }),
    }),
  ),
  isTerms: yup.boolean().oneOf([true], 'יש לאשר את תנאי השימוש'),
  pcCoverError:  yup.array().test('pcCoverError', 'שדה חובה', function() {
    const needneedPcCoverageTravelers = this.parent.travelers.filter(t => t.needPcCoverage)
    return this.parent.travelers.length === 1 || !this.parent.isPccover  || (this.parent.travelers.length > 1 && this.parent.isPccover && 
        needneedPcCoverageTravelers.length >= 1)
  }),
  mobileCoverError: yup.array().test('mobileCoverError', 'שדה חובה', function() {
    const needneedMobileCoverageTravelers = this.parent.travelers.filter(t => t.needMobileCoverage);
    return  this.parent.travelers.length === 1 || !this.parent.isMobilecover || (this.parent.travelers.length > 1 && this.parent.isMobilecover && 
          needneedMobileCoverageTravelers.length >= 1)}),
  isOwnerHospital2: yup.string().when('isTravelsc', {
    is: true,
    then: yup.string().required('שדה חובה')
  }),
  isCancellationOfSelfParticipationOnRentedCarError: yup.boolean().test('isCancellationOfSelfParticipationOnRentedCarError', 'שדה חובה', function () {
    const needCancellationOfSelfParticipationOnRentedCarTravelers = this.parent.travelers.filter(t => t.cancellationOfSelfParticipationOnRentedCar);
    return !this.parent.isCancellationOfSelfParticipationOnRentedCar || (this.parent.isCancellationOfSelfParticipationOnRentedCar && needCancellationOfSelfParticipationOnRentedCarTravelers.length >= 1)
  }),
};

export default ValidationSchema;