import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core';
import {ContactUs} from 'wobi-web-common';
import {handleContactUsSubmit} from '../utils/apiHandlers';
import {fireGtmEvent} from '../utils/marketing.utils';

const SideBar = ({intl}) => {
  const classes = useStyles();
  const messages = id => intl.formatMessage({id});
  return (<div className={classes.sticky}>
    <ContactUs
      postRequest={(values) => {
        fireGtmEvent('TravelContactUs');
        return handleContactUsSubmit(values);
      }}
      isOpen={false}
      label={messages('contact_us.terms_conditions_label')}
      linkText={messages('contact_us.terms_conditions_link_text')}
    />
  </div>);
};

const useStyles = makeStyles(() => ({
  sticky: {
    position: 'sticky',
    top: 30,
  },
}));

SideBar.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(SideBar);
