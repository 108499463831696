import Moment from 'moment';

export function getAge(dateOrDateString) {
  const now = Moment();
  let dob;
  if (!dateOrDateString) {
    return null;
  }
  if (typeof dateOrDateString === 'string') {
    dob = Moment(dateOrDateString);
  } else {
    dob = dateOrDateString;
  } /* may be a Moment obj */
  return now.diff(dob, 'years');
}