import React from 'react';
import {withRouter} from 'react-router-dom';
import TabsLayout from '../components/TabsLayout';
import Payment from './tabs-contents/Payment';
import stepsTabs from '../config/tabs';
import {getCurrentTabIndex, tabIndexToName} from '../utils/routingHelpers';

const tabs = stepsTabs().offers;

class PaymentPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.setTab = this.setTab.bind(this);
  }

  componentDidMount() {
    this.setTab(this.props.location);
    this.unlisten = this.props.history.listen((location) => {
      this.setTab(location);
    });
  }

  submit() {
    this.props.history.push('thank-you');
  }

  setTab(location) {
    const tabIndex = getCurrentTabIndex(location, 'offers');
    if (tabIndex === false) {
      return;
    }
    sessionStorage.setItem('travel.payment-tab', tabIndex);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    this.props.changeSideBar(false);
    const offer = JSON.parse(sessionStorage.getItem('travel.offerSelected'));
    if (!offer.convertedPrice) {
      offer.convertedPrice = offer.totalPriceShekel? offer.totalPriceShekel : Number.parseFloat(offer.price * offer.currencyRate).toFixed(2);
      sessionStorage.setItem('travel.offerSelected', JSON.stringify(offer));
    }
    const initialTabs = JSON.parse(sessionStorage.getItem('travel.details-form'));
    const completeDetailsValues = JSON.parse(sessionStorage.getItem('travel.complete-details'));

    return (
      <TabsLayout
        isTabsContainer={false}
        tabs={tabs}
        currentTabIndex={Number(sessionStorage.getItem('travel.payment-tab'))}
      >
        <Payment
          offer={offer}
          initialTabs={initialTabs.values}
          completeDetailsValues={completeDetailsValues.values}
          submitAct={this.submit}
        />
      </TabsLayout>
    );
  }
}

export default withRouter(PaymentPage);
