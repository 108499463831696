/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/aria-role */
import React from 'react';
import {Backdrop, Modal} from '@material-ui/core';
import PropTypes from 'prop-types';
import './Popup.css';

import useStyles from './style';

function SuggestionAddRiderModal({isModalShow, buttons, title, description, subDescription, onClose}) {
  const classes = useStyles();
  const [imageWidth, setImageWidth] = React.useState(250);
  const [imageHeight, setImageHeight] = React.useState(150);

  // React.useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerHeight < 500) {
  //       setImageWidth(200);
  //       setImageHeight(100);
  //     } else {
  //       setImageHeight(150);
  //       setImageWidth(250);
  //     }
  //   };
  //   handleResize();
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);
  return (
    <Modal open={isModalShow} className={classes.modal}
      closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500}} onClose={onClose}>
      <div className={`popup-content ${classes.modalPaper}`}>
        <div role='close popup' className={`popup-close ${classes.popupClose}`} onClick={onClose}>
          <div className='popup-close-x'>X</div>
        </div>
        <div className={`popup-text popup-text-highlited ${classes.popupTextHighlited}`}>
          {title}
        </div>
        <div className={`popup-text ${classes.popupText}`}>
          {description}
        </div>
        <div className={`popup-text popup-text-highlited ${classes.popupTextHighlited}`}>
          {subDescription}
        </div>
        <div className='popup-buttons'>
          <div>
            <div type='button' className={`popup-button no-thanks-button ${classes.button2} ${classes.buttons}`} onClick={buttons.button2.onClick}>
              {buttons.button2.text}
            </div>
          </div>
          <div>
            <div type='button' className={`popup-button add-button ${classes.button1} ${classes.buttons}`} onClick={buttons.button1.onClick}>
              {buttons.button1.text}
            </div>
          </div>
        </div>
        <div className='popup-image'>
          {/* i need the image to change size depend on the screen size */}
          <img src={buttons.button1.image} alt='Presentor' width={imageWidth + 'px'} height={imageHeight + 'px'} />
        </div>
      </div>
    </Modal>
  );
}
SuggestionAddRiderModal.propTypes = {
  isModalShow: PropTypes.bool.isRequired,
  buttons: PropTypes.shape({
    button1: PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      image: PropTypes.string,
    }),
    button2: PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      image: PropTypes.string,
    }),
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  subDescription: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
SuggestionAddRiderModal.defaultProps = {
  title: 'title',
  description: 'description',
  onClose: () => { },
};
export default SuggestionAddRiderModal;