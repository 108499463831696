import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Layout from 'wobi-web-common/dist/components/Layout';
import {Breakpoints} from 'wobi-web-common';
import Header from './components/Header';
import MainContainer from './components/MainContainer';
import Routes from './components/Routes';
import SideBar from './steps/SideBar';
import withStepGuardMiddleware from './utils/StepGuardMiddleware';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import {setOffersFields} from './utils/persistOfferDetails';

setInterval(() => {
  agentDeveloperUtils.replaceWobiWithAgentName(document);
}
, 500);

const App = () => {
  const [renderSideBar, setRenderSideBar] = React.useState(false);
  const isMobile = useMediaQuery(`(max-width:${Breakpoints.mobile}px)`);

  const changeSideBar = (isOn) => {
    if (isOn !== renderSideBar) {
      setRenderSideBar(isOn);
    }
  };
  React.useEffect(() => {
    // save the agentDeveloperName in the session storage
    const agentDeveloperName = agentDeveloperUtils.agentData.Agent_Developer_Name_c;
    setOffersFields({agentDeveloperName});
  });

  React.useEffect(() => {
    agentDeveloperUtils.replaceWobiWithAgentName(document);
  });

  return (
    <MainContainer>
      <Layout
        sidebar={renderSideBar ?
          <SideBar/> : false}
        header={<Header isMobile={isMobile}/>}>
        <Routes changeSideBar={changeSideBar}/>
      </Layout>
    </MainContainer>
  );
};

export default withStepGuardMiddleware(App);
