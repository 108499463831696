import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Redirect} from 'react-router';

import InitialDetailsStep from '../steps/InitialDetailsStep';
import OffersStep from '../steps/OffersStep';
import CompleteDetailsStep from '../steps/CompleteDetailsStep';
import PaymentStep from '../steps/PaymentStep';
import ThankYouStep from '../steps/ThankYouStep';
import {PageNotFound} from 'wobi-web-common';

const Routes = ({changeSideBar}) => (
  <Switch>
    <Route exact path='/details' render={(props) => <InitialDetailsStep {...props} changeSideBar={changeSideBar} />} />
    <Route exact path='/initial-details' render={(props) => <InitialDetailsStep {...props} changeSideBar={changeSideBar} />} />
    <Route exact path='/'>
      <Redirect to='/details#destination' />
    </Route>
    <Route exact path='/offers' render={(props) => <OffersStep {...props} changeSideBar={changeSideBar} />} />
    <Route exact path='/complete-details' render={(props) => <CompleteDetailsStep {...props} changeSideBar={changeSideBar} />} />
    <Route exact path='/payment' render={(props) => <PaymentStep {...props} changeSideBar={changeSideBar} />} />
    <Route exact path='/thank-you' render={(props) => <ThankYouStep {...props} changeSideBar={changeSideBar} />} />
    <Route exact path='*' component={PageNotFound} />
  </Switch>
);

export default Routes;
