import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom'
import {BrowserRouter} from 'react-router-dom';
import WobiThemeProvider from 'wobi-web-common/dist/components/WobiThemeProvider';
import {IntlProvider} from 'react-intl';
import messages_he from './messages/he';
import rollbar from './config/rollbar';
import TagManager from 'react-gtm-module';
import rollbarInitialize from 'wobi-web-common/dist/config/rollbar';
import {createBrowserHistory} from 'history'
import patchBlockFn from 'history-block-async';
import { agentDeveloperUtils } from 'wobi-web-common/dist/components/utils/helpers';

const defaultLanguage = 'he';
const messages = {
  he: messages_he,
};

window.rollbar = rollbar;
//window.history.forward();

const gtmEnv = {
  gtmId: agentDeveloperUtils.getGtmId(),
};
TagManager.initialize(gtmEnv);
const AdditionalGtmIdArr = agentDeveloperUtils.getAdditionalTagManegerIds();
if (AdditionalGtmIdArr && Array.isArray(AdditionalGtmIdArr) && AdditionalGtmIdArr.length > 0) {
  // eslint-disable-next-line no-unused-vars
  for (const additionalGtmId of AdditionalGtmIdArr) {
    const additionalGtmEnviroment = {
      gtmId: additionalGtmId,
    };
    TagManager.initialize(additionalGtmEnviroment);
  }
}
const history = patchBlockFn(createBrowserHistory());

ReactDOM.render(
  <IntlProvider locale={defaultLanguage} messages={messages[defaultLanguage]}>
    <WobiThemeProvider>
      <Router history={history}>
        <App/>
      </Router>
    </WobiThemeProvider>
  </IntlProvider>,
  document.querySelector('#root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
