import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Paper, Grid, Backdrop} from '@material-ui/core';
import {Black, Blue} from 'wobi-web-common/dist/config/colors.config';
import Loader from 'wobi-web-common/dist/components/Loader';
import {getOffersFields} from '../../utils/persistOfferDetails';
import {handleContactUsSubmit} from '../../utils/apiHandlers';
import OffersContainer from '../offers/OffersContainer';
import NoOffers from '../offers/NoOffers';
import ErrorMessagePopup from '../../components/modals/ErrorMessage';
import {getImageByAgent} from '../../config/agent';

const LoaderTravelImage = getImageByAgent('loader-travel.gif');

const OffersList = React.memo(({
  className,
  offers,
  loaderState,
  keepChecking,
  submitAct,
  isSubmitting,
  handleErrorClose,
  isErrorOpen,
  handleNoOffers,
}) => {
  const classes = useStyles();
  const [showContactUsThanks, setShowContactUsThanks] = React.useState(false);
  const [isContactUsLoading, setContactUsLoading] = React.useState(false);

  const submitContactUs = () => {
    setContactUsLoading(true);
    handleContactUsSubmit({
      thereNoOffers: true,
    }).then(() => {
      setShowContactUsThanks(true);
      setContactUsLoading(false);
    });
  };

  const offersContainerProps = {offers,
    submitAct,
    isSubmitting};
  const noOffersProps = {classes,
    showContactUsThanks,
    isContactUsLoading,
    submitContactUs,
    handleNoOffers};

  return (
    <div className={className} data-testid='offers-main-container'>
      <Backdrop className={classes.backdrop} open={loaderState} data-testid='offers-loader'>
        <Paper style={{padding: 30}}>
          <Loader image={LoaderTravelImage}>
            מגוון הצעות בדרך...
          </Loader>
        </Paper>
      </Backdrop>
      <Grid container spacing={0} justify='center'>
        <Grid item xs={12} lg={8} className={classes.suggestionsTitle}>
          <Paper className={classes.paperOffers} elevation={0}>
            {offers.length ? <OffersContainer {...offersContainerProps} /> :
              !keepChecking && !offers.length ? <NoOffers {...noOffersProps} /> : false
            }
            <ErrorMessagePopup onClose={handleErrorClose} isOpen={isErrorOpen}/>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {},
  suggestionsTitle: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(24.5),
    fontWeight: 450,
  },
  loaderContainer: {
    maxHeight: 300,
    transition: 'max-height 0.75s ease-in',
    overflow: 'hidden',
    margin: '88px 0 38px',

    '&.hide': {
      transition: 'max-height 0.75s ease-out',
      maxHeight: 0,
      margin: 0,
    },
  },
  progressLine: {
    width: 247,
    height: 3,
    margin: '10px 0 0',
  },
  paperOffers: {
    borderRadius: 8,
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : Blue[150],
    margin: '29px 0 0',
  },
  backdrop: {
    zIndex: 9,
  },
  noOffers: {
    padding: 10,

    '& .text': {
      fontSize: theme.typography.pxToRem(21),
      color: Black[170],
    },

    '& .contact_us_loader': {
      margin: '30px 70px',
    },

    '& button': {
      marginTop: 30,
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: 700,
    },

    '& .img-container': {
      marginTop: 50,
      '& img': {
        width: '100%',
        maxWidth: 478,
      },
    },
  },
  contactUsThanks: {
    display: 'inline-flex',
    alignItems: 'center',
    margin: '20px 0',
    padding: '15px 40px',
    borderRadius: 10,
    border: `1px solid ${Black[1]}`,

    '& img': {
      width: 80,
    },

    '& > div': {
      color: Black[170],
      marginLeft: 20,
      fontSize: theme.typography.pxToRem(15.75),
    },
  },
}));

OffersList.propTypes = {
  offers: PropTypes.object,
  submitAct: PropTypes.func,
  coversTitles: PropTypes.object,
  className: PropTypes.object,
  loaderState: PropTypes.bool,
  keepChecking: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  handleErrorClose: PropTypes.func,
  isErrorOpen: PropTypes.bool,
};

export default OffersList;
