import {makeStyles} from '@material-ui/core';
import {Black} from 'wobi-web-common/dist/config/colors.config';

export default makeStyles(theme => ({
  paper: {
    width: 500,
    maxWidth: '100%',
    display: 'flex',
    maxHeight: 'calc(100% - 64px)',
    flexDirection: 'column',
    margin: 32,
    position: 'relative',
    overflowY: 'auto',
    outline: 'none',
    minHeight: '100px',
  },
  title: {
    color: theme.palette.secondary.dark,
    margin: '10px 0',
    fontSize: theme.typography.pxToRem(30.625),
    fontWeight: 700,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(19.25),
    },
  },
  subtitle: {
    color: theme.palette.secondary.dark,
    margin: '10px 0',
    fontSize: theme.typography.pxToRem(21.875),
    fontWeight: 500,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(15.75),
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalHeader: {
    height: 20,
  },
  modalBody: {
    padding: 20,
  },
  buttonDismiss: {
    display: 'inline-block',
    position: 'absolute',
    right: 32,
    top: 32,
    height: 14,
    width: 14,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      right: 22,
      top: 32,
    },
    '&:before, &:after': {
      position: 'relative',
      content: '""',
      display: 'block',
      width: 25,
      border: `1px solid ${Black[170]}`,
      [theme.breakpoints.down('md')]: {
        width: 17,
      },
    },
    '&:before': {
      top: 6,
      left: -1,
      transform: 'rotate(45deg)',
    },
    '&:after': {
      top: 4,
      left: -1,
      transform: 'rotate(-45deg)',
    },
  },
}));
