export default [
  {
    index: 0,
    label: 'פרטים ראשונים',
    key: 'details',
    meta: 'BackIntialDetails'
  },
  {
    index: 1,
    label: 'קבלת הצעות',
    key: 'offers',
    meta: 'BackOffers'
  },
  {
    index: 2,
    label: 'השלמת פרטים',
    key: 'complete-details',
    meta: 'BackAdditional'
  },
  {
    index: 3,
    label: 'תשלום',
    key: 'payment',
    meta: 'BackPayments'
  },
  {
    index: 4,
    label: 'סיום תהליך',
    key: 'thank-you',
    meta: ''
  }
];
