import React from 'react';
import {withRouter} from 'react-router-dom';
import TabsLayout from '../components/TabsLayout';
import CompleteDetails from './tabs-contents/CompleteDetails';
import stepsTabs from '../config/tabs';
import {getCurrentTabIndex, tabIndexToName} from '../utils/routingHelpers';

const tabs = stepsTabs().offers;

class CompleteDetailsPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.setTab = this.setTab.bind(this);
  }

  componentDidMount() {
    this.setTab(this.props.location);
    this.unlisten = this.props.history.listen((location) => {
      this.setTab(location);
    });
  }

  submit() {
    this.props.history.push('payment');
  }

  setTab(location) {
    const tabIndex = getCurrentTabIndex(location, 'offers');
    if (tabIndex === false) {
      return;
    }
    sessionStorage.setItem('travel.complete-details-tab', tabIndex);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    this.props.changeSideBar(false);
    const offer = JSON.parse(sessionStorage.getItem('travel.offerSelected'));
    const initialTabs = JSON.parse(sessionStorage.getItem('travel.details-form'));

    return (
      <TabsLayout
        isTabsContainer={false}
        tabs={tabs}
        currentTabIndex={Number(sessionStorage.getItem('travel.complete-details-tab'))}
      >

        <CompleteDetails offer={offer} initialTabs={initialTabs?.values} submitAct={() => this.submit()}/>
      </TabsLayout>
    );
  }
}

export default withRouter(CompleteDetailsPage);
