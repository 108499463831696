import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import TabsLayout from '../components/TabsLayout';
import OffersList from './tabs-contents/OffersList';
import stepsTabs from '../config/tabs';
import {getCurrentTabIndex} from '../utils/routingHelpers';
import {sortingOptions} from './offers/offersSort';
import {
  checkOfferStatus,
  getAllOffers,
  updateCustomerSelectedOffer,
  updateNoOffers,
} from '../utils/apiHandlers';
import {getOffersFields, getUtmFields, setOffersFields, setSelectedOffer} from '../utils/persistOfferDetails';
import {fireGtmEvent} from '../utils/marketing.utils';
import {FORMIK_DEBOUNCE} from '../utils/formik-persist';

// import Offers from "../config/mock.offers";

const tabs = stepsTabs().offers;

const CHECK_STATUS_TIMEOUT = process.env.REACT_APP_CHECK_STATUS_TIMEOUT || 2000;
const CHECK_STATUS_TIME_LIMIT = process.env.REACT_APP_CHECK_STATUS_TIME_LIMIT || 10000;

class OffersPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.setTab = this.setTab.bind(this);
    this.handler = this.handler.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
    this.getOffers = this.getOffers.bind(this);
    this.handleSelectedOffer = this.handleSelectedOffer.bind(this);
    this.handleNoOffers = this.handleNoOffers.bind(this);

    this.state = {
      offers: [],
      offersCallId: '',
      keepChecking: true,
      loader: true,
      lowestPrice: 0,
      sortTypeId: 1,
      isSubmitting: false,
    };
  }

  async handler(actionType, values) {
    switch (actionType) {
    case 'sort': {
      const sortTypeId = values;
      const comparer = sortingOptions.find(sortingOption => sortingOption.id === sortTypeId).comparer;
      this.setState({
        sortTypeId,
        offers: [...this.state.offers.sort(comparer)],
      });
      break;
    }
    }
  }

  checkStatus() {
    checkOfferStatus({
      offersCallId: this.state.offersCallId,
    }).then(resp => {
      if (resp.data && resp.data.responses && resp.data.responses.length) {
        const respOffers = resp.data.responses;
        this.setState({
          offers: respOffers,
          lowestPrice: respOffers
            .reduce((lowestPrice, {price}) => !lowestPrice ? price : price < lowestPrice ? price : lowestPrice, 0),
        }, () => {
          this.handler('sort', this.state.sortTypeId);
        });
      }
    });
  }

  initTimer() {
    const that = this;
    this.setState({keepChecking: true,
      loader: true}, () => {
      this.statusInterval = setInterval(() => {
        if (that.state.keepChecking) {
          that.checkStatus();
        } else {
          that.setState({keepChecking: false,
            loader: false});
          clearInterval(that.statusInterval);
        }
      }, CHECK_STATUS_TIMEOUT);

      this.timeout = setTimeout(() => {
        that.setState({
          keepChecking: false,
          loader: false,
        });
        clearInterval(that.statusInterval);
      }, CHECK_STATUS_TIME_LIMIT);
    });
  }

  getOffers() {
    const requestData = JSON.parse(sessionStorage.getItem('travel.details-form')).values;
    requestData.agentDeveloperName = getOffersFields('agentDeveloperName');
    requestData.utmFields = getUtmFields();
    if (sessionStorage.getItem('leadId')) {
      requestData.leadId = sessionStorage.getItem('leadId');
    }
    if (sessionStorage.getItem('parentLeadId')) {
      requestData.parentLeadId = sessionStorage.getItem('parentLeadId');
    }
    this.setState({
      offers: [],
      keepChecking: true,
    }, () => {
      getAllOffers(requestData).then(resp => {
        if (resp.data) {
          const offerCallIdState = {offersCallId: resp.data.offersCallId};
          const leadId = resp.data.leadId;
          setOffersFields(offerCallIdState);
          sessionStorage.setItem('leadId', leadId);
          this.setState(offerCallIdState);
        }
        this.initTimer();
      }).catch(() => {
        this.setState({keepChecking: false});
      });
    });
  }

  componentDidMount() {
    this.setTab(this.props.location);

    // set minimal delay so that formik-persist has time to save form values to session storage
    setTimeout(this.getOffers, FORMIK_DEBOUNCE + 10);
    this.unlisten = this.props.history.listen((location) => {
      this.setTab(location);
    });
  }

  clearInsuranceCoversWarning = (data) => data.insuranceCovers.filter(cover => cover.warning !== true);

  handleSelectedOffer = async (data) => {
    data.insuranceCovers = this.clearInsuranceCoversWarning(data);
    if (sessionStorage.getItem('leadId')) {
      data.leadId = sessionStorage.getItem('leadId');
    }

    setSelectedOffer(data);
    const resp = await updateCustomerSelectedOffer({
      selectedOffer: data,
      token: this.state.offersCallId,
    });
    return Boolean(resp.success);
  };

  // Made indiction there no have offers to the user
  handleNoOffers = () => {
    updateNoOffers({
      token: this.state.offersCallId,
    });
  }

  submit = async (offerIndex) => {
    this.setState({isSubmitting: true});
    sessionStorage.setItem('travel.offerSelected', JSON.stringify(this.state.offers[offerIndex]));
    sessionStorage.setItem('travel.offersCallId', JSON.stringify(this.state.offersCallId));
    fireGtmEvent('travelPurchase', {provider: this.state.offers[offerIndex]});
    const response = await this.handleSelectedOffer(this.state.offers[offerIndex]);
    this.setState({isSubmitting: false});
    if (response) {
      this.props.history.push('complete-details');
    } else {
      this.setState({isErrorOpen: true});
    }
  }

  setTab(location) {
    const tabIndex = getCurrentTabIndex(location, 'offers');
    if (tabIndex === false) {
      return;
    }
    sessionStorage.setItem('travel.offers-tab', tabIndex);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    this.props.changeSideBar(false);

    return (
      <TabsLayout
        isTabsContainer={false}
        tabs={tabs}
        currentTabIndex={Number(sessionStorage.getItem('travel.offers-tab'))}
      >

        <OffersList
          offers={this.state.offers}
          loaderState={this.state.loader}
          keepChecking={this.state.keepChecking}
          submitAct={(offerIndex) => this.submit(offerIndex)}
          isSubmitting={this.state.isSubmitting}
          isErrorOpen={this.state.isErrorOpen}
          handleNoOffers={this.handleNoOffers}
          handleErrorClose={() => this.setState({isErrorOpen: false})}
        />
      </TabsLayout>
    );
  }
}

OffersPage.propTypes = {
  location: PropTypes.any,
  changeSideBar: PropTypes.bool,
  history: PropTypes.any,
};

export default withRouter(OffersPage);
