const {REACT_APP_TRAVEL_SERVICE_URL, REACT_APP_NODE_ENV} = process.env;
const protocolPrefix = REACT_APP_NODE_ENV === 'development' ? 'http://' : 'https://';

/**
 * Common function for API call request
 * If application running locally, then API request will be mocked
 *
 * @param {Object} params - Object of params for API call
 * @param {string} params.endpoint
 * @param {Object} [params.data] - Data of call (only for POST)
 * @param {Object} [params.headers]
 * @param {string} params.method
 * @returns {Promise} - Promise with real or mocked call
 */
export const apiRequest = ({endpoint, data, headers = {}, method}) => {
  const baseHeaders = {
    'Content-Type': 'application/json'
  };

  return fetch(`${protocolPrefix}${REACT_APP_TRAVEL_SERVICE_URL}/${endpoint}`, {
    body: JSON.stringify(data),
    headers: Object.assign(baseHeaders, headers),
    method
  })
    .then(async (resp) => {
      const json = await resp.json();
      return {
        ...json,
        statusCode: resp.status,
      };
    })
    .catch(error => error);
};

/**
 * API call request with method 'POST'
 *
 * @param {string} endpoint
 * @param {Object} [data]
 * @param {Object} [headers]
 */
export const apiPostRequest = (endpoint, data, headers = {}) => apiRequest({
  data,
  endpoint,
  headers,
  method: 'POST'
});

/**
 * API call request with method 'GET'
 *
 * @param {string} endpoint
 * @param {Object} [headers]
 */
export const apiGetRequest = (endpoint, headers = {}) => apiRequest({
  endpoint,
  headers,
  method: 'GET'
});
