import { SessionTimer} from '../utils/insurencSessionTimer';

const OFFERS_FIELDS_KEY = 'offers_fields';
const UTM_FIELDS_KEY = 'utm_fields';
const SELECTED_OFFER_KEY = 'selected';
const SESSION_TIMESTAMP = 'session_timestamp';
const SESSION_DETAILS_FORM = 'travel.details-form';

SessionTimer.startSessionTimer();

export const getFromStorage = (storageKey, key = false) => {
  const dataString = sessionStorage.getItem(storageKey);
  const dataObject = dataString ? JSON.parse(dataString) : {};

  return key ? dataObject[key] : dataObject;
};

const saveToStorage = (storageKey, newData = {}) => {
  const oldDataObject = getFromStorage(storageKey);
  sessionStorage.setItem(storageKey, JSON.stringify(Object.assign(oldDataObject, newData)));
};

export const setOffersFields = (newData = {}) => saveToStorage(OFFERS_FIELDS_KEY, newData);
export const getOffersFields = (key = false) => getFromStorage(OFFERS_FIELDS_KEY, key);
export const setUtmFields = (newData = {}) => saveToStorage(UTM_FIELDS_KEY, newData);
export const getUtmFields = (key = false) => getFromStorage(UTM_FIELDS_KEY, key);
export const getSelectedOffer = (key = false) => getFromStorage(SELECTED_OFFER_KEY, key);
export const setSelectedOffer = (newData = {}) => saveToStorage(SELECTED_OFFER_KEY, newData);
export const setDetailForm = (newData = {}) => saveToStorage(SESSION_DETAILS_FORM, newData);
export const getDetailForm = (key = false) => getFromStorage(SESSION_DETAILS_FORM, key);

export const removeOffersFieldsValue = (key) => {
  const offersFieldsObject = getOffersFields();
  delete offersFieldsObject[key];

  sessionStorage.setItem(OFFERS_FIELDS_KEY, JSON.stringify(offersFieldsObject));
};

export const clearOffersFields = () => {
  sessionStorage.removeItem(OFFERS_FIELDS_KEY);
};

export const preSaveHandler = () => {
  SessionTimer.updateSessionTimer();

  return true;
};
