import React, {useState, useEffect} from 'react';
import {Backdrop, Modal, Paper, Box, Checkbox, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import useStyles from './styles';

export const PregnancyAndExtremeSportsPopup = ({isOpen, onClose, onCheckBoxEvent, sportsStatus,typeOfShowPregnancyAndExtremeSportsPopup}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  function handleCloseModal() {
    onClose(false);
    setIsModalOpen(false);
  }
  return (
    <Modal
      open={isModalOpen}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{timeout: 500}}
    >
      <Paper className={classes.paper}>
        <div data-testid='error-popup' className={classes.modalHeader}>
          <Box onClick={handleCloseModal}><span className={classes.buttonDismiss}/></Box>
        </div>
        <div className={classes.modalBody}>
          <h3 className={classes.subtitle}>
            {intl.formatMessage({id: typeOfShowPregnancyAndExtremeSportsPopup}) }
          </h3>
          <Checkbox
            id='isPregnancyAndExtremeSports'
            data-testid='isPregnancyAndExtremeSports'
            onChange={(event) => {
              setIsChecked(event.target.checked);
              onCheckBoxEvent(event.target.checked);
            }}
          />
          <label htmlFor='isPregnancyAndExtremeSports' className={classes.description}>
            {intl.formatMessage({id: 'pregnancy_and_extreme_sports.checkbox'})}
          </label>
          <Button onClick={handleCloseModal} className={classes.buttonClose} disabled={!isChecked}>
            { intl.formatMessage({id: 'pregnancy_and_extreme_sports.button'}) }
          </Button>
        </div>

      </Paper>
    </Modal>
  );
};

PregnancyAndExtremeSportsPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCheckBoxEvent: PropTypes.func.isRequired,
  sportsStatus: PropTypes.object.isRequired,
};