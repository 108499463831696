export const ridersTypes = [
  {
    formikKey: 'isDetection',
    id: 'locateAndExtract',
    name: 'איתור וחילוץ',
  },
  {
    formikKey: 'isLiable',
    id: 'travelThirdParty',
    name: 'חבות כלפי צד ג\'',
  },
  {
    formikKey: 'isCharger',
    id: 'luggage',
    name: 'כבודה (מטען)',
  },
  {
    formikKey: 'isEsports',
    id: 'extreme',
    name: 'ספורט אתגרי',
  },
  {
    formikKey: 'isWsports',
    id: 'winterExtreme',
    name: 'ספורט חורף',
  },
  {
    formikKey: 'isTravelsc',
    id: 'cancellation',
    name: 'ביטול או קיצור הנסיעה עקב סיבות רפואיות',
  },
  {
    formikKey: 'isPccover',
    id: 'pcAndTablet',
    name: 'מחשב/טאבלט',
  },
  {
    formikKey: 'isMobilecover',
    id: 'mobile',
    name: 'טלפון נייד',
  },
  {
    formikKey: 'isCancellationOfSelfParticipationOnRentedCar',
    id: 'cancellationOfSelfParticipationOnRentedCar',
    name: 'ביטול השתתפות עצמית לרכב שכור ',
  },
];