import {agentDeveloperUtils} from 'wobi-web-common';

class InsurancSessionTimer {
    insurenceFlowTimer = null;

    interval = process.env.REACT_APP_SESSION_TIMEOUT;

    startPage = agentDeveloperUtils.getWebsiteBaseUrl;

    updateSessionTimer() {
      clearTimeout(this.insurenceFlowTimer);
      this.startSessionTimer();
    }

    startSessionTimer() {
      this.insurenceFlowTimer = setTimeout(() => {
        if (!window.location.hash && window.location.pathname === '/') {
          return this.updateSessionTimer();
        }
        sessionStorage.clear();
        window.location.href = '/';
      }, this.interval);
    }

    constructor() {
      this.updateSessionTimer = this.updateSessionTimer.bind(this);
      this.startSessionTimer = this.startSessionTimer.bind(this);
    }
}

export const SessionTimer = new InsurancSessionTimer();