/* eslint-disable complexity */
import React from 'react';
import moment from 'moment';
import {getAge} from '../../../../../utils/dateHelpers';


export function getTravelers (formikData) {
  /* add to each traveler, if not already exists:
    fullName: firstName LastName,
    minorDiseases: [],
    chronic: false,
    additionalChronic: false,
    age: calculated according to dob (only added for females),
    pregnant: false  (only added for females),
    week: ''  (only added for females),
  */

  let arr = [];
  for (let i = 1; i <= 10; i++){
    let obj = {};
    obj.dob       = formikData['dob' + i];
    obj.ID        = formikData['ID' + i];
    obj.firstName = formikData['firstName' + i];
    obj.lastName  = formikData['lastName' + i];
    obj.gender    = formikData['gender' + i];
    const oldTravelers = formikData['travelers'];
    obj.additionalChronic = oldTravelers[i - 1] && oldTravelers[i - 1].additionalChronic ? oldTravelers[i - 1].additionalChronic : false;
    obj.chronic = oldTravelers[i - 1] && oldTravelers[i - 1].chronic ? oldTravelers[i - 1].chronic : null;
    if (obj.gender === '2') {
      obj.pregnant = oldTravelers[i - 1] && oldTravelers[i - 1].pregnant ? oldTravelers[i - 1].pregnant : false;
    }
    obj.needPcCoverage =   oldTravelers[i-1] &&  oldTravelers[i-1].needPcCoverage ?   oldTravelers[i-1].needPcCoverage : null;
    obj.needMobileCoverage = oldTravelers[i - 1] && oldTravelers[i - 1].needMobileCoverage ? oldTravelers[i - 1].needMobileCoverage : null;
    obj.cancellationOfSelfParticipationOnRentedCar = oldTravelers[i - 1] && oldTravelers[i - 1].cancellationOfSelfParticipationOnRentedCar
      ? oldTravelers[i - 1].cancellationOfSelfParticipationOnRentedCar : false;
    obj.pc =   oldTravelers[i-1] &&  oldTravelers[i-1].pc ?   oldTravelers[i-1].pc : null;
    obj.mobile =   oldTravelers[i-1] &&  oldTravelers[i-1].mobile ?   oldTravelers[i-1].mobile : null;
    obj.minorDiseases = oldTravelers[i - 1] && oldTravelers[i - 1].minorDiseases ? oldTravelers[i - 1].minorDiseases : null;
    obj.medicalEscalation =   oldTravelers[i-1] &&  oldTravelers[i-1].medicalEscalation ?   oldTravelers[i-1].medicalEscalation : null;
    obj.medicalTreatment = oldTravelers[i - 1] && oldTravelers[i - 1].medicalTreatment ? oldTravelers[i - 1].medicalTreatment : false;
    obj.surgeryMoreThan3Months = oldTravelers[i - 1] && oldTravelers[i - 1].surgeryMoreThan3Months ? oldTravelers[i - 1].surgeryMoreThan3Months : false;
    obj.recommendationForMedicines = oldTravelers[i - 1] && oldTravelers[i - 1].recommendationForMedicines ? oldTravelers[i - 1].recommendationForMedicines : false;
    obj.additionalExistingMedicalCondition = oldTravelers[i - 1] && oldTravelers[i - 1].additionalExistingMedicalCondition ? oldTravelers[i - 1].additionalExistingMedicalCondition : false;
    obj.severDisease1 = oldTravelers[i - 1] && typeof oldTravelers[i - 1].severDisease1 === 'string' ? oldTravelers[i - 1].severDisease1 : undefined;
    obj.severDisease2 = oldTravelers[i - 1] && typeof oldTravelers[i - 1].severDisease2 === 'string' ? oldTravelers[i - 1].severDisease2 : undefined;
    obj.severDisease3 = oldTravelers[i - 1] && typeof oldTravelers[i - 1].severDisease3 === 'string' ? oldTravelers[i - 1].severDisease3 : undefined;
    obj.severDisease4 = oldTravelers[i - 1] && typeof oldTravelers[i - 1].severDisease4 === 'string' ? oldTravelers[i - 1].severDisease4 : undefined;
    if (Object.values(obj).some(prop => !!prop)) arr.push(obj);  /* presume all props are initiated with falsy values */
  }

  arr.map((traveler, index) => {
    let age = getAge(traveler.dob);
    traveler.age = age;
    if (traveler.gender === '2' && age && age >= 17 && age <= 60) {
      traveler.fullName =          traveler.fullName ? traveler.fullName : `${traveler.firstName} ${traveler.lastName}`;
      traveler.minorDiseases =     traveler.minorDiseases ? traveler.minorDiseases : [];
      traveler.chronic =           traveler.chronic ? traveler.chronic : false;
      traveler.additionalChronic = traveler.additionalChronic ? traveler.additionalChronic : false;

      traveler.severDisease1 = typeof traveler.severDisease1 === 'string' ? traveler.severDisease1 : undefined;
      traveler.severDisease2 = typeof traveler.severDisease2 === 'string' ? traveler.severDisease2 : undefined;
      traveler.severDisease3 = typeof traveler.severDisease3 === 'string' ? traveler.severDisease3 : undefined;
      traveler.severDisease4 = typeof traveler.severDisease4 === 'string' ? traveler.severDisease4 : undefined;

      traveler.pregnant =          traveler.pregnant ? traveler.pregnant : false;
      traveler.week =              traveler.week ? traveler.week : '';
      traveler.medicalTreatment = traveler.medicalTreatment ? traveler.medicalTreatment : false;
      traveler.surgeryMoreThan3Months = traveler.surgeryMoreThan3Months ? traveler.surgeryMoreThan3Months : false;
      traveler.recommendationForMedicines = traveler.recommendationForMedicines ? traveler.recommendationForMedicines : false;
      traveler.additionalExistingMedicalCondition = traveler.additionalExistingMedicalCondition ? traveler.additionalExistingMedicalCondition : false;
    } else {
      traveler.fullName =          traveler.fullName ? traveler.fullName : `${traveler.firstName} ${traveler.lastName}`;
      traveler.minorDiseases =     traveler.minorDiseases ? traveler.minorDiseases : [];
      traveler.chronic =           traveler.chronic ? traveler.chronic : false;
      traveler.additionalChronic = traveler.additionalChronic ? traveler.additionalChronic : false;
      traveler.coversItems =       traveler.coversItems  ? traveler.coversItems  : null
      traveler.medicalTreatment = traveler.medicalTreatment ? traveler.medicalTreatment : false;
      traveler.surgeryMoreThan3Months = traveler.surgeryMoreThan3Months ? traveler.surgeryMoreThan3Months : false;
      traveler.recommendationForMedicines = traveler.recommendationForMedicines ? traveler.recommendationForMedicines : false;
      traveler.additionalExistingMedicalCondition = traveler.additionalExistingMedicalCondition ? traveler.additionalExistingMedicalCondition : false;
      //delete traveler.age;
      delete traveler.pregnant;
      delete traveler.week;
    }
    return traveler.ID
  })
  return arr;
}

export const utils = {
  useForceUpdate: function () {
    const [value, setValue] = React.useState(true); //boolean state
    return () => setValue(!value); // toggle the state to force render
  },

  filterWomenTravelers: function (travelers) {
    // return array of travelers with gender:female & age:17-60
    return travelers.filter(traveler => {
      return parseInt(traveler.gender, 10) === 2 &&
             traveler.age && traveler.age >= 17 && traveler.age <= 60
    })
  },

  filterPregnantTravelers: function (travelers) {
    // return array of women that are checked as pregnant=true
    return travelers.filter(traveler => {
      return parseInt(traveler.gender, 10) === 2 &&
             traveler.age && traveler.age >= 17 && traveler.age <= 60 &&
             traveler.pregnant;
    })
  },

  filterPregnantWithBigWeek: function (travelers, policyEndDate) {
    // return array of women that are pregnant and with too-high week (33-43)
    const timeLeftToBackHome = moment(policyEndDate).diff(moment(), 'weeks');
    return travelers.filter(traveler => {
      let {age, gender, pregnant, week} = traveler;
      return parseInt(gender, 10) === 2 &&
        age && age >= 17 &&
        age <= 60 &&
        pregnant &&
        week &&
        !isNaN(parseInt(week, 10)) &&
        parseInt(week, 10) + timeLeftToBackHome > 32 &&
        parseInt(week, 10) <= 43;
    })
  },

  filterChronicTravelers: function (travelers) {
    // return array of travelers that are checked as chronic=true
    return travelers.filter(traveler => {
      return traveler.chronic;
    })
  },

  filterAdditionalChronicTravelers: function (travelers) {
    // return array of travelers that are checked as additionalChronic=true
    return travelers.filter(traveler => {
      return traveler.additionalChronic;
    })
  },

  filterFirstWoman: function (travelers) {
    return travelers.filter(x => parseInt(x.gender, 10) === 2)
  },

  getIndexByID: function (array, id) {
    return array.findIndex(x => x.ID === id);
  },

  isPositiveNumber: function (value) {
    return (/^(\d+)$/.test(value));
  },

  filterAllNotPregnantTravelers: function (travelers) {
    const pregnantTravelers = this.filterPregnantTravelers(travelers);
    return travelers.filter((element) => !pregnantTravelers.includes(element));
  },

  filterMedicalTreatmentTravelers: function (travelers) {
    // return array of travelers that are checked as chronic=true
    return travelers.filter(traveler => {
      return traveler.medicalTreatment;
    })
  },

  filterMedicalEscalationTravelers: function (travelers) {
    // return array of travelers that are checked as chronic=true
    return travelers.filter(traveler => {
      return traveler.medicalEscalation;
    })
  },

  filterSeverDiseaseTravelers: function (severDiseaseNumber, travelers) {
    // return array of travelers that are checked as chronic=true
    return travelers.filter(traveler => {
      return traveler[severDiseaseNumber] === 'yes';
    })
  },

  getIndexFromIDArrayByID: function (array, id) {
    return array.findIndex(x => x === id);
  },
};
