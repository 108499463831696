import React from 'react';
import PropTypes from 'prop-types';
import FontFaceObserver from 'fontfaceobserver';
import * as yup from 'yup';
import Moment from 'moment';
import DatePicker from 'wobi-web-common/dist/components/Datepicker';
import EzFormControl from 'wobi-web-common/dist/components/EzFormControl';
import {makeStyles} from '@material-ui/core';
import {FormattedMessage, useIntl} from 'react-intl';
import {validateDate} from '../../../utils/validationHelpers';
import {fireGtmEvent} from '../../../utils/marketing.utils';
import CountriesSelect from '../../../components/CountriesSelect';

const Tab1 = React.memo(
  ({
    idForAccessibility,
    className,
    submitCallback,
    validationCallback,
    formikSetField,
    actionButton,
    formikValues,
    formikErrors,
    formikSetTouched,
    formikValidateForm,
    initialStartDateFromServer,
  }) => {
    if (!initialStartDateFromServer) {
      return null;
    }

    // mocked policy start date, it has to be received from backend
    const [startDate, setStartDate] = React.useState('');
    const intl = useIntl();
    const [daysNumberMessage, setDaysNumberMessage] = React.useState('');
    const [endDate, setEndDate] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const font = new FontFaceObserver('Ploni');
    font.load().then(() => {
      setIsLoaded(true);
    });

    if (
      !formikValues.policyStartDate &&
      startDate === '' &&
      initialStartDateFromServer
    ) {
      const today = new Moment();
      const date = Moment(initialStartDateFromServer, 'YYYY-MM-DD');
      const policyStartDate = date.isSame(today, 'day') ?
        date
          .set('hour', today.hour())
          .set('minutes', today.minute())
          .format('YYYY-MM-DDTHH:mm:ss') :
        Moment(date)
          .set('hour', 0)
          .set('minutes', 0)
          .format('YYYY-MM-DDTHH:mm:ss');
      formikSetField('policyStartDate', policyStartDate);
      setStartDate(policyStartDate);
    } else if (
      startDate === '' &&
      endDate === null &&
      formikValues.policyStartDate &&
      formikValues.policyEndDate
    ) {
      // update dates if insert after fill
      setEndDate(formikValues.policyEndDate);
      setStartDate(formikValues.policyStartDate);
      if (
        Moment(formikValues.policyStartDate).isValid() &&
        Moment(formikValues.policyEndDate).isValid()
      ) {
        const countDays =
          Number(
            Moment(formikValues.policyEndDate).diff(
              Moment(formikValues.policyStartDate),
              'days',
            ),
          ) + 1;
        countDays > 0 ?
          setDaysNumberMessage(
            intl.formatMessage({id: 'step1.tab1.days_num_message'}) +
                countDays,
          ) :
          setDaysNumberMessage('');
      } else {
        setDaysNumberMessage('');
      }
    }
    const startDayRecievedFromBackendMock = initialStartDateFromServer;
    const initialStartDate =
      (formikValues.policyStartDate &&
        Moment(Moment(formikValues.policyStartDate).format('YYYY-MM-DD'))) ||
      startDayRecievedFromBackendMock;
    const maxStartDate = Moment(startDayRecievedFromBackendMock).add(
      '90',
      'days',
    );
    const maxEndDate = Moment(initialStartDate).add('180', 'days');
    const classes = useStyles();

    // dynamic callback to React.Component method
    const validationObject = {
      policyEndDate: yup
        .mixed()
        .required('שדה חובה')
        .test('underMax', 'זמן הביטוח עד 180 יום', function () {
          const policyEndDateMoment =
            (this.parent.policyEndDate &&
              Moment(Moment(this.parent.policyEndDate).format('YYYY-MM-DD'))) ||
            (this.parent.policyStartDate &&
              Moment(
                Moment(this.parent.policyStartDate).format('YYYY-MM-DD'),
              )) ||
            startDayRecievedFromBackendMock;
          const initialStartDateMoment =
            (this.parent.policyStartDate &&
              Moment(
                Moment(this.parent.policyStartDate).format('YYYY-MM-DD'),
              )) ||
            startDayRecievedFromBackendMock;
          const maxEndDate = Moment(initialStartDateMoment).add('180', 'days');
          return policyEndDateMoment.isSameOrBefore(maxEndDate) && policyEndDateMoment.isAfter(initialStartDateMoment);
        }),
      policyStartDate: validateDate(
        startDayRecievedFromBackendMock,
        maxStartDate,
      ),
      targetCountries: yup.string().typeError().required('שדה חובה'),
    };
    setTimeout(() => {
      validationCallback(validationObject);
    }, 1000);

    const handleStartDatepickerChange = (value, setFieldValue) => {
      // if (value instanceof Date && !isNaN(value)) alert(value);
      const wasToday = Moment(formikValues.policyStartDate).isSame(
        Moment(),
        'day',
      );
      const isToday = Moment(value).isSame(Moment(), 'day');
      value = isToday ?
        Moment(value).format('YYYY-MM-DDTHH:mm:ss') :
        Moment(value)
          .set('hour', 0)
          .set('minutes', 0)
          .format('YYYY-MM-DDTHH:mm:ss');
      setFieldValue('policyStartDate', value);
      setStartDate(value);

      if (isToday != wasToday) {
        const updateEndDate = isToday ?
          Moment(endDate).format('YYYY-MM-DDTHH:mm:ss') :
          Moment(endDate)
            .set('hour', 0)
            .set('minutes', 0)
            .format('YYYY-MM-DDTHH:mm:ss');
        setFieldValue('policyEndDate', updateEndDate);
        setEndDate(updateEndDate);
      }

      if (Moment(endDate).isValid() && Moment(value).isValid()) {
        const countDays =
          Number(Moment(endDate).diff(Moment(value), 'days')) + 1;
        countDays > 0 ?
          setDaysNumberMessage(
            intl.formatMessage({id: 'step1.tab1.days_num_message'}) +
                countDays,
          ) :
          setDaysNumberMessage('');
      } else {
        setDaysNumberMessage('');
      }
    };

    const handleEndDatepickerChange = (value, setFieldValue) => {
      value = Moment(formikValues.policyStartDate).isSame(Moment(), 'day') ?
        Moment(value).format('YYYY-MM-DDTHH:mm:ss') :
        Moment(value)
          .set('hour', 0)
          .set('minutes', 0)
          .format('YYYY-MM-DDTHH:mm:ss');
      setFieldValue('policyEndDate', value);
      setEndDate(value);

      if (Moment(startDate).isValid() && Moment(value).isValid()) {
        const countDays = Number(Moment(value).diff(startDate, 'days')) + 1;
        countDays > 0 ?
          setDaysNumberMessage(
            intl.formatMessage({id: 'step1.tab1.days_num_message'}) +
                countDays,
          ) :
          setDaysNumberMessage('');
      } else {
        setDaysNumberMessage('');
      }
    };

    const handleSubmit = async () => {
      const errors = await formikValidateForm();
      if (Object.keys(errors).length === 0) {
        fireGtmEvent('confirmDestination', {
          dateDepart: formikValues.policyStartDate,
          dateReturn: formikValues.policyEndDate,
          destination: formikValues.targetCountries,
        });
        const {values} = JSON.parse(sessionStorage.getItem('travel.details-form')) || {};

        const {policyEndDate, targetFrame} = values || {};
        if (!policyEndDate || !targetFrame || !targetFrame.length) {
          sessionStorage.removeItem('travel.details-form');
          Object.keys(validationObject).forEach((field) => {
            formikSetField(field, null);
            formikSetTouched(field, true);
          });
          window.rollbar.error(JSON.stringify({
            browser: window.navigator.appCodeName,
            browserVersion: window.navigator.appVersion,
            field: !policyEndDate ? 'policyEndDate' : 'targetFrame',
            fieldValue: formikValues.policyEndDate || formikValues.targetFrame,
          }));
        } else {
          submitCallback(1);
        }
      } else {
        Object.keys(validationObject).forEach((field) => formikSetTouched(field, true));
      }
    };

    return (
      <div className={className} id={idForAccessibility} role='tabpanel'>
        <div className={classes.title} style={{fontFamily: isLoaded ? 'Ploni' : ''}}>
          <h2>{intl.formatMessage({id: 'step1.tab1.gettingStarted'})}</h2>
          <h2>{intl.formatMessage({id: 'step1.tab1.fillNextFields'})}</h2>
        </div>
        <div className={classes.innerTitle} style={{fontFamily: isLoaded ? 'Ploni' : ''}}>
          {intl.formatMessage({id: 'step1.tab1.travelDates'})}
          {' '}
          <span>{daysNumberMessage}</span>
        </div>
        <div className={classes.datesWrapper}>
          <EzFormControl
            name='policyStartDate'
            label={intl.formatMessage({id: 'step1.tab1.start_date'})}
            isLabelInChild
          >
            <DatePicker
              style={{width: '100%'}}
              value={formikValues.policyStartDate}
              minDate={startDayRecievedFromBackendMock}
              maxDate={maxStartDate}
              autoFocus
              maxDateMessage=''
              minDateMessage=''
              onChange={(date) => handleStartDatepickerChange(date, formikSetField)
              }
              onAccept={() => {}}
              openTo='date'
              invalidDateMessage=''
              autoOk
              id='policyStartDate'
              variant='inline'
              data-testid='date-picker-start'
            />
          </EzFormControl>
          <EzFormControl
            name='policyEndDate'
            label={intl.formatMessage({id: 'step1.tab1.end_date'})}
            isLabelInChild
          >
            <DatePicker
              style={{width: '100%'}}
              value={formikValues.policyEndDate}
              minDate={formikValues.policyStartDate}
              maxDate={maxEndDate}
              maxDateMessage=''
              minDateMessage=''
              onChange={(date) => handleEndDatepickerChange(date, formikSetField)
              }
              onAccept={() => {}}
              openTo='date'
              invalidDateMessage=''
              autoOk
              id='policyEndDate'
              variant='inline'
              data-testid='date-picker-end'
            />
          </EzFormControl>
        </div>
        <div data-testid='destinations-wrapper'>
          <div className={classes.innerTitle} style={{fontFamily: isLoaded ? 'Ploni' : ''}}>
            {intl.formatMessage({id: 'step1.tab1.targetTitle'})}
            {' '}
            <span>
              {intl.formatMessage({id: 'step1.tab1.targetTitleRemark'})}
            </span>
          </div>
          <CountriesSelect
            value={formikValues.targetCountries}
            onSelect={(countries, continents) => {
              formikSetField('targetCountries', countries);
              formikSetField('targetFrame', continents);
            }}
          />
        </div>

        {actionButton &&
          actionButton({
            children: <FormattedMessage id='continue' />,
            className: classes.submitBtn,
            'data-testid': 'continue-button-1',
            onClick: handleSubmit,
            size: 'large',
            style: {fontFamily: isLoaded ? 'Ploni' : ''},
            'aria-label': formikErrors && Object.keys(formikErrors).length ?
              `${intl.formatMessage({id: 'continue'})}, ${intl.formatMessage({id: 'validation.needToFillAllFields'})}` :
              null,
          })}
      </div>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  datesWrapper: {
    '& .MuiTextField-root>.MuiFormLabel-root': {
      backgroundColor: theme.palette.background.paper,
    },
    '& > .MuiFormControl-root': {
      '&:last-child': {
        marginLeft: 20,
        [theme.breakpoints.down(1280)]: {marginLeft: 0},
      },
      [theme.breakpoints.down(1280)]: {maxWidth: 'calc(50% - 10px)'},
      [theme.breakpoints.down(1160)]: {maxWidth: 256},
      maxWidth: 256,
    },
    display: 'flex',
    flexFlow: 'row wrap',
    [theme.breakpoints.down(1280)]: {justifyContent: 'space-between'},
    [theme.breakpoints.down(1160)]: {width: 256},
    justifyContent: 'flex-start',
    marginBottom: 30,
  },
  innerTitle: {
    '& span': {
      fontWeight: 300,
      margin: '0 0 0 20px',
    },
    '& span:last-child': {margin: '0 0 0 15px'},
    color: theme.palette.secondary.darkLight,
    fontFamily: 'Arial,sans-serif',
    fontSize: theme.typography.fontSize,
    fontWeight: 600,
    lineHeight: '16px',
    margin: '20px 0 12px',
  },
  submitBtn: {
    fontFamily: 'Arial,sans-serif',
    fontSize: theme.typography.fontSize,
    marginTop: 10,
    width: 132,
  },
  title: {
    '& > h2': {
      color: theme.palette.text.secondary,
      display: 'block',
      fontSize: theme.typography.pxToRem(18.37),
      fontWeight: 400,
      letterSpacing: '-0.1px',
      lineHeight: '23px',
      [theme.breakpoints.down(1160)]: {width: 260},
    },
    '& > h2:first-of-type': {
      color: theme.palette.secondary.main,
      fontSize: theme.typography.pxToRem(49),
      fontWeight: 300,
      lineHeight: 1,
      margin: 0,
    },
    fontFamily: 'Arial,sans-serif',
  },
}));

Tab1.propTypes = {
  actionButton: PropTypes.func,
  className: PropTypes.string,
  formikSetField: PropTypes.func,
  formikSetTouched: PropTypes.func,
  formikValidateForm: PropTypes.func,
  formikValues: PropTypes.object,
  formikErrors: PropTypes.object,
  initialStartDateFromServer: PropTypes.string,
  submitCallback: PropTypes.func,
  validationCallback: PropTypes.func,
};

export default Tab1;
