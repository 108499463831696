/* eslint-disable max-len */
// File may seem redundant as most of requests are called from a single location,
// regardless it helps see all endpoint in one location and mend them as a unit,
// will also be easier to mock later in unit tests

import {apiGetRequest, apiPostRequest} from './apiHelpers';
import {getFromStorage, getOffersFields} from "./persistOfferDetails";

export const handleContactUsSubmit = (values) => apiPostRequest('api/contact_us', {
  fullName: values.name || values.fullname || getFromStorage('travel.details-form','values').firstName1 + " " + getFromStorage('travel.details-form','values').lastName1,
  offersCallId: values.offersCallId || getFromStorage('offers_fields','offersCallId'),
  phone: values.phone || values.phoneNumber || getFromStorage('travel.details-form','values').phone1,
  thereNoOffers: values.thereNoOffers,
  agentDeveloperName: getOffersFields('agentDeveloperName'),
});

export const checkOfferStatus = (values) => apiPostRequest('api/check_status', values);

export const getAllOffers = (requestData) => apiPostRequest('api/get_offers', requestData);

export const updatePotentialDetails = (requestData) => apiPostRequest('api/update_potential_details', requestData);

export const getToken = (details) => apiPostRequest('sms_api/get_token', details);

export const validateSecret = (req) => apiPostRequest('sms_api/validate_secret', req);

export const getCoversTitles = (policyType) => apiGetRequest(`data/get_covers_titles?policyType=${policyType}`);

export const verifyPayment = (data) => apiPostRequest('api/verifyPayment', data);

export const producingPolicy = (data) => apiPostRequest('api/producingPolicy', data);

export const updateCustomerSelectedOffer = (data) => apiPostRequest('api/updateCustomerSelectedOffer', data);

export const updateNoOffers = (data) => apiPostRequest('api/updateNoOffers', data);

export const handleAdditionalDetailsSubmit = (data) => apiPostRequest('api/updateCustomerAdditionalDetails', data);

export const getStartingDate = () => apiGetRequest('api/get_start_date');

export const getCityStreets = cityCode => apiGetRequest(`data/streets/${cityCode}`);

export const logGtmEvent = (data) => apiPostRequest('data/gtm/event', data);

export const getIsStillWorkingHourOrPolicyStartDateNotBlocked = policyStartDate => apiGetRequest(`api/get_is_still_working_hour_or_is_policy_start_date_not_blocked?policyStartDate=${policyStartDate}`);
