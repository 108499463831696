import * as yup from 'yup';
import {isIsraeliIdValid} from 'wobi-web-common';
import Moment from 'moment';
import conditions from './formConditions';

export const hebrewCharsRegExp = /^[\u0590-\u05fe\s.'")(-]*$/;
export const englishCharsRegExp = /^[a-zA-Z]+(?:-?(\s)?[a-zA-Z]+)*$/;
export const phoneNumberRegExp = /^\d+$/;
export const policyHolderMaxDOB = Moment().subtract(17, 'year');
export const policyHolderMinDOB = Moment().subtract(85, 'year');

export const validateDate = (startDate, endDate) => yup.mixed().required('שדה חובה')
  .test('validDate', 'תאריך לא תקין', (date) => conditions.isValidDate(date))
  .test('underMax', 'התאריך מעל המותר',
    (date) => conditions.isUnderMax(date, endDate))
  .test('aboveMin', 'התאריך מתחת למותר',
    (date) => conditions.isAboveMin(date, startDate));

export const validateForHebrew = (text) => yup.string().required('שדה חובה')
  .test('validation for hebrew', !!text ? text : 'אנא הזן  טקסט בעברית',
    (value = '') => hebrewCharsRegExp.test(value));


export const validateForEnglish = (text) => yup.string().required('שדה חובה')
  .test('validation for hebrew', !!text ? text  : 'אנא הזן טקסט באנגלית',
    (value = '') => englishCharsRegExp.test(value));

export const validatePhoneNumber = () => yup.string().required('שדה חובה')
  .min(10, 'אורך השדה קטן מ-10').max(10, 'אורך השדה גדול מ-10')
  .test('phone number validation', 'מספר טלפון לא תקין',
    (value) => {
      if (value && value.length === 10 && value.substr(0, 2) === '05') {
        return phoneNumberRegExp.test(value);
      }
      return false;
    });

export const validateEmail = () => yup.string().matches(/^[\w-.+]+@([\w-]+\.)+\w{2,4}$/,
  'ֿנא להזין מייל תקין')
  .typeError()
  .required('שדה חובה');

export const validateForIsraeliID = () => yup.string().required('שדה חובה')
  .test('validation for hebrew', 'המזהה אינו תקף',
    (value = '') => value !== '' ? isIsraeliIdValid(value) : true);

export const validateIfParentChecked = (parentName, validation) => yup.string()
  .when(parentName, {
    is: (field) => field,
    then: validation
  });
