const ccTypes = [
  {id: 'Isracard',
    name: 'ישראכרט'},
  {id: 'Visa',
    name: 'ויזה'},
  {id: 'Diners',
    name: 'דיינרס'},
  {id: 'Mastercard',
    name: 'מאסטרקארד'},
  {id: 'American-Express',
    name: 'אמריקן אקספרס'},
];

export default  function filterCCTypesByProvider(provider) {
  switch (provider.toUpperCase()) {
  case 'CLAL':
    return ccTypes.filter((ccType) => ccType.id !== 'Diners');
  default:
    return ccTypes;
  }
};