import React from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {Blue} from 'wobi-web-common/dist/config/colors.config';
import {getImageByAgent} from '../config/agent';

const AlertImg = getImageByAgent('alert.svg');
const useStyles = makeStyles((theme) => ({
  blockedBanner: {
    width: 'calc(100% + 100px)',
    position: 'relative',
    left: '-50px',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    borderRadius: '20px',
    border: `1px solid ${Blue[90]}`,
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : Blue[20],
    textAlign: 'left',
    '& h2': {
      fontSize: theme.typography.pxToRem(16.63),
      lineHeight: '20px',
      fontWeight: 600,
      color: theme.palette.secondary.dark,
      margin: '10px 0',
      position: 'relative',
      padding: '0 12px',
      display: 'flex',
      flexFlow: 'row nowrap',
      '&>span': {display: 'inline-block'},
      '&>img': {
        width: 23,
        height: 21,
        display: 'inline-block',
        position: 'relative',
        top: 1,
        marginRight: 10,
      },
    },
    '& p': {
      margin: 0,
      fontSize: theme.typography.fontSize,
      lineHeight: '19px',
      fontWeight: 400,
      color: theme.palette.text.secondary,
      marginBottom: 10,
      padding: '0 15px',
    },
  },
}));

const BlockedBanner = () => {
  const classes = useStyles();

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <section className={classes.blockedBanner} tabIndex='0'>
      <h2>
        <img src={AlertImg} alt='' aria-hidden='true'/>
        <span><FormattedMessage id='blocked_banner.title'/></span>
      </h2>
      {/* <p><FormattedMessage id='blocked_banner.text'/></p> */}
    </section>
  );
};

export default BlockedBanner;
