export default [
  'USA',
  'UNITED KINGDOM',
  'FRANCE',
  'ITALY',
  'SPAIN',
  'GREECE',
  'THAILAND',
  'HUNGARY',
  'CYPRUS',
  'ROMANIA',
  'NETHERLANDS',
];
