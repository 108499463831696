import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Blue} from 'wobi-web-common/dist/config/colors.config';
import {Breakpoints} from 'wobi-web-common';
import { agentDeveloperUtils } from 'wobi-web-common/dist/components/utils/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
    margin: '25px 0',
  },
  mainText: {
    fontSize: theme.typography.pxToRem(31.5),
    fontWeight: 'bold',
    '& span': {
      color: agentDeveloperUtils.getPrimaryColor(450),
      fontSize: theme.typography.pxToRem(29.75),
      fontWeight: 300,
      marginLeft: 10,
    },
    [theme.breakpoints.down(Breakpoints.mobile)]: {
      padding: '0 50px',
    },
  },
  subText: {
    fontSize: theme.typography.fontSize,
    '& span': {
      color: theme.palette.secondary.dark,
      fontWeight: 300,
      marginLeft: 5,
    },
  },
}));

const MainTitle = ({mainText, subText}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.mainText}>{mainText}</div>
      <div className={classes.subText}>{subText}</div>
    </div>
  );
};

export default MainTitle;
