/* eslint-disable complexity */
/* eslint-disable max-len */

export const utils = {

  filterSurgeryMoreThan3MonthsTravelers(travelers) {
    // return array of travelers that are checked as chronic=true
    return travelers.filter(traveler => traveler.surgeryMoreThan3Months);
  },

  filterRecommendationForMedicinesTravelers(travelers) {
    // return array of travelers that are checked as chronic=true
    return travelers.filter(traveler => traveler.recommendationForMedicines);
  },

  filterAdditionalExistingMedicalConditionTravelers(travelers) {
    // return array of travelers that are checked as chronic=true
    return travelers.filter(traveler => traveler.additionalExistingMedicalCondition);
  },

};
