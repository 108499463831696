import {minorDiseases}  from './minorDiseases';

export const tab3InitialData = {
  isUSAMedicalCondition           : '',
  isTreatmentAbroad      : '',
  isCovid19              : '',
  isMedicalEscalation    : '',
  isMedicalTreatment     : '',
  isPregnancy            : '',
  pregnancyIdentifica    : [],
  isPregEndangered       : '',
  isChronic              : '',
  isAdditionalChronic    : '',
  additChronIdentific    : [],
  isSeverDisease1        : '',
  isSeverDisease2        : '',
  isSeverDisease3        : '',
  isSeverDisease4        : '',
  week0                  : '',
  week1                  : '',
  week2                  : '',
  week3                  : '',
  week4                  : '',
  week5                  : '',
  week6                  : '',
  week7                  : '',
  week8                  : '',
  week9                  : '',
  travelers              : [],
  minorDiseases          : minorDiseases,
  medicalTreatmentIdentifica: [],
  medicalEscalationIdentifica: [],
  severDisease1Identifica: [],
  severDisease2Identifica: [],
  severDisease3Identifica: [],
  severDisease4Identifica: [],
};
