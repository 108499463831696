import React from 'react';
import {FormHelperText} from '@material-ui/core';
import {ErrorMessage} from 'formik';
import {FormattedHTMLMessage} from 'react-intl';
import YesNoRadioGroup from 'wobi-web-common/dist/components/YesNoRadioGroup';

/**
 * simple yes/no question
 */
const SimpleYesNo = ({
  name,
  value,
  displayCondition,
  onChange,
  label,
  hideErrorRowCondition,
  dataTestId
}) => {
  if (displayCondition !== undefined && !displayCondition) {
    return false;
  }

  return (
    <article>
      <div data-testid={dataTestId}>
        <YesNoRadioGroup
          name={name}
          value={value}
          onChange={onChange}
          label={label ? label : <FormattedHTMLMessage id={`step1.tab3.${name}`}/>}/>
      </div>
      {
        !hideErrorRowCondition && <FormHelperText error ><ErrorMessage name={name}/></FormHelperText>
      }
    </article>
  );
};

export default SimpleYesNo;
