import Tab1 from './Tab1';
import Tab2 from './Tab2';
import Tab3 from './Tab3';
import Tab4 from './Tab4';
import Tab3_Extended from './Tab3_Extended';

export {
  Tab1,
  Tab2,
  Tab3,
  Tab4,
  Tab3_Extended,
};
