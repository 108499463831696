/* eslint-disable max-len */
/* eslint-disable id-length */
/* eslint-disable complexity */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {ErrorMessage} from 'formik';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormLabel,
  FormGroup,
  FormHelperText,
} from '@material-ui/core';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import {injectIntl, FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import {utils} from './helpers/Tab3_helpers/utils';
import ValidationSchema from './helpers/Tab3_helpers/validationSchema';
import useTab3Styles from './helpers/Tab3_helpers/tab3Styles';
import BlockedBanner from '../../../components/BlockedBanner';
import CrossSellBanner from '../../../components/CrossSellBanner';
import Tab3YesNo from '../../../components/Tab3YesNo';
import Tab3Tooltip from '../../../components/Tab3Tooltip';
import UnsupportedMedicalCases from '../../../components/modals/UnsupportedMedicalCases';
import {fireGtmEvent} from '../../../utils/marketing.utils';
import moment from 'moment';
import {getImageByAgent} from '../../../config/agent';
import { agentDeveloperUtils } from 'wobi-web-common';

const alertIcon = getImageByAgent('alert.svg');

const HAREL_MAX_DAYS = process.env.REACT_APP_HAREL_MAX_DAYS || 14;

const Tab3 = React.memo(
  ({
    className: hideTabClassName,
    intl,
    idForAccessibility,
    submitCallback,
    formikIsSubmitting,
    validationCallback,
    formikSetField,
    formikErrors,
    formikValues,
    formikSetTouched,
    formikValidateForm,
    actionButton,
    handleAdditionalDiseaseChange,
  }) => {
    const values = formikValues;
    const [isModalShow, setIsModalShow] = useState(false);
    const [blockedBannerCondition, setBlockedBannerCondition] = useState(false);
    const [isAllTravelersArePregnant, setAllTravelersArePregnant] = useState(false);
    const classes = useTab3Styles();
    const messages = (id) => intl.formatMessage({id});
    const forceUpdate = utils.useForceUpdate();
    const validationObject = ValidationSchema;

    const travelersWithChronicCondition = utils.filterChronicTravelers(
      values.travelers,
    );

    setTimeout(() => {
      validationCallback(validationObject);
    }, 1);

    useEffect(() => {
      setIsModalShow(getBlockCondition() && agentDeveloperUtils.getAgentDeveloperName === 'Wobi');
    }, [values, blockedBannerCondition]);

    useEffect(() => {
      handleAdditionalDiseaseChange(values.isMedicalEscalation === 'yes' || (values.isAdditionalChronic === 'yes' && (values.isSeverDisease1 === 'yes' || values.isSeverDisease2 === 'yes' || values.isSeverDisease3 === 'yes' || values.isSeverDisease4 === 'yes')));
    }, [values.isMedicalEscalation, values.isSeverDisease1, values.isSeverDisease2, values.isSeverDisease3, values.isSeverDisease4]);

    useEffect(() => {
      const travelersWithChronicCondition = formikValues.travelers.filter((traveler) => traveler.additionalChronic);
      for (const traveler of formikValues.travelers) {
        if (!traveler.additionalChronic || formikValues.isAdditionalChronic === 'no') {
          delete traveler.severDisease1;
          delete traveler.severDisease2;
          delete traveler.severDisease3;
          delete traveler.severDisease4;
        } else if (travelersWithChronicCondition.length === 1) {
          traveler.severDisease1 = formikValues.isSeverDisease1;
          traveler.severDisease2 = formikValues.isSeverDisease2;
          traveler.severDisease3 = formikValues.isSeverDisease3;
          traveler.severDisease4 = formikValues.isSeverDisease4;
        }
      }
      if ((travelersWithChronicCondition.length === 0 || formikValues.isAdditionalChronic === 'no') && formikValues.travelers.length >= 1) {
        formikSetField('isSeverDisease1', '');
        formikSetField('isSeverDisease2', '');
        formikSetField('isSeverDisease3', '');
        formikSetField('isSeverDisease4', '');
      }
    }, [formikValues.isAdditionalChronic, formikValues.travelers]);

    const handleIsPregnancy = (value) => {
      formikSetField('isPregnancy', value);
      for (let i = 0; i < values.travelers.length; i++) {
        if (values.travelers[i].pregnant) {
          clickPregnancyIdentification(values.travelers[i], false);
        }
      }

      if (utils.filterWomenTravelers(values.travelers).length === 1) {
        const woman = utils.filterFirstWoman(values.travelers)[0];
        if (value === 'yes') {
          clickPregnancyIdentification(woman, true);
        } else if (value === 'no') {
          clickPregnancyIdentification(woman, false);
        }
      }

      setAllTravelersArePregnant(utils.filterPregnantTravelers(values.travelers).length === values.travelers.length);
    };

    const haveFertileWomen = utils.filterWomenTravelers(values.travelers)
      .length;
    const isPregnant = haveFertileWomen && values.isPregnancy === 'yes';

    const clickPregnancyIdentification = (woman, value) => {
      woman.pregnant = value;
      formikSetField('travelers', values.travelers);

      if (value) {
        values.pregnancyIdentifica.push(woman.ID);
      } else {
        const i = utils.getIndexFromIDArrayByID(values.pregnancyIdentifica, woman.ID);
        values.pregnancyIdentifica.splice(i, 1);
      }
      formikSetField('pregnancyIdentifica', values.pregnancyIdentifica);
      updatePregnancyWeek(woman.week, woman.ID);
      setAllTravelersArePregnant(utils.filterPregnantTravelers(values.travelers).length === values.travelers.length);
    };

    const setPregnancyWeeks = (event, index, travelerID) => {
      values.travelers[index].week = event.target.value;
      formikSetField('travelers', values.travelers);
      updatePregnancyWeek(event.target.value, travelerID);
    };

    const updatePregnancyWeek = (value, travelerID) => {
      const i = utils.getIndexByID(values.travelers, travelerID);
      let newValue = value;
      if (value === undefined) {
        newValue = '';
      }
      const propertyName = `week${i}`;
      formikSetField(propertyName, newValue);
    };

    const filterTravelersWithChronicCondition = () => {
      const travelers = [...values.travelers];
      return travelers.filter((t) => t.additionalChronic);
    };

    const uncheckTravelerMinorDiseases = (index) => {
      const travelers = [...values.travelers];
      const oldDiseases = [...travelers[index].minorDiseases];
      const travelerID = travelers[index].ID;
      oldDiseases.forEach((d) => {
        const minorDiseases = [...values.minorDiseases];
        const minorDiseaseIndex = minorDiseases.findIndex((md) => md.key === d);
        const newChosenTravelers = minorDiseases[
          minorDiseaseIndex
        ].chosenTravelers.filter((id) => id !== travelerID);
        handleTravelerButtonClick(
          {currentTarget: {value: travelerID}},
          newChosenTravelers,
          minorDiseaseIndex,
        );
        if (newChosenTravelers.length === 0) {
          checkMinorDisease(minorDiseaseIndex, minorDiseases, false);
        }
      });
      if (formikValues.isAdditionalChronic === 'no') {
        travelers[index].additionalChronic = false;
      }
      formikSetField(
        'additChronIdentific',
        values.additChronIdentific.filter((id) => id !== travelerID),
      );
    };

    const handleIsChronicButtonClick = (value) => {
      formikSetField('isChronic', value);
      const newValue = value === 'yes';
      if (values.travelers.length === 1) {
        values.travelers[0].chronic = newValue;
        formikSetField('travelers', values.travelers);
      }
      if (!newValue) {
        values.travelers.forEach((trav, index) => setChronicIdentification('chronic', index, false));
        formikSetField('additChronIdentific', []);
        formikSetField('isAdditionalChronic', 'no');
        formikSetField('isSeverDisease1', 'no');
        formikSetField('isSeverDisease2', 'no');
        formikSetField('isSeverDisease3', 'no');
        formikSetField('isSeverDisease4', 'no');
      }
    };

    const handleIsAdditionalChronicButtonClick = (value) => {
      formikSetField('isAdditionalChronic', value);
      const newValue = value === 'yes';
      if (values.travelers.length === 1) {
        values.travelers[0].additionalChronic = newValue;
        formikSetField('travelers', values.travelers);
      }
      if (!newValue) {
        values.travelers.forEach((trav, index) => setChronicIdentification('additionalChronic', index, false));
        formikSetField('additChronIdentific', []);
        formikSetField('isAdditionalChronic', 'no');
        formikSetField('isSeverDisease1', 'no');
        formikSetField('isSeverDisease2', 'no');
        formikSetField('isSeverDisease3', 'no');
        formikSetField('isSeverDisease4', 'no');
      }
    };

    const setChronicIdentification = (property, index, value) => {
      /* prop is 'chronic'/'additionalChronic */
      const travelers = [...values.travelers];
      travelers[index][property] = value;
      if ((property === 'chronic' || property === 'additChronIdentific') && !value) {
        uncheckTravelerMinorDiseases(index);
      } else {
        updateAdditChronIdentific(property, index, value);
      }
      formikSetField('travelers', travelers);
    };

    const checkMinorDisease = (index, array, value) => {
      array[index].checked = value;
      const travelers = [...values.travelers];

      if (travelersWithChronicCondition.length === 1) {
        const travelerIndex = travelers.findIndex((t) => t.chronic);
        if (travelerIndex > -1) {
          if (value) {
            array[index].chosenTravelers = [
              travelersWithChronicCondition[0].ID,
            ];
            travelers[travelerIndex].minorDiseases.push(array[index].key);
          } else {
            const i = travelersWithChronicCondition[0].minorDiseases.findIndex(
              (d) => d.key === array[index].key,
            );
            travelers[travelerIndex].minorDiseases.splice(i, 1);
          }
          formikSetField('travelers', travelers);
          forceUpdate();
        }
      }
      formikSetField('minorDiseases', array);
      forceUpdate();
    };

    const handleTravelerButtonClick = (
      event,
      newChosenTravelers,
      diseaseIndex,
    ) => {
      updateMinorDiseaseChosenTravelers(newChosenTravelers, diseaseIndex);
      updateTravelerMinorDiseases(event, newChosenTravelers, diseaseIndex);
    };

    const updateMinorDiseaseChosenTravelers = (
      newChosenTravelers,
      diseaseIndex,
    ) => {
      const updatedMinorDiseases = [...values.minorDiseases];
      updatedMinorDiseases[diseaseIndex].chosenTravelers = newChosenTravelers;
      formikSetField('minorDiseases', updatedMinorDiseases);
      forceUpdate();
    };

    const updateTravelerMinorDiseases = (
      event,
      newChosenTravelers,
      diseaseIndex,
    ) => {
      const travelerID = event.currentTarget.value;
      const travelerIndex = utils.getIndexByID(values.travelers, travelerID);
      const diseaseKey = values.minorDiseases[diseaseIndex].key;
      const updatedTravelers = [...values.travelers];

      if (newChosenTravelers.includes(travelerID)) {
        updatedTravelers[travelerIndex].minorDiseases.push(diseaseKey);
      } else {
        const j = updatedTravelers[travelerIndex].minorDiseases.findIndex(
          (d) => d === diseaseKey,
        );
        updatedTravelers[travelerIndex].minorDiseases.splice(j, 1);
      }

      formikSetField('travelers', updatedTravelers);
      forceUpdate();
    };

    const updateAdditChronIdentific = (property, index, value) => {
      if (!value) {
        const i = utils.getIndexFromIDArrayByID(
          values.additChronIdentific,
          values.travelers[index].ID,
        );
        values.additChronIdentific.splice(i, 1);
      } else if (
        property === 'additionalChronic' ||
        (property === 'chronic' && values.travelers[index].additionalChronic)
      ) {
        values.additChronIdentific.push(values.travelers[index].ID);
      }
      formikSetField('additChronIdentific', values.additChronIdentific);
    };

    const handleSubmitStep = async () => {
      const errors = await formikValidateForm();
      const hasErrors = Object.keys(errors).some((field) => Object.keys(validationObject).includes(field));
      if (!hasErrors) {
        fireGtmEvent('confirmMedical', {
          medicalTravel: formikValues.isTreatmentAbroad,
          sixMonthsMedical: formikValues.isMedicalEscalation,
          medicalTests: formikValues.isMedicalTreatment,
          chronicPatient: formikValues.isChronic,
          additionalDisease: formikValues.isAdditionalChronic,
          isPregnant: formikValues.isPregnancy,
          pregnantTravelers: formikValues.travelers
            .filter((tr) => tr.pregnant)
            .map((tr) => ({
              week: tr.week,
              ID: tr.ID,
            })),
        });
        submitCallback(3);
      } else {
        Object.keys(validationObject).forEach((field) => formikSetTouched(field, true, false));
      }
    };

    const someoneChronicAndPregnant = () => values.travelers.some(traveler => (traveler.additionalChronic || traveler.medicalEscalation || traveler.medicalTreatment) && traveler.pregnant);

    const getHarelCondition = () => {
      const policyStartDate = moment(formikValues.policyStartDate);
      const policyEndDate = moment(formikValues.policyEndDate);
      const isLessThanDays = policyEndDate.diff(policyStartDate, 'days') + 1 <= HAREL_MAX_DAYS;
      if (!isLessThanDays || values.targetCountries.includes('USA')) {
        return values.isMedicalTreatment === 'yes' ||
          values.isMedicalEscalation === 'yes' ||
          values.isSeverDisease1 === 'yes' ||
          values.isSeverDisease2 === 'yes' ||
          values.isSeverDisease3 === 'yes' ||
          values.isSeverDisease4 === 'yes';
      } else {
        return (values.isMedicalTreatment === 'yes' && isAllTravelersArePregnant) ||
        (values.isMedicalEscalation === 'yes' && isAllTravelersArePregnant);
      }
    };
    const getBlockCondition = () => values.isTreatmentAbroad === 'yes' ||
      values.isCovid19 === 'yes' ||
      getHarelCondition() ||
      (values.isPregEndangered === 'yes' &&
        utils.filterWomenTravelers(values.travelers).length) ||

      someoneChronicAndPregnant() ||
      Boolean(
        utils.filterPregnantWithBigWeek(values.travelers, values.policyEndDate)
          .length,
      );

    const manyCanBePregnant =
      isPregnant && utils.filterWomenTravelers(values.travelers).length > 1;
    const oneOrMorePregnant =
      isPregnant && utils.filterPregnantTravelers(values.travelers).length;
    const isChronicChecked = values.isChronic === 'yes';
    const isChronicAndManyTravelers =
      isChronicChecked && values.travelers.length > 1;
    const oneOrMoreHasChronic =
      isChronicChecked && utils.filterChronicTravelers(values.travelers).length;
    const manyHaveChronic =
      isChronicChecked && travelersWithChronicCondition.length > 1;
    const isAdditionalChronicChecked =
      /* isChronicChecked  && */ values.isAdditionalChronic === 'yes';
    const isAdditionalChronicAndManyTravelers =
      isAdditionalChronicChecked && values.travelers.length > 1;

    const manyCanBeTreatment = !isAllTravelersArePregnant && values.travelers.length > 1;
    const isBlockByCondition = getBlockCondition();

    if (isBlockByCondition !== blockedBannerCondition) {
      setBlockedBannerCondition(isBlockByCondition);
    }
    const submitButtonAriaLabel = () => {
      if (blockedBannerCondition) {
        return `${messages('continue')}, ${messages('blocked_banner.title')}`;
      } else if (formikErrors && Object.keys(formikErrors).length) {
        return `${messages('continue')}, ${messages('validation.needToFillAllFields')}`;
      } else {
        return null;
      }
    };

    const handleIsMedicalTreatment = (value) => {
      formikSetField('isMedicalTreatment', value);
      for (let i = 0; i < values.travelers.length; i++) {
        if (values.travelers[i].medicalTreatment) {
          setMedicalTreatmentIdentification(values.travelers[i], false);
        }
      }

      if (values.travelers.length === 1) {
        const traveler = values.travelers[0];
        if (value === 'yes') {
          setMedicalTreatmentIdentification(traveler, true);
        } else if (value === 'no') {
          setMedicalTreatmentIdentification(traveler, false);
        }
      }
    };

    const setMedicalTreatmentIdentification = (traveler, value) => {
      traveler.medicalTreatment = value;
      formikSetField('travelers', values.travelers);

      if (value) {
        values.medicalTreatmentIdentifica.push(traveler.ID);
      } else {
        const i = utils.getIndexFromIDArrayByID(values.medicalTreatmentIdentifica, traveler.ID);
        values.medicalTreatmentIdentifica.splice(i, 1);
      }
      formikSetField('medicalTreatmentIdentifica', values.medicalTreatmentIdentifica);
    };

    const handleIsMedicalEscalation = (value) => {
      formikSetField('isMedicalEscalation', value);
      for (let i = 0; i < values.travelers.length; i++) {
        if (values.travelers[i].medicalEscalation) {
          setMedicalEscalationldentification(values.travelers[i], false);
        }
      }

      if (values.travelers.length === 1) {
        const traveler = values.travelers[0];
        if (value === 'yes') {
          setMedicalEscalationldentification(traveler, true);
        } else if (value === 'no') {
          setMedicalEscalationldentification(traveler, false);
        }
      }
    };

    const setMedicalEscalationldentification = (traveler, value) => {
      traveler.medicalEscalation = value;
      formikSetField('travelers', values.travelers);

      if (value) {
        values.medicalEscalationIdentifica.push(traveler.ID);
      } else {
        const i = utils.getIndexFromIDArrayByID(values.medicalEscalationIdentifica, traveler.ID);
        values.medicalEscalationIdentifica.splice(i, 1);
      }
      formikSetField('medicalEscalationIdentifica', values.medicalEscalationIdentifica);
    };

    const handleIsSeverDisease = (property, travelerProperty, value) => {
      formikSetField(property, value);
      const filtertravelers = filterTravelersWithChronicCondition();
      for (let i = 0; i < filtertravelers.length; i++) {
        if (values.travelers[i][travelerProperty]) {
          setSeverDiseaseldentification(travelerProperty, values.travelers[i], false);
        }
      }
      if (filtertravelers.length === 1) {
        const traveler = filtertravelers[0];
        if (value === 'yes') {
          setSeverDiseaseldentification(travelerProperty, traveler, true);
        } else if (value === 'no') {
          setSeverDiseaseldentification(travelerProperty, traveler, false);
        }
      }
    };

    const setSeverDiseaseldentification = (property, traveler, value) => {
      // const travelers = [...values.travelers];
      // eslint-disable-next-line no-param-reassign
      value = value ? 'yes' : 'no';
      traveler[property] = value;
      formikSetField('travelers', values.travelers);

      if (value === 'yes') {
        values[property + 'Identifica'].push(traveler.ID);
      } else {
        const i = utils.getIndexFromIDArrayByID(values[property + 'Identifica'], traveler.ID);
        values[property + 'Identifica'].splice(i, 1);
      }
      formikSetField(property + 'Identifica', values[property + 'Identifica']);
    };

    const manyCanBeSeverDisease = filterTravelersWithChronicCondition().length > 1;
    return (
      <div className={`${hideTabClassName} travel-medical-conditions`} id={idForAccessibility} role='tabpanel'>
        <div className={`${classes.medicalConditions}`}>
          <div className='questions'>
            {values.targetCountries.includes('USA') ? (
              <section>
                <Tab3YesNo
                  name='isUSAMedicalCondition'
                  dataTestId='is-vaccinated'
                  value={values.isUSAMedicalCondition}
                  values={values}
                  onChange={(event) => formikSetField('isUSAMedicalCondition', event.target.value)}
                />
              </section>
            ) : null}
            {/* (1-3) --------------- mandatoryNo questions, seen always------------------*/}
            <section>
              <Tab3YesNo
                name='isTreatmentAbroad'
                dataTestId='is-treatment-abroad'
                value={values.isTreatmentAbroad}
                values={values}
                onChange={(event) => {
                  fireGtmEvent('MedicalTravel', {value: event.target.value});
                  formikSetField('isTreatmentAbroad', event.target.value);
                }}
              />
            </section>
            <section className={isPregnant ? 'util-unbordered' : ''}>
              <Tab3YesNo
                name='isMedicalTreatment'
                dataTestId='is-medical-treatment'
                value={values.isMedicalTreatment}
                values={values}
                onChange={(event) => {
                  fireGtmEvent('MedicalTest', {value: event.target.value});
                  handleIsMedicalTreatment(event.target.value);
                }}
                label={
                  <div>
                    <FormattedHTMLMessage id='step1.tab3.isMedicalTreatment' />
                    <FormattedHTMLMessage
                      id='step1.tab3.isMedicalTreatmentSmallText'
                    />
                  </div>
                }
              />
              {manyCanBeTreatment && values.isMedicalTreatment === 'yes' ?
                (
                  <article>
                    <FormLabel component='legend' className='checkboxGroup-label'>
                      {messages('step1.tab3.select_travelers')}
                    </FormLabel>
                    <FormGroup>
                      {values.travelers.map((traveler, i) => (<FormControlLabel
                        key={`traveler-${i}-medical-treatment`}
                        label={traveler.fullName}
                        control={
                          <Checkbox
                            data-testId={`traveler-${i + 1}-medical-treatment`}
                            checked={Boolean(traveler.medicalTreatment)}
                            onChange={(event, value) => {
                              setMedicalTreatmentIdentification(traveler, value);
                            }}
                          />
                        }
                      />))}
                    </FormGroup>
                    <FormHelperText error>
                      <ErrorMessage name='medicalTreatmentIdentifica' />
                    </FormHelperText>
                  </article>
                ) : false}
            </section>
            <section>
              <Tab3YesNo
                name='isMedicalEscalation'
                dataTestId='is-medical-escalation'
                value={values.isMedicalEscalation}
                values={values}
                onChange={(event) => {
                  // handleAdditionalDiseaseChange(event.target.value);
                  fireGtmEvent('SixMonthMedical', {value: event.target.value});
                  handleIsMedicalEscalation(event.target.value);
                }}
                label={
                  <div>
                    <FormattedHTMLMessage id='step1.tab3.isMedicalEscalation' />
                    <FormattedHTMLMessage
                      id='step1.tab3.isMedicalEscalation1'
                    />
                  </div>
                }
              />
              {manyCanBeTreatment && values.isMedicalEscalation === 'yes' ?
                (
                  <article>
                    <FormLabel component='legend' className='checkboxGroup-label'>
                      {messages('step1.tab3.select_travelers')}
                    </FormLabel>
                    <FormGroup>
                      {values.travelers.map((traveler, i) => (
                        <FormControlLabel
                          key={`traveler-${i}-medical-escalation`}
                          label={traveler.fullName}
                          control={
                            <Checkbox
                              data-testId={`traveler-${i + 1}-medical-escalation`}
                              checked={Boolean(traveler.medicalEscalation)}
                              onChange={(event, value) => {
                                setMedicalEscalationldentification(traveler, value);
                              }}
                            />
                          }
                        />
                      ))}
                    </FormGroup>
                    <FormHelperText error>
                      <ErrorMessage name='medicalEscalationIdentifica' />
                    </FormHelperText>
                  </article>
                ) : false}
            </section>
            {/* (4) ---------------- isPregnancy question, seen if there's a woman in age-range-----------------*/}
            <section className={
              isPregnant ? 'bordered-section' : isChronicChecked && !isPregnant ? 'util-unbordered' : ''
            }>
              <Tab3YesNo
                name='isPregnancy'
                dataTestId='is-pregnancy'
                value={values.isPregnancy}
                displayCondition={haveFertileWomen}
                values={values}
                onChange={(event, value) => {
                  fireGtmEvent('Pregnancy', {value: event.target.value});
                  handleIsPregnancy(value);
                }}
              />

              {/* (5) --------------  pregnancyIdentification (check which womanTraveler is pregnant)  -------------*/}
              {!manyCanBePregnant ?
                false :
                (
                  <article>
                    <FormLabel component='legend' className='checkboxGroup-label'>
                      {messages('step1.tab3.pregnancyIdentification')}
                    </FormLabel>
                    <FormGroup>
                      {values.travelers.map((traveler, i) => (traveler.gender === '1' ?
                          false :
                          (
                            <FormControlLabel
                              key={`traveler-${i}-pregnancy`}
                              label={traveler.fullName}
                              control={
                                <Checkbox
                                  data-testId={`traveler-${i + 1}-pregnancy`}
                                  checked={Boolean(traveler.pregnant)}
                                  onChange={(event, value) => {
                                    clickPregnancyIdentification(traveler, value);
                                  }}
                                />
                              }
                            />
                          )))}
                    </FormGroup>
                    <FormHelperText error>
                      <ErrorMessage name='pregnancyIdentifica' />
                    </FormHelperText>
                  </article>
                )}

              {/* (6) -------------------  isPregEndangered    --------------------*/}
              <Tab3YesNo
                name='isPregEndangered'
                dataTestId='is-preg-endangered'
                value={values.isPregEndangered}
                displayCondition={isPregnant}
                values={values}
                onChange={(event) => {
                  fireGtmEvent('pregnancyRisk', {value: event.target.value});
                  formikSetField('isPregEndangered', event.target.value);
                }}
              />

              {/* (7) --------------  pregnancyWeek (fill pregnancy-week for each pregnant traveler)  --------------*/}
              {!oneOrMorePregnant ?
                false :
                (
                  <article>
                    <FormLabel
                      component='legend'
                      className='textFieldsGroup-label'
                    >
                      {messages('step1.tab3.pregnancyWeek')}
                      <span className='notation'>
                        {messages('step1.tab3.pregnancyWeek_notation')}
                      </span>
                    </FormLabel>
                    <FormGroup>
                      {values.travelers.map((traveler, i) => !traveler.pregnant ?
                          false :
                          (
                            <div
                              className='pregnancyWeek-row'
                              key={`traveler-${i + 1}-pregnancyWeek`}
                            >
                              <FormControlLabel
                                label={traveler.fullName}
                                name={`week${i}`}
                                control={
                                  <TextField
                                    data-testid={`pregnancy-week${i + 1}`}
                                    className='pregnancyWeek-input'
                                    value={traveler.week}
                                    name={`week${i}`}
                                    error={Boolean(formikErrors[`week${i}`])}
                                    onChange={(event) => {
                                      setPregnancyWeeks(event, i, traveler.ID);
                                    }}
                                  />
                                }
                              />
                              <FormHelperText
                                error
                                className='textFieldsGroup-error'
                              >
                                <ErrorMessage name={`week${i}`} />
                              </FormHelperText>
                            </div>
                          ))}
                    </FormGroup>
                  </article>
                )}
            </section>
            {/* (8.0) ----------------covid 19 mandatory question, always seen-----------------*/}
            <section
              className={
                isChronicChecked && !isPregnant ? 'bordered-section' : ''
              }
            >
              <Tab3YesNo
                name='isCovid19'
                dataTestId='is-covid-abroad'
                value={values.isCovid19}
                displayCondition={false}
                values={values}
                onChange={(event) => {
                  fireGtmEvent('MedicalTravel', {value: event.target.value});
                  formikSetField('isCovid19', event.target.value);
                }}
              />

              {/* (8) ----------------chronic mandatory question, always seen-----------------*/}
              <Tab3YesNo
                name='isChronic'
                displayCondition={false}
                dataTestId='is-chronic'
                value={values.isChronic}
                values={values}
                onChange={(event, value) => {
                  fireGtmEvent('ChronicPatient', {value: event.target.value});
                  handleIsChronicButtonClick(value);
                }}
                label={
                  <div>
                    <FormattedHTMLMessage id='step1.tab3.isChronic' />
                    <FormattedHTMLMessage
                      id='step1.tab3.isChronic2'
                      values={{
                        imgUrl: alertIcon,
                      }}
                    />
                    <span onClick={() => fireGtmEvent('chronicMoreInfoEvent')}>
                      <FormattedHTMLMessage id='step1.tab3.chronic_link' />
                    </span>
                  </div>
                }
              />

              {/* (9) ----------------  chronicIdentification  -----------------*/}
              {!isChronicAndManyTravelers ?
                false :
                (
                  <article>
                    <div className='checkbox-label-row'>
                      <FormLabel
                        component='legend'
                        className='checkboxGroup-label'
                      >
                        {messages('step1.tab3.chronicIdentification')}
                      </FormLabel>
                      <Tab3Tooltip name='chronicIdentification_tooltip' />
                    </div>

                    <FormGroup>
                      {values.travelers.map((traveler, i) => (
                        <div
                          className='checkbox-item-row'
                          key={`traveler-${i}-chronic`}
                        >
                          <FormControlLabel
                            label={traveler.fullName}
                            control={
                              <Checkbox
                                data-testid={`traveler-${i + 1}-chronic`}
                                checked={Boolean(traveler.chronic)}
                                onChange={(event, value) => {
                                  setChronicIdentification('chronic', i, value);
                                }}
                              />
                            }
                          />
                          <div />
                        </div>
                      ))}
                    </FormGroup>
                    <FormHelperText error>
                      <ErrorMessage name='travelers' />
                    </FormHelperText>
                  </article>
                )}
              {/* (10) ----------------  medicalCondition (choosing minor-diseases)  -----------------*/}
              {!oneOrMoreHasChronic ?
                false :
                (
                  <article>
                    <div className='checkbox-label-row'>
                      <FormLabel
                        component='legend'
                        className='checkboxGroup-label'
                      >
                        {messages('step1.tab3.medicalCondition')}
                      </FormLabel>
                      <Tab3Tooltip name='medicalCondition_tooltip' />
                    </div>

                    <FormGroup>
                      {values.minorDiseases.map((disease, i, minorDiseases) => (
                        <div
                          className='checkbox-item-row'
                          data-testid={`disease-${i + 1}`}
                          key={`disease-${disease.key}`}
                        >
                          <FormControlLabel
                            label={disease.label}
                            control={
                              <Checkbox
                                checked={Boolean(disease.checked)}
                                onChange={(event, value) => {
                                  checkMinorDisease(i, minorDiseases, value);
                                }}
                              />
                            }
                          />
                          {disease.checked && manyHaveChronic ? (
                            <ToggleButtonGroup
                              value={values.minorDiseases[i].chosenTravelers}
                              onChange={(event, value) => {
                                handleTravelerButtonClick(event, value, i);
                              }}
                              arial-label='text formatting'
                              size='small'
                            >
                              {travelersWithChronicCondition.map(
                                (traveler, travelerIndex) => (
                                  <ToggleButton
                                    value={traveler.ID}
                                    aria-label={traveler.fullName}
                                    key={`d${i}-t${travelerIndex}-btn`}
                                  >
                                    {traveler.fullName}
                                  </ToggleButton>
                                ),
                              )}
                            </ToggleButtonGroup>
                          ) :
                            false
                          }
                        </div>
                      ))}
                      <FormHelperText error>
                        <ErrorMessage name='minorDiseases' />
                      </FormHelperText>
                    </FormGroup>
                  </article>
                )}
            </section>
            {/* end of chronic section */}
            {

              //* * (11) ----------------  isAdditionalChronic  -----------------*/
            }
            <section className='util-unbordered'>
              <Tab3YesNo
                name='isAdditionalChronic'
                dataTestId='is-additional-chronic'
                value={values.isAdditionalChronic}

                // displayCondition={oneOrMoreHasChronic}
                onChange={(event) => {
                  fireGtmEvent('ChronicPatient', {
                    value: event.target.value,
                  });
                  handleIsAdditionalChronicButtonClick(event.target.value);
                }}
                values={values}
                hideErrorRowCondition={isAdditionalChronicChecked}
                label={
                  <div>
                    <FormattedHTMLMessage id='step1.tab3.isAdditionalChronic' />
                    <FormattedHTMLMessage
                      id='step1.tab3.isAdditionalChronicSmallText'
                    />
                  </div>
                }
              />
            </section>
            {/* (12) ---------------- additionalChronicIdentification, seen if isAdditionalChronic=yes-----------------*/}

            {!isAdditionalChronicAndManyTravelers ?
              false :
              (
                <section>
                  <article>
                    <div className='checkbox-label-row'>
                      <FormLabel
                        component='legend'
                        className='checkboxGroup-label'
                      >
                        {messages('step1.tab3.chronicIdentification')}
                      </FormLabel>
                      <Tab3Tooltip name='chronicIdentification_tooltip' />
                    </div>
                    <FormGroup>
                      {values.travelers.map((traveler, i) => (!isAdditionalChronicAndManyTravelers ?
                          false :
                          (
                            <div
                              className='checkbox-item-row'
                              key={`traveler-${i}-additionalCh`}
                            >
                              <FormControlLabel
                                label={traveler.fullName}
                                control={
                                  <Checkbox
                                    data-testid={`traveler-${i + 1}-additionalCh`}
                                    checked={Boolean(traveler.additionalChronic)}
                                    onChange={(event, value) => {
                                      setChronicIdentification(
                                        'additionalChronic',
                                        i,
                                        value,
                                      );
                                    }}
                                  />
                                }
                              />
                              <div />
                            </div>
                          )))}
                    </FormGroup>
                    <FormHelperText error>
                      <ErrorMessage name='additChronIdentific' />
                    </FormHelperText>
                  </article>
                </section>
              )}

            {/* (13-15) ----------------severDiseases questions, seen if isAdditionalChronic=yes-----------------*/}
            {!isAdditionalChronicChecked || !filterTravelersWithChronicCondition().length ?
              false :
              (
                <section>
                  <div className='severDiseases_title'>
                    {messages('step1.tab3.severDisease_title')}
                  </div>
                  <Tab3YesNo
                    name='isSeverDisease1'
                    dataTestId='is-sever-disease-1'
                    value={values.isSeverDisease1}
                    values={values}
                    onChange={(event) => handleIsSeverDisease('isSeverDisease1', 'severDisease1', event.target.value)
                    }
                  />
                  {manyCanBeSeverDisease && values.isSeverDisease1 === 'yes' ?
                    (
                      <article>
                        <FormLabel component='legend' className='checkboxGroup-label'>
                          {messages('step1.tab3.select_travelers')}
                        </FormLabel>
                        <FormGroup>
                          {utils.filterAllNotPregnantTravelers(filterTravelersWithChronicCondition()).map((traveler, i) => (
                            <FormControlLabel
                              key={`traveler-${i}-sever-disease-1`}
                              label={traveler.fullName}
                              control={
                                <Checkbox
                                  data-testId={`traveler-${i + 1}-sever-disease-1`}
                                  checked={traveler.severDisease1 === 'yes'}

                                  //                              disabled={true}
                                  onChange={(event, value) => {
                                    setSeverDiseaseldentification('severDisease1', traveler, value);
                                  }}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                        <FormHelperText error>
                          <ErrorMessage name='severDisease1Identifica' />
                        </FormHelperText>
                      </article>
                    ) : false}
                  <Tab3YesNo
                    name='isSeverDisease2'
                    dataTestId='is-sever-disease-2'
                    value={values.isSeverDisease2}
                    values={values}
                    onChange={(event) => handleIsSeverDisease('isSeverDisease2', 'severDisease2', event.target.value)
                    }
                  />
                  {manyCanBeSeverDisease && values.isSeverDisease2 === 'yes' ?
                    (
                      <article>
                        <FormLabel component='legend' className='checkboxGroup-label'>
                          {messages('step1.tab3.select_travelers')}
                        </FormLabel>
                        <FormGroup>
                          {utils.filterAllNotPregnantTravelers(filterTravelersWithChronicCondition()).map((traveler, i) => (
                            <FormControlLabel
                              key={`traveler-${i}-sever-disease-2`}
                              label={traveler.fullName}
                              control={
                                <Checkbox
                                  data-testId={`traveler-${i + 1}-sever-disease-2`}
                                  checked={traveler.severDisease2 === 'yes'}

                                  //                              disabled={true}
                                  onChange={(event, value) => {
                                    setSeverDiseaseldentification('severDisease2', traveler, value);
                                  }}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                        <FormHelperText error>
                          <ErrorMessage name='severDisease2Identifica' />
                        </FormHelperText>
                      </article>
                    ) : false}
                  <Tab3YesNo
                    name='isSeverDisease3'
                    dataTestId='is-sever-disease-3'
                    value={values.isSeverDisease3}
                    values={values}
                    onChange={(event) => handleIsSeverDisease('isSeverDisease3', 'severDisease3', event.target.value)
                    }
                  />
                  {manyCanBeSeverDisease && values.isSeverDisease3 === 'yes' ?
                    (
                      <article>
                        <FormLabel component='legend' className='checkboxGroup-label'>
                          {messages('step1.tab3.select_travelers')}
                        </FormLabel>
                        <FormGroup>
                          {utils.filterAllNotPregnantTravelers(filterTravelersWithChronicCondition()).map((traveler, i) => (
                            <FormControlLabel
                              key={`traveler-${i}-sever-disease-3`}
                              label={traveler.fullName}
                              control={
                                <Checkbox
                                  data-testId={`traveler-${i + 1}-sever-disease-3`}
                                  checked={traveler.severDisease3 === 'yes'}

                                  //                              disabled={true}
                                  onChange={(event, value) => {
                                    setSeverDiseaseldentification('severDisease3', traveler, value);
                                  }}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                        <FormHelperText error>
                          <ErrorMessage name='severDisease3Identifica' />
                        </FormHelperText>
                      </article>
                    ) : false}
                  <Tab3YesNo
                    name='isSeverDisease4'
                    dataTestId='is-sever-disease-4'
                    value={values.isSeverDisease4}
                    values={values}
                    onChange={(event) => handleIsSeverDisease('isSeverDisease4', 'severDisease4', event.target.value)
                    }
                  />
                  {manyCanBeSeverDisease && values.isSeverDisease4 === 'yes' ?
                    (
                      <article>
                        <FormLabel component='legend' className='checkboxGroup-label'>
                          {messages('step1.tab3.select_travelers')}
                        </FormLabel>
                        <FormGroup>
                          {utils.filterAllNotPregnantTravelers(filterTravelersWithChronicCondition()).map((traveler, i) => (
                            <FormControlLabel
                              key={`traveler-${i}-sever-disease-4`}
                              label={traveler.fullName}
                              control={
                                <Checkbox
                                  data-testId={`traveler-${i + 1}-sever-disease-4`}
                                  checked={traveler.severDisease4 === 'yes'}

                                  //                              disabled={true}
                                  onChange={(event, value) => {
                                    setSeverDiseaseldentification('severDisease4', traveler, value);
                                  }}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                        <FormHelperText error>
                          <ErrorMessage name='severDisease4Identifica' />
                        </FormHelperText>
                      </article>
                    ) : false}
                </section>
              )}
          </div>
          {blockedBannerCondition && <BlockedBanner />}
          {blockedBannerCondition && <CrossSellBanner />}
          <UnsupportedMedicalCases
            values={values}
            isModalShow={!hideTabClassName && isModalShow}
            hideContactPassportCard={someoneChronicAndPregnant()}
            close={() => {
              setIsModalShow(false);
            }}
          />
          <div className={classes.buttons}>
            {actionButton &&
              actionButton({
                children: messages('continue'),
                className: classes.submitBtn,
                size: 'large',
                onClick: handleSubmitStep,
                variant: 'contained',
                'aria-label': messages('continue'),
                'data-testid': 'continue-button-3',
                color: 'primary',
                disabled: formikIsSubmitting || blockedBannerCondition,
                'aria-label': submitButtonAriaLabel(),
              })}
          </div>
        </div>
      </div>
    );
  },
);

Tab3.propTypes = {
  className: PropTypes.string,
  idForAccessibility: PropTypes.string,
  intl: PropTypes.object,
  submitCallback: PropTypes.func,
  formikIsSubmitting: PropTypes.bool,
  validationCallback: PropTypes.func,
  formikSetField: PropTypes.func,
  formikErrors: PropTypes.object,
  formikValues: PropTypes.object,
  formikSetTouched: PropTypes.func,
  formikValidateForm: PropTypes.func,
  actionButton: PropTypes.object,
  handleAdditionalDiseaseChange: PropTypes.func,
};

export default injectIntl(Tab3);
