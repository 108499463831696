/* eslint-disable max-len */
import * as yup from 'yup';
import {utils} from './utils';
import {testsConditions as conditions} from './testsConditions';

const ValidationSchema = {
  isUSAMedicalCondition:         yup.string().when('targetCountries', {is: (val) => val.includes('USA'), then: yup.string().required('שדה חובה')}),
  isTreatmentAbroad:    yup.string().required('שדה חובה'),
  //isCovid19:    yup.string().required('שדה חובה'),
  isMedicalEscalation:  yup.string().required('שדה חובה'),
  isMedicalTreatment:   yup.string().required('שדה חובה'),
  isPregnancy:          yup.string().when('travelers',{is:(t) => utils.filterWomenTravelers(t).length,then: yup.string().required('שדה חובה')}),
  isPregEndangered:     yup.string().when('isPregnancy',{is:'yes',then: yup.string().required('שדה חובה')}),
  pregnancyIdentifica:  yup.array().test('pregnancyIdentification', 'שדה חובה', function() {return conditions.pregnancyIdentification(this.parent.travelers, this.parent.isPregnancy);}),
  //isChronic:            yup.string().required('שדה חובה'),
  //isAdditionalChronic:  yup.string().when('isChronic',{is:'yes',then: yup.string().required('שדה חובה')}),
  isAdditionalChronic:  yup.string().required('שדה חובה'),

  travelers:            yup.array().test('chronicIdentification', 'שדה חובה', function(travelers) {return conditions.chronicIdentification(travelers, this.parent.isChronic);}),
  minorDiseases:        yup.array().nullable()
    .test('minorDiseases-chosenTravelers', 'יש לסמן לפחות מבוטח אחד עבור כל מחלה שנבחרה', function(minorDiseases) {return conditions.minorDiseasesChosenTravelers(minorDiseases, this.parent.travelers.length);})
    .test('minorDiseases-chosenDiseases', 'יש לבחור לפחות מחלה אחת', function(minorDiseases) {return conditions.minorDiseasesChosenDiseases(minorDiseases, this.parent.isChronic, this.parent.isAdditionalChronic);}),
  additChronIdentific:  yup.array().test('additionalChronicIdentification', 'שדה חובה', function(array) {return conditions.additionalChronicIdentification(this.parent.travelers, this.parent.isAdditionalChronic);}),
  medicalTreatmentIdentifica:  yup.array().test('medicalTreatmentIdentifica', 'שדה חובה', function() {return conditions.medicalTreatmentIdentifica(this.parent.travelers, this.parent.isMedicalTreatment);}),

  medicalEscalationIdentifica:  yup.array().test('medicalEscalationIdentifica', 'שדה חובה', function() {return conditions.medicalEscalationIdentifica(this.parent.travelers, this.parent.isMedicalEscalation);}),

  severDisease1Identifica: yup.array().when('isAdditionalChronic', { is: 'yes', then: yup.array().test('severDisease1Identifica', 'שדה חובה', function () { return conditions.severDiseaseIdentifica(this.parent.travelers, 'severDisease1', this.parent.isSeverDisease1); }) }),

  severDisease2Identifica: yup.array().when('isAdditionalChronic', { is: 'yes', then: yup.array().test('severDisease2Identifica', 'שדה חובה', function () { return conditions.severDiseaseIdentifica(this.parent.travelers, 'severDisease2', this.parent.isSeverDisease2); }) }),

  severDisease3Identifica: yup.array().when('isAdditionalChronic', { is: 'yes', then: yup.array().test('severDisease3Identifica', 'שדה חובה', function () { return conditions.severDiseaseIdentifica(this.parent.travelers, 'severDisease3', this.parent.isSeverDisease3); }) }),

  severDisease4Identifica: yup.array().when('isAdditionalChronic', { is: 'yes', then: yup.array().test('severDisease4Identifica', 'שדה חובה', function () { return conditions.severDiseaseIdentifica(this.parent.travelers, 'severDisease4', this.parent.isSeverDisease4); }) }),
};

[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].forEach(index => {
  ValidationSchema['week'+index] = yup.string().when('isPregnancy',{is:'yes',then: yup.string()
                                    .test('weekRequired', 'שדה חובה', function (week) {return conditions.weekRequired(week, this.path, this.parent.travelers)})
                                    .test('weekValid', 'ערך לא תקין', function (week) {return conditions.weekValid(week, this.path, this.parent.travelers)})});
  if ((index > 0) && (index < 5)) {
    ValidationSchema['isSeverDisease'+index] = yup.string().when('isAdditionalChronic',{is:'yes',then: yup.string().required('שדה חובה')});
  }
});


export default ValidationSchema;
