export const sortingOptions = [
  {
    id: 1,
    title: 'מחיר נמוך לגבוה',
    comparer: ({price: price1}, {price: price2}) => price1 - price2
  },
  {
    id: 2,
    title: 'כיסוי גבוה לנמוך',
    comparer: (item1, item2) => {
      const price1 = item1.additionalPrices.find(el=> el.id === 2).value;
      const price2 = item2.additionalPrices.find(el=> el.id === 2).value;

      return price2 - price1;
    }
  },
  {
    id: 3,
    title: 'השתתפות עצמית נמוכה לגבוהה',
    comparer: (item1, item2) => {
      const price1 = item1.additionalPrices.find(el=> el.id === 3).value;
      const price2 = item2.additionalPrices.find(el=> el.id === 3).value;

      return price1 - price2;
    }
  }
];
