/* eslint-disable max-len */
import {utils} from './utils';

export const testsConditions = {
  surgeryMoreThan3MonthsIdentifica(thisParentTravelers, thisParentIsSurgeryMoreThan3Months) {
    return thisParentIsSurgeryMoreThan3Months === 'no' || (thisParentIsSurgeryMoreThan3Months === 'yes' &&
              utils.filterSurgeryMoreThan3MonthsTravelers(thisParentTravelers).length > 0);
  },

  recommendationForMedicinesIdentifica(thisParentTravelers, thisParentIsRecommendationForMedicines) {
    return thisParentIsRecommendationForMedicines === 'no' || (thisParentIsRecommendationForMedicines === 'yes' &&
              utils.filterRecommendationForMedicinesTravelers(thisParentTravelers).length > 0);
  },

  additionalExistingMedicalConditionIdentifica(thisParentTravelers, thisParentIsAdditionalExistingMedicalCondition) {
    return thisParentIsAdditionalExistingMedicalCondition === 'no' || (thisParentIsAdditionalExistingMedicalCondition === 'yes' &&
              utils.filterAdditionalExistingMedicalConditionTravelers(thisParentTravelers).length > 0);
  },

};
