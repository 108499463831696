/* eslint-disable max-len */
import React from 'react';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';
import * as yup from 'yup';
import {Formik, Form} from 'formik';
import {Persist, FORMIK_DEBOUNCE} from '../utils/formik-persist';
import TabsLayout from '../components/TabsLayout';
import {Tab1, Tab2, Tab3, Tab4, Tab3_Extended} from './tabs-contents/initial-details';
import stepsTabs from '../config/tabs';
import {getCurrentTabIndex, tabIndexToName} from '../utils/routingHelpers';
import {tab3InitialData} from './tabs-contents/initial-details/helpers/Tab3_helpers/tab3InitialData';
import {tab3ExtendedInitialData} from './tabs-contents/initial-details/helpers/Tab3_Extended_helpers/tab3ExtendedInitialData';
import {getTravelers} from './tabs-contents/initial-details/helpers/Tab3_helpers/utils';
import {getStartingDate} from '../utils/apiHandlers';
import {preSaveHandler, setUtmFields} from '../utils/persistOfferDetails';
import {Button} from '@material-ui/core';
import {
  constractCampaignArgs,
  fireGtmEvent,
} from '../utils/marketing.utils';

const queryStringParametersNames = [
  'first-referrer',
  'gclid',
  'referrer',
  'utm_source',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'utm_medium',
  'first-utm_medium',
  'first-utm_source',
  'first-gclid',
  'utm_keywords',
];

let validate = () => {};
let touch = () => {};
// Block for setting formik initialValues
const tab2TravelerData = {phone1: ''};
for (let i = 1; i <= 10; i++) {
  tab2TravelerData['dob' + i] = null;
  tab2TravelerData['ID' + i] = '';
  tab2TravelerData['firstName' + i] = '';
  tab2TravelerData['lastName' + i] = '';
  tab2TravelerData['gender' + i] = '';
}
const formikInitialValues = {
  policyStartDate: null,
  policyEndDate: null,
  targetFrame: [],
  targetCountries: [], // tab1 fields
  policyholdNum: 1,
  isLeftIsrael: false,
  ...tab2TravelerData, // tab2 fields
  ...tab3InitialData, // tab3 fields
  ...tab3ExtendedInitialData, // tab3 extended fields
  isDetection: true,
  isLiable: true, // tab4 fields
  isCharger: false,
  isEsports: false,
  isWsports: false,
  isTravelc: false,
  isTravelsc: false,
  isPccover: false,
  isMobilecover: false,
  isCancellationOfSelfParticipationOnRentedCar: false,
  isTerms: false, // tab5 fields
  isOwnerHospital: false,
  isOwnerHospital2: '',
  pccoverManufacturer: '',
  pccoverModel: '',
  pccoverYear: '',
  mobilecoverManufacturer: '',
  mobilecoverModel: '' // tab5 fields
};

class InitialDetailsPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.getStartDate = this.getStartDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.runValidation = this.runValidation.bind(this);
    this.handleCurrentTabValidation = this.handleCurrentTabValidation.bind(this);
    this.setTab = this.setTab.bind(this);
    this.refSubmit = React.createRef();
    this.handleAdditionalDiseaseChange  = this.handleAdditionalDiseaseChange.bind(this);
  }

  state = {
    tab: 0,
    validationFields: {},
    tabs: stepsTabs().details.filter((tab) => tab.key !== 'medical_extended'),
  };

  fixQueryStringDuplicateParam(querystringParameters) {
    const fixedQueryStringParams = {
      ...querystringParameters,
    };
    const stringParameters = Object.keys(fixedQueryStringParams);
    stringParameters.forEach(param => {
      if (queryStringParametersNames.includes(param)) {
        if (Array.isArray(fixedQueryStringParams[param])) {
          fixedQueryStringParams[param] = fixedQueryStringParams[param][0];
        }
      }
    });
    return fixedQueryStringParams;
  }

  setCampaignArgs() {
    const {location} = this.props;
    const values = queryString.parse(location.search || location.hash.split('?')[1]);
    const fixedValues = this.fixQueryStringDuplicateParam(values);
    const offersFields = constractCampaignArgs(fixedValues);
    if (fixedValues['first-referrer']) {
      offersFields.first_referrer = fixedValues['first-referrer'];
    }
    if (fixedValues['first-utm_medium']) {
      offersFields.first_utm_medium = fixedValues['first-utm_medium'];
    }
    if (fixedValues['first-utm_source']) {
      offersFields.first_utm_source = fixedValues['first-utm_source'];
    }
    if (fixedValues['first-gclid']) {
      offersFields.first_gclid = fixedValues['first-gclid'];
    }
    setUtmFields(offersFields);
  }


  componentDidMount() {
     this.setCampaignArgs();
    this.getStartDate();
    this.props.changeSideBar(false);
    this.unblock = this.props.history.block((location, action, callback) => {
      const tabIndex = getCurrentTabIndex(location, 'details');
      const { pathname } = location;
      if( !pathname.includes("offers") && this.state.tab >= tabIndex) {
        return true;
      }
      // Allow the transition to pass or block it asynchronously
      // When the handler returns `undefined` the transitions will be blocked until the callback
      // is called with either `true` to allow navigation or `false` to block it.
      this.runValidation().then((res) => callback(res))

    });
    const parameters = queryString.parse(this.props.location.search || this.props.location.hash.split('?')[1]);
    if (parameters.pid) {
      const parentLeadId = parameters.pid;
      sessionStorage.setItem('parentLeadId', parentLeadId);
    }
    if (this.state.tab === 0 && parameters && parameters.referrer) {
      fireGtmEvent('TravelStart', {
          referrer: parameters.referrer,
        });
    }
  }

  async runValidation() {
    const errors = await validate()
      if (Object.keys(errors).length != 0) {
        Object.keys(this.state.validationFields).forEach((field) =>
        touch(field, true));
        return false;
      }
      return true;
  }

  componentWillMount() {
    this.setTab(this.props.location);
    this.unlisten = this.props.history.listen((location) => {
      this.setTab(location);
    });

    window.addEventListener('keypress', this.enterSubmitForm);
  }

  getStartDate() {
    getStartingDate().then(resp => {
      if (resp.success) {
        this.setState({initialStartDate: resp.data.startDate});
      }
    });
  }

  handleChange(tabIndex, setFieldValue, values) {
    if (tabIndex === this.state.tab) {
      return;
    }
    if (tabIndex === 5) {
      this.submit(); return;
    }
    if (tabIndex === 2 && values) {
      const travelers = getTravelers(values);
      setFieldValue('travelers', travelers);
    }

    const tabName = tabIndexToName(tabIndex, this.state.tabs);
    this.props.history.push(`#${tabName}`);
  }

  handleCurrentTabValidation(tabValidation, fromTab, activeTab) {
    if (Object.keys(tabValidation).length !== Object.keys(this.state.validationFields).length &&
      activeTab === fromTab) {
      this.setState({validationFields: tabValidation});
    }
  }

  setTab(location) {
    const tabIndex = getCurrentTabIndex(location, 'details');
    if (tabIndex === false) {
      return;
    }
    this.setState({tab: tabIndex});
  }

  submit() {
    this.props.history.push('offers');
  }

  enterSubmitForm = (e) => {
    if (e.keyCode === 13 && this.refSubmit) {
      this.refSubmit.current.click();
    }
  }

  componentWillUnmount() {
    this.unlisten();
    window.removeEventListener('keypress', this.enterSubmitForm);
  }

  updateFormikValidateForm = (formikValidateForm,formikSetTouched) => {
    validate= formikValidateForm;
    touch = formikSetTouched;
  }

  handleAdditionalDiseaseChange(event) {
    if (event === false) {
      sessionStorage.setItem('travel.details-form.isShowMedicalExtended', false);
      this.setState({tabs: stepsTabs().details});
     } else {
      sessionStorage.setItem('travel.details-form.isShowMedicalExtended', true);
      this.setState({tabs: stepsTabs().details});
     }
  }

  render() {
    const activeTab = this.state.tab;
    return (
      <Formik
        validationSchema={yup.object().shape(this.state.validationFields)}
        initialValues={formikInitialValues}>
        {({values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue, setFieldTouched, validateForm}) => (
          <Form>
            <TabsLayout
              tabs={this.state.tabs}
              currentTabIndex={activeTab}
              handleChange={(event, tabIndex) => {
                this.handleChange(tabIndex);
                fireGtmEvent('MenuTravelDetails', {tabIndex});
              }}
              formikSetTouched={setFieldTouched}
              formikValidateForm={validateForm}
              formikSetField={setFieldValue}
              formikErrors={errors} formikValues={values}
              formikHandleChange={handleChange}
              handleSubmit={handleSubmit}
              formikIsSubmitting={isSubmitting}
              updateFormikValidateForm={this.updateFormikValidateForm}
            >
              <Tab1
                initialStartDateFromServer={this.state.initialStartDate}
                validationCallback={(tabValidation) => this.handleCurrentTabValidation(tabValidation, 0, activeTab)}
                submitCallback={(tabIndex) => this.handleChange(tabIndex)}
                actionButton={activeTab === 0 ? ({ children, ...props }) => (
                <Button ref={this.refSubmit} {...props}> {children} </Button>
                ) : null}
              />
              <Tab2
                validationCallback={(tabValidation) => this.handleCurrentTabValidation(tabValidation, 1, activeTab)}
                submitCallback={(tabIndex) => this.handleChange(tabIndex, setFieldValue, values)}
                actionButton={activeTab === 1 ? ({ children, ...props }) => (
                  <Button ref={this.refSubmit} {...props}> {children} </Button>
                ) : null}
              />
              <Tab3
                validationCallback={(tabValidation) => this.handleCurrentTabValidation(tabValidation, 2, activeTab)}
                submitCallback={(tabIndex) => this.handleChange(tabIndex)}
                activeTab={activeTab}
                actionButton={activeTab === 2 ? ({ children, ...props }) => (
                  <Button ref={this.refSubmit} {...props}> {children} </Button>
                ) : null}
                handleAdditionalDiseaseChange={this.handleAdditionalDiseaseChange}
              />
              <Tab3_Extended
                validationCallback={(tabValidation) => this.handleCurrentTabValidation(tabValidation, 3, activeTab)}
                submitCallback={(tabIndex) => this.handleChange(tabIndex)}
                activeTab={activeTab}
                actionButton={activeTab === 3 ? ({ children, ...props }) => (
                  <Button ref={this.refSubmit} {...props}> {children} </Button>
                ) : null}
              />
              <Tab4
                validationCallback={(tabValidation) => this.handleCurrentTabValidation(tabValidation, 4, activeTab)}
                submitCallback={(tabIndex) => this.handleChange(tabIndex)}
                actionButton={activeTab === 4 ? ({ children, ...props }) => (
                  <Button ref={this.refSubmit} {...props}> {children} </Button>
                ) : null}
              />
            </TabsLayout>
            <Persist
              isSessionStorage
              name='travel.details-form'
              preSaveHandler={preSaveHandler}
              debounce={FORMIK_DEBOUNCE}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(InitialDetailsPage);
