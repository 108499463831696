import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import breakpoints from 'wobi-web-common/dist/config/breakpoints';

const useStyles = makeStyles((theme) => ({
  appRoot: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh',
  },
  inner: {
    '&.mobile': {
      '& .header, & .bottom': {
        padding: 0,
      },
    },
    '& .header': {
      background: theme.palette.type === 'light' ?
        'transparent linear-gradient(180deg, #ECF9FE 0%, #FBFEFF 60%, #FFFFFF52 100%) 0% 0% no-repeat padding-box' :
        'none',
      padding: '50px 74px 0px 74px',
    },
    '& .bottom': {
      padding: '0px 74px 50px 74px',
    },
    '& .main-content': {
      padding: '34px 0',
    },
  },
}));

const MainContainer = ({children}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width:${breakpoints.mobile}px)`);

  return (
    <div className={`App ${classes.appRoot}`} data-testid='app-root-container'>
      <div className={`${classes.inner} ${isMobile ? 'mobile' : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default MainContainer;
