/* eslint-disable object-shorthand */
import {utils} from './utils';

export const testsConditions = {
  weekRequired: function (week, thisPath, thisParentTravelers) {
    let currentTravelerIndex = parseInt(thisPath.substring(4), 10);
    return thisPath === undefined || thisParentTravelers === undefined ||
           thisParentTravelers.length - 1 < currentTravelerIndex ||
          !(!week && thisParentTravelers[currentTravelerIndex].pregnant);
  },
  
  weekValid: function (week, thisPath, thisParentTravelers) {
    let currentTravelerIndex = parseInt(thisPath.substring(4), 10);
    return thisPath === undefined || thisParentTravelers === undefined ||
           thisParentTravelers.length - 1 < currentTravelerIndex ||
           !(thisParentTravelers[currentTravelerIndex].pregnant &&
           (!week || !utils.isPositiveNumber(week) || parseInt(week, 10) > 43));
  },
  
  pregnancyIdentification: function (thisParentTravelers, thisParentIsPregnancy) {
    return !(thisParentIsPregnancy === 'yes' && utils.filterPregnantTravelers(thisParentTravelers).length === 0);
  },

  chronicIdentification: function (travelers, thisParentIsChronic) {
    return !(thisParentIsChronic === 'yes' && travelers.length > 1 && utils.filterChronicTravelers(travelers).length === 0);
  },

  additionalChronicIdentification: function (thisParentTravelers, thisParentIsAdditionalChronic) {
    return thisParentIsAdditionalChronic === 'no' || ( thisParentIsAdditionalChronic === 'yes' &&
             utils.filterAdditionalChronicTravelers(thisParentTravelers).length > 0);
  },

  minorDiseasesChosenTravelers: function (minorDiseases, thisParentTravelersLength) {
    if (minorDiseases === null || thisParentTravelersLength === 1) return true; /* case user unchecked all diseases or 1 traveler */
    else return minorDiseases.every((minorDisease) => (minorDisease.checked && minorDisease.chosenTravelers.length > 0) || !minorDisease.checked);
  },

  minorDiseasesChosenDiseases: function (minorDiseases, isChronic, isAdditionalChronic) {
    if (minorDiseases.every((minorDisease) => (!minorDisease.checked)) && isChronic === 'yes' && isAdditionalChronic !== 'yes') return false;
    else return true;
  },

  medicalTreatmentIdentifica:  function (thisParentTravelers, thisParentIsMedicalTreatment) {
    return thisParentIsMedicalTreatment === 'no' || (thisParentIsMedicalTreatment === 'yes' &&
             utils.filterMedicalTreatmentTravelers(thisParentTravelers).length > 0);
  },

  medicalEscalationIdentifica:  function (thisParentTravelers, thisParentIsMedicalEscalation) {
    return thisParentIsMedicalEscalation === 'no' || (thisParentIsMedicalEscalation === 'yes' &&
             utils.filterMedicalEscalationTravelers(thisParentTravelers).length > 0);
  },

  severDiseaseIdentifica(thisParentTravelers, severDiseaseNumber, thisParentIsSeverDisease) {
     return thisParentIsSeverDisease === 'no' || (thisParentIsSeverDisease === 'yes' &&
              utils.filterSeverDiseaseTravelers(severDiseaseNumber, thisParentTravelers).length > 0);
   },
};