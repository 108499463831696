import {makeStyles} from '@material-ui/core';

export default makeStyles((theme) => ({
  text: {
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  actionButton: {
    flex: '1 1',
    padding: [[6, 8]],
    margin: [[0, 2]],
    lineHeight: 1,
  },
}))
